import { GraphQLClient } from "graphql-request";
import React, { useContext, useEffect, useState } from "react";
import config from "./config";
import { ApplicationContext } from "./ApplicationContext";

const useAnonymousGraphQlClient = (
  endpoint: string = config.endpoint
): { graphQlClient: any } => {
  // curl 'https://<hasura-host>/v1/graphql' - H 'x-hasura-admin-secret: <admin-secret>'  --data - binary '<graphql-query>'
  return {
    graphQlClient: () => {
      return new GraphQLClient(endpoint, {
        headers: {
        },
      });
    },
  };
};

export default useAnonymousGraphQlClient;
