import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AccessTokenCheckInput = {
  accessToken: Scalars['String'];
};

export type AccessTokenCheckOutput = {
  __typename?: 'AccessTokenCheckOutput';
  accessTokenSuccess: Scalars['String'];
};

export type AccessTokenOutput = {
  __typename?: 'AccessTokenOutput';
  accessToken: Scalars['jsonb'];
};

export type AddCustomerInput = {
  user: Scalars['jsonb'];
};

export type AddCustomerOutput = {
  __typename?: 'AddCustomerOutput';
  accessToken: Scalars['jsonb'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CloudbedsGetReservationsInput = {
  accessToken: Scalars['String'];
  queryParams: CloudbedsGetReservationsParams;
};

export type CloudbedsGetReservationsOutput = {
  __typename?: 'CloudbedsGetReservationsOutput';
  accessToken?: Maybe<Scalars['jsonb']>;
};

export type CloudbedsGetReservationsParams = {
  checkInFrom?: InputMaybe<Scalars['timestamp']>;
  checkInTo?: InputMaybe<Scalars['timestamp']>;
  checkOutFrom?: InputMaybe<Scalars['timestamp']>;
  checkOutTo?: InputMaybe<Scalars['timestamp']>;
  includeGuestsDetails?: InputMaybe<Scalars['Boolean']>;
  modifiedFrom?: InputMaybe<Scalars['timestamp']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyId?: InputMaybe<Scalars['String']>;
  resultsFrom?: InputMaybe<Scalars['timestamp']>;
  resultsTo?: InputMaybe<Scalars['timestamp']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomName?: InputMaybe<Scalars['String']>;
  sortByRecent?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

export type CloudbedsInput = {
  code?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type CloudbedsOutput = {
  __typename?: 'CloudbedsOutput';
  accessToken: Scalars['jsonb'];
};

export type CloudbedsRoom = {
  quantity: Scalars['Int'];
  roomID?: InputMaybe<Scalars['Int']>;
  roomRateID?: InputMaybe<Scalars['Int']>;
  roomTypeID: Scalars['Int'];
};

export type CustomField = {
  fieldName?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
};

export type DeleteAdjustmentInput = {
  adjustmentID: Scalars['String'];
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
};

export type DeleteAdjustmentOutput = {
  __typename?: 'DeleteAdjustmentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type DeleteGuestNoteInput = {
  guestID: Scalars['Int'];
  noteId: Scalars['Int'];
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type DeleteGuestNoteOutput = {
  __typename?: 'DeleteGuestNoteOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type DeleteReservationNoteInput = {
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID?: InputMaybe<Scalars['String']>;
  reservationNoteID?: InputMaybe<Scalars['Int']>;
};

export type DeleteReservationNoteOutput = {
  __typename?: 'DeleteReservationNoteOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type EmailSchedule = {
  reservationEvent?: InputMaybe<ReservationEvent>;
  reservationStatusChange?: InputMaybe<ReservationStatusChange>;
};

export type GetAdjustmentInput = {
  adjustmentId: Scalars['Int'];
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type GetAdjustmentOutput = {
  __typename?: 'GetAdjustmentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetAdjustmentsOutput = {
  __typename?: 'GetAdjustmentsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetAvailableRoomTypesInput = {
  accessToken: Scalars['String'];
  adults: Scalars['Int'];
  children: Scalars['Int'];
  detailedRates?: InputMaybe<Scalars['Boolean']>;
  endDate: Scalars['timestamp'];
  maxRate?: InputMaybe<Scalars['Float']>;
  minRate?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  promoCode?: InputMaybe<Scalars['String']>;
  propertyIDs?: InputMaybe<Scalars['String']>;
  rooms: Scalars['Int'];
  sort?: InputMaybe<Scalars['String']>;
  startDate: Scalars['timestamp'];
};

export type GetAvailableRoomTypesOutput = {
  __typename?: 'GetAvailableRoomTypesOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetCustomFieldsInput = {
  customFieldId?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  shortcode?: InputMaybe<Scalars['String']>;
};

export type GetCustomFieldsOutput = {
  __typename?: 'GetCustomFieldsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetDashboardInput = {
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type GetDashboardOutput = {
  __typename?: 'GetDashboardOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetEmailScheduleInput = {
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type GetEmailScheduleOutput = {
  __typename?: 'GetEmailScheduleOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetEmailTemplatesInput = {
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type GetGuestInput = {
  accessToken: Scalars['String'];
  guestID?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['Int']>;
  reservationId?: InputMaybe<Scalars['String']>;
};

export type GetGuestListInput = {
  checkInFrom?: InputMaybe<Scalars['timestamp']>;
  checkInTo?: InputMaybe<Scalars['timestamp']>;
  checkOutFrom?: InputMaybe<Scalars['timestamp']>;
  excludeSecondaryGuests?: InputMaybe<Scalars['Boolean']>;
  includeGuestInfo?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyIDs?: InputMaybe<Scalars['Int']>;
  resultsFrom?: InputMaybe<Scalars['timestamp']>;
  resultsTo?: InputMaybe<Scalars['timestamp']>;
  sortBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type GetGuestListOutput = {
  __typename?: 'GetGuestListOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetGuestNotesInput = {
  guestId?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
};

export type GetGuestNotesOutput = {
  __typename?: 'GetGuestNotesOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetGuestOutput = {
  __typename?: 'GetGuestOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetGuestsByFilterInput = {
  accessToken: Scalars['String'];
  cehckoutTo?: InputMaybe<Scalars['timestamp']>;
  checkInFrom?: InputMaybe<Scalars['timestamp']>;
  checkInTo?: InputMaybe<Scalars['timestamp']>;
  checkoutFrom?: InputMaybe<Scalars['timestamp']>;
  guestName?: InputMaybe<Scalars['String']>;
  propertyIDs?: InputMaybe<Scalars['Int']>;
  reservationId?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type GetGuestsByFilterOutput = {
  __typename?: 'GetGuestsByFilterOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetGuestsByStatusInput = {
  pageNumer?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyId?: InputMaybe<Scalars['Int']>;
  resultsFrom?: InputMaybe<Scalars['timestamp']>;
  resultsTo?: InputMaybe<Scalars['timestamp']>;
  status: Scalars['String'];
};

export type GetGuestsByStatusOutput = {
  __typename?: 'GetGuestsByStatusOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetGuestsModifiedInput = {
  checkInForm?: InputMaybe<Scalars['timestamp']>;
  checkInTo?: InputMaybe<Scalars['timestamp']>;
  checkOutFrom?: InputMaybe<Scalars['timestamp']>;
  checkOutTo?: InputMaybe<Scalars['timestamp']>;
  inHouse?: InputMaybe<Scalars['Boolean']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertIDs?: InputMaybe<Scalars['Int']>;
  resultsFrom?: InputMaybe<Scalars['timestamp']>;
  resultsTo?: InputMaybe<Scalars['timestamp']>;
};

export type GetGuestsModifiedOutput = {
  __typename?: 'GetGuestsModifiedOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetHotelDetailsInput = {
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type GetHotelDetailsOutput = {
  __typename?: 'GetHotelDetailsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetHotelsInput = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyCity?: InputMaybe<Scalars['String']>;
  propertyIDs?: InputMaybe<Scalars['Int']>;
  propertyName?: InputMaybe<Scalars['String']>;
};

export type GetHotelsNearInput = {
  baseAddress: Scalars['String'];
  baseCity: Scalars['String'];
  baseZip: Scalars['String'];
  maxDistance: Scalars['Float'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyName?: InputMaybe<Scalars['String']>;
};

export type GetHotelsNearOutput = {
  __typename?: 'GetHotelsNearOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetHotelsOutput = {
  __typename?: 'GetHotelsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetHousekeepersInput = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['String']>;
};

export type GetHousekeepersOutput = {
  __typename?: 'GetHousekeepersOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetHousekeepingStatusInput = {
  housekeeperIDs?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  roomCondition?: InputMaybe<Scalars['String']>;
  roomOccupied?: InputMaybe<Scalars['Boolean']>;
  roomTypeIDs?: InputMaybe<Scalars['String']>;
};

export type GetHousekeepingStatusOutput = {
  __typename?: 'GetHousekeepingStatusOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetPaymentMethodsInput = {
  accessToken: Scalars['String'];
  lang?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
};

export type GetPaymentMethodsOutput = {
  __typename?: 'GetPaymentMethodsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetPaymentsInput = {
  houseAccountID?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID?: InputMaybe<Scalars['String']>;
};

export type GetPaymentsOutput = {
  __typename?: 'GetPaymentsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetReservationAssignmentsInput = {
  date?: InputMaybe<Scalars['timestamp']>;
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type GetReservationAssignmentsOutput = {
  __typename?: 'GetReservationAssignmentsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetReservationInput = {
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
};

export type GetReservationInvoiceInformationInput = {
  accessToken: Scalars['String'];
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
};

export type GetReservationInvoiceInformationOutput = {
  __typename?: 'GetReservationInvoiceInformationOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetReservationOutput = {
  __typename?: 'GetReservationOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetReservationRoomDetailsInput = {
  propertyID?: InputMaybe<Scalars['Int']>;
  subReservationID?: InputMaybe<Scalars['String']>;
};

export type GetReservationRoomDetailsOutput = {
  __typename?: 'GetReservationRoomDetailsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetReservationsInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type GetReservationsOutput = {
  __typename?: 'GetReservationsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetReservationsWithRateDetailsInput = {
  accessToken: Scalars['String'];
  excludeStatuses?: InputMaybe<Scalars['String']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedFrom?: InputMaybe<Scalars['timestamp']>;
  modifiedTo?: InputMaybe<Scalars['timestamp']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationCheckoutFrom?: InputMaybe<Scalars['timestamp']>;
  reservationCheckoutTo?: InputMaybe<Scalars['timestamp']>;
  reservationID?: InputMaybe<Scalars['String']>;
  resultsFrom?: InputMaybe<Scalars['timestamp']>;
  resultsTo?: InputMaybe<Scalars['timestamp']>;
  sortByRecent?: InputMaybe<Scalars['Boolean']>;
};

export type GetReservationsWithRateDetailsOutput = {
  __typename?: 'GetReservationsWithRateDetailsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetRoomTypesInput = {
  adults?: InputMaybe<Scalars['Int']>;
  children?: InputMaybe<Scalars['Int']>;
  detailedRates?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  maxGuests?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyCity?: InputMaybe<Scalars['String']>;
  propertyIDs?: InputMaybe<Scalars['String']>;
  propertyName?: InputMaybe<Scalars['String']>;
  roomTypeIDs?: InputMaybe<Scalars['String']>;
  roomTypeName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
};

export type GetRoomTypesOutput = {
  __typename?: 'GetRoomTypesOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetRoomsFeesAndTaxesInput = {
  endDate?: InputMaybe<Scalars['timestamp']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  roomsCount?: InputMaybe<Scalars['Int']>;
  roomsTotal?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
};

export type GetRoomsFeesAndTaxesOutput = {
  __typename?: 'GetRoomsFeesAndTaxesOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetRoomsInput = {
  accessToken: Scalars['String'];
  endDate?: InputMaybe<Scalars['timestamp']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyIDs?: InputMaybe<Scalars['String']>;
  roomTypeID: Scalars['Int'];
  roomTypeNameShort: Scalars['String'];
  startDate?: InputMaybe<Scalars['timestamp']>;
};

export type GetRoomsOutput = {
  __typename?: 'GetRoomsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetRoomsUnassignedInput = {
  propertyIDs?: InputMaybe<Scalars['Int']>;
};

export type GetRoomsUnassignedOutput = {
  __typename?: 'GetRoomsUnassignedOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetSourcesInput = {
  propertyIDs?: InputMaybe<Scalars['String']>;
};

export type GetSourcesOutput = {
  __typename?: 'GetSourcesOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type GetTransactionsInput = {
  accessToken: Scalars['String'];
  createdFrom?: InputMaybe<Scalars['String']>;
  createdTo?: InputMaybe<Scalars['String']>;
  guestID?: InputMaybe<Scalars['Int']>;
  houseAccountID?: InputMaybe<Scalars['Int']>;
  includeCredit?: InputMaybe<Scalars['Boolean']>;
  includeDebit?: InputMaybe<Scalars['Boolean']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedFrom?: InputMaybe<Scalars['timestamp']>;
  modifiedTo?: InputMaybe<Scalars['timestamp']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID?: InputMaybe<Scalars['String']>;
  resultsFrom?: InputMaybe<Scalars['timestamp']>;
  resultsTo?: InputMaybe<Scalars['timestamp']>;
  roomID?: InputMaybe<Scalars['String']>;
  subReservationID?: InputMaybe<Scalars['String']>;
  transactionIDs?: InputMaybe<Scalars['String']>;
};

export type GetTransactionsOutput = {
  __typename?: 'GetTransactionsOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type PostAccessTokenInput = {
  grant_type: Scalars['String'];
  refresh_token: Scalars['String'];
};

export type PostAdjustmentInput = {
  amount: Scalars['Float'];
  itemId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
  type: Scalars['String'];
};

export type PostAdjustmentOutput = {
  __typename?: 'PostAdjustmentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostCustomFieldInput = {
  applyTo?: InputMaybe<Scalars['String']>;
  displayed?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isPersonal?: InputMaybe<Scalars['Boolean']>;
  maxCharacters?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  propertyID?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  shortcode: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type PostCustomFieldOutput = {
  __typename?: 'PostCustomFieldOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostCustomPaymentMethodInput = {
  method: Scalars['String'];
  methodName?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
};

export type PostCustomPaymentMethodOutput = {
  __typename?: 'PostCustomPaymentMethodOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostEmailScheduleInput = {
  emailTemplateId: Scalars['Int'];
  propertyId?: InputMaybe<Scalars['Int']>;
  schedule?: InputMaybe<EmailSchedule>;
  scheduleName: Scalars['String'];
};

export type PostEmailScheduleOutput = {
  __typename?: 'PostEmailScheduleOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostEmailTemplateOutput = {
  __typename?: 'PostEmailTemplateOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostGuestDocumentInput = {
  file?: InputMaybe<Scalars['String']>;
  guestId?: InputMaybe<Scalars['Int']>;
  propertyId?: InputMaybe<Scalars['Int']>;
};

export type PostGuestDocumentOutput = {
  __typename?: 'PostGuestDocumentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostGuestNoteInput = {
  guestID: Scalars['Int'];
  guestNote: Scalars['String'];
  propertyID?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type PostGuestNoteOutput = {
  __typename?: 'PostGuestNoteOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostGuestOutput = {
  __typename?: 'PostGuestOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostGuestPhotoInput = {
  file: Scalars['String'];
  guestID: Scalars['Int'];
};

export type PostGuestPhotoOutput = {
  __typename?: 'PostGuestPhotoOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostHousekeeperInput = {
  name?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
};

export type PostHousekeeperOutput = {
  __typename?: 'PostHousekeeperOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostHousekeepingAssignmentInput = {
  housekeeperID?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  roomIDs?: InputMaybe<Scalars['String']>;
};

export type PostHousekeepingAssignmentOutput = {
  __typename?: 'PostHousekeepingAssignmentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostHousekeepingStatusInput = {
  doNotDisturb?: InputMaybe<Scalars['Boolean']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  roomCondition?: InputMaybe<Scalars['String']>;
  roomID?: InputMaybe<Scalars['String']>;
};

export type PostHousekeepingStatusOutput = {
  __typename?: 'PostHousekeepingStatusOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostPaymentInput = {
  accessToken: Scalars['String'];
  amount: Scalars['Float'];
  cardType: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  houseAccountID?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
  subReservationID?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PostPaymentOutput = {
  __typename?: 'PostPaymentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostReservationDocumentInput = {
  file?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID?: InputMaybe<Scalars['String']>;
};

export type PostReservationDocumentOutput = {
  __typename?: 'PostReservationDocumentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostReservationInput = {
  accessToken: Scalars['String'];
  adults: Array<InputMaybe<RoomAssignment>>;
  cardToken?: InputMaybe<Scalars['String']>;
  children: Array<InputMaybe<RoomAssignment>>;
  customFields?: InputMaybe<Array<InputMaybe<CustomField>>>;
  endDate: Scalars['timestamp'];
  estimatedArrivalTime?: InputMaybe<Scalars['timestamp']>;
  guestCountry: Scalars['String'];
  guestEmail: Scalars['String'];
  guestFirstName: Scalars['String'];
  guestGender?: InputMaybe<Scalars['String']>;
  guestLastName: Scalars['String'];
  guestPhone?: InputMaybe<Scalars['String']>;
  guestZip: Scalars['String'];
  paymentAuthorizationCode?: InputMaybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  promoCode?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  rooms: Array<CloudbedsRoom>;
  startDate: Scalars['timestamp'];
};

export type PostReservationOutput = {
  __typename?: 'PostReservationOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostRoomAssignInput = {
  accessToken: Scalars['String'];
  newRoomID: Scalars['String'];
  oldRoomID?: InputMaybe<Scalars['String']>;
  overrideRates?: InputMaybe<Scalars['Boolean']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
  roomTypeID?: InputMaybe<Scalars['Int']>;
};

export type PostRoomAssignOutput = {
  __typename?: 'PostRoomAssignOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostRoomCheckInInput = {
  accessToken: Scalars['String'];
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
  roomID?: InputMaybe<Scalars['String']>;
  subReservationID?: InputMaybe<Scalars['String']>;
};

export type PostRoomCheckInOutput = {
  __typename?: 'PostRoomCheckInOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostRoomCheckOutInput = {
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID?: InputMaybe<Scalars['String']>;
  roomID?: InputMaybe<Scalars['String']>;
  subReservationID?: InputMaybe<Scalars['String']>;
};

export type PostRoomCheckOutOutput = {
  __typename?: 'PostRoomCheckOutOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PostVoidPaymentInput = {
  houseAccountID?: InputMaybe<Scalars['Int']>;
  paymentID?: InputMaybe<Scalars['Int']>;
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID?: InputMaybe<Scalars['String']>;
};

export type PostVoidPaymentOutput = {
  __typename?: 'PostVoidPaymentOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PutGuestInput = {
  guestAddress1?: InputMaybe<Scalars['String']>;
  guestAddress2?: InputMaybe<Scalars['String']>;
  guestBirthDate?: InputMaybe<Scalars['timestamp']>;
  guestCellPhone?: InputMaybe<Scalars['String']>;
  guestCity?: InputMaybe<Scalars['String']>;
  guestCountry?: InputMaybe<Scalars['String']>;
  guestDocumentType?: InputMaybe<Scalars['String']>;
  guestEmail?: InputMaybe<Scalars['String']>;
  guestFirstName?: InputMaybe<Scalars['String']>;
  guestGender?: InputMaybe<Scalars['String']>;
  guestID?: InputMaybe<Scalars['Int']>;
  guestLastName?: InputMaybe<Scalars['String']>;
  guestPhone?: InputMaybe<Scalars['String']>;
  guestState?: InputMaybe<Scalars['String']>;
  guestZip?: InputMaybe<Scalars['String']>;
  propertyID?: InputMaybe<Scalars['Int']>;
};

export type PutGuestNoteOutput = {
  __typename?: 'PutGuestNoteOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PutGuestOutput = {
  __typename?: 'PutGuestOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PutHousekeeperOutput = {
  __typename?: 'PutHousekeeperOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PutReservationInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type PutReservationNoteInput = {
  propertyID?: InputMaybe<Scalars['Int']>;
  reservationID: Scalars['String'];
  reservationNote?: InputMaybe<Scalars['String']>;
  reservationNoteID: Scalars['Int'];
};

export type PutReservationNoteOutput = {
  __typename?: 'PutReservationNoteOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type PutReservationOutput = {
  __typename?: 'PutReservationOutput';
  data?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['jsonb']>;
};

export type ReservationEvent = {
  days: Scalars['Int'];
  event: Scalars['String'];
  time: Scalars['String'];
};

export type ReservationStatusChange = {
  status: Scalars['String'];
};

export type RoomAssignment = {
  quantity: Scalars['Int'];
  roomID?: InputMaybe<Scalars['Int']>;
  roomTypeID: Scalars['Int'];
};

export type SampleInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  accessToken: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "app_settings" */
export type App_Settings = {
  __typename?: 'app_settings';
  access_token: Scalars['jsonb'];
  id: Scalars['Int'];
};


/** columns and relationships of "app_settings" */
export type App_SettingsAccess_TokenArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "app_settings" */
export type App_Settings_Aggregate = {
  __typename?: 'app_settings_aggregate';
  aggregate?: Maybe<App_Settings_Aggregate_Fields>;
  nodes: Array<App_Settings>;
};

/** aggregate fields of "app_settings" */
export type App_Settings_Aggregate_Fields = {
  __typename?: 'app_settings_aggregate_fields';
  avg?: Maybe<App_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<App_Settings_Max_Fields>;
  min?: Maybe<App_Settings_Min_Fields>;
  stddev?: Maybe<App_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<App_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<App_Settings_Sum_Fields>;
  var_pop?: Maybe<App_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<App_Settings_Var_Samp_Fields>;
  variance?: Maybe<App_Settings_Variance_Fields>;
};


/** aggregate fields of "app_settings" */
export type App_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Settings_Append_Input = {
  access_token?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type App_Settings_Avg_Fields = {
  __typename?: 'app_settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "app_settings". All fields are combined with a logical 'AND'. */
export type App_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<App_Settings_Bool_Exp>>;
  _not?: InputMaybe<App_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<App_Settings_Bool_Exp>>;
  access_token?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_settings" */
export enum App_Settings_Constraint {
  /** unique or primary key constraint */
  AppSettingsPkey = 'app_settings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Settings_Delete_At_Path_Input = {
  access_token?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Settings_Delete_Elem_Input = {
  access_token?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Settings_Delete_Key_Input = {
  access_token?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "app_settings" */
export type App_Settings_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "app_settings" */
export type App_Settings_Insert_Input = {
  access_token?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type App_Settings_Max_Fields = {
  __typename?: 'app_settings_max_fields';
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type App_Settings_Min_Fields = {
  __typename?: 'app_settings_min_fields';
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "app_settings" */
export type App_Settings_Mutation_Response = {
  __typename?: 'app_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Settings>;
};

/** on_conflict condition type for table "app_settings" */
export type App_Settings_On_Conflict = {
  constraint: App_Settings_Constraint;
  update_columns?: Array<App_Settings_Update_Column>;
  where?: InputMaybe<App_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "app_settings". */
export type App_Settings_Order_By = {
  access_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: app_settings */
export type App_Settings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Settings_Prepend_Input = {
  access_token?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "app_settings" */
export enum App_Settings_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "app_settings" */
export type App_Settings_Set_Input = {
  access_token?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type App_Settings_Stddev_Fields = {
  __typename?: 'app_settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type App_Settings_Stddev_Pop_Fields = {
  __typename?: 'app_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type App_Settings_Stddev_Samp_Fields = {
  __typename?: 'app_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type App_Settings_Sum_Fields = {
  __typename?: 'app_settings_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "app_settings" */
export enum App_Settings_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type App_Settings_Var_Pop_Fields = {
  __typename?: 'app_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type App_Settings_Var_Samp_Fields = {
  __typename?: 'app_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type App_Settings_Variance_Fields = {
  __typename?: 'app_settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** this stores information about the people that suck */
export type Customer = {
  __typename?: 'customer';
  address?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  customer_notes: Array<Customer_Notes>;
  /** An aggregate relationship */
  customer_notes_aggregate: Customer_Notes_Aggregate;
  /** An array relationship */
  customer_projects: Array<Customer_Project>;
  /** An aggregate relationship */
  customer_projects_aggregate: Customer_Project_Aggregate;
  description?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  /** An array relationship */
  hosted_ats: Array<Hosted_At>;
  /** An aggregate relationship */
  hosted_ats_aggregate: Hosted_At_Aggregate;
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservations_aggregate: Reservation_Aggregate;
  seen_last?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_obj?: Maybe<Scalars['jsonb']>;
};


/** this stores information about the people that suck */
export type CustomerCustomer_NotesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerCustomer_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerCustomer_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerCustomer_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerHosted_AtsArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerHosted_Ats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** this stores information about the people that suck */
export type CustomerUser_ObjArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "customer" */
export type Customer_Aggregate = {
  __typename?: 'customer_aggregate';
  aggregate?: Maybe<Customer_Aggregate_Fields>;
  nodes: Array<Customer>;
};

/** aggregate fields of "customer" */
export type Customer_Aggregate_Fields = {
  __typename?: 'customer_aggregate_fields';
  avg?: Maybe<Customer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Max_Fields>;
  min?: Maybe<Customer_Min_Fields>;
  stddev?: Maybe<Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Sum_Fields>;
  var_pop?: Maybe<Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Var_Samp_Fields>;
  variance?: Maybe<Customer_Variance_Fields>;
};


/** aggregate fields of "customer" */
export type Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Append_Input = {
  user_obj?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Customer_Avg_Fields = {
  __typename?: 'customer_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "customer". All fields are combined with a logical 'AND'. */
export type Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Bool_Exp>>;
  _not?: InputMaybe<Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  birthdate?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_notes?: InputMaybe<Customer_Notes_Bool_Exp>;
  customer_projects?: InputMaybe<Customer_Project_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  hosted_ats?: InputMaybe<Hosted_At_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  reservations?: InputMaybe<Reservation_Bool_Exp>;
  seen_last?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_obj?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer" */
export enum Customer_Constraint {
  /** unique or primary key constraint */
  BlacklistUsersPkey = 'blacklist_users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Delete_At_Path_Input = {
  user_obj?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Delete_Elem_Input = {
  user_obj?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Delete_Key_Input = {
  user_obj?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "customer" */
export type Customer_Inc_Input = {
  rating?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "customer" */
export type Customer_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_notes?: InputMaybe<Customer_Notes_Arr_Rel_Insert_Input>;
  customer_projects?: InputMaybe<Customer_Project_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  hosted_ats?: InputMaybe<Hosted_At_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['numeric']>;
  reservations?: InputMaybe<Reservation_Arr_Rel_Insert_Input>;
  seen_last?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_obj?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Customer_Max_Fields = {
  __typename?: 'customer_max_fields';
  address?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  seen_last?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Customer_Min_Fields = {
  __typename?: 'customer_min_fields';
  address?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  seen_last?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "customer" */
export type Customer_Mutation_Response = {
  __typename?: 'customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer>;
};

/** columns and relationships of "customer_notes" */
export type Customer_Notes = {
  __typename?: 'customer_notes';
  author_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  customer?: Maybe<Customer>;
  customer_id?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "customer_notes" */
export type Customer_Notes_Aggregate = {
  __typename?: 'customer_notes_aggregate';
  aggregate?: Maybe<Customer_Notes_Aggregate_Fields>;
  nodes: Array<Customer_Notes>;
};

/** aggregate fields of "customer_notes" */
export type Customer_Notes_Aggregate_Fields = {
  __typename?: 'customer_notes_aggregate_fields';
  avg?: Maybe<Customer_Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Notes_Max_Fields>;
  min?: Maybe<Customer_Notes_Min_Fields>;
  stddev?: Maybe<Customer_Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Notes_Sum_Fields>;
  var_pop?: Maybe<Customer_Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Notes_Var_Samp_Fields>;
  variance?: Maybe<Customer_Notes_Variance_Fields>;
};


/** aggregate fields of "customer_notes" */
export type Customer_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_notes" */
export type Customer_Notes_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Notes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Notes_Max_Order_By>;
  min?: InputMaybe<Customer_Notes_Min_Order_By>;
  stddev?: InputMaybe<Customer_Notes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Notes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Notes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Notes_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Notes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Notes_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Notes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_notes" */
export type Customer_Notes_Arr_Rel_Insert_Input = {
  data: Array<Customer_Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Notes_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Notes_Avg_Fields = {
  __typename?: 'customer_notes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_notes" */
export type Customer_Notes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_notes". All fields are combined with a logical 'AND'. */
export type Customer_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Notes_Bool_Exp>>;
  _not?: InputMaybe<Customer_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Notes_Bool_Exp>>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  customer?: InputMaybe<Customer_Bool_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_notes" */
export enum Customer_Notes_Constraint {
  /** unique or primary key constraint */
  CustomerNotesPkey = 'customer_notes_pkey'
}

/** input type for incrementing numeric columns in table "customer_notes" */
export type Customer_Notes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer_notes" */
export type Customer_Notes_Insert_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Customer_Notes_Max_Fields = {
  __typename?: 'customer_notes_max_fields';
  author_id?: Maybe<Scalars['uuid']>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "customer_notes" */
export type Customer_Notes_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Notes_Min_Fields = {
  __typename?: 'customer_notes_min_fields';
  author_id?: Maybe<Scalars['uuid']>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "customer_notes" */
export type Customer_Notes_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_notes" */
export type Customer_Notes_Mutation_Response = {
  __typename?: 'customer_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Notes>;
};

/** on_conflict condition type for table "customer_notes" */
export type Customer_Notes_On_Conflict = {
  constraint: Customer_Notes_Constraint;
  update_columns?: Array<Customer_Notes_Update_Column>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_notes". */
export type Customer_Notes_Order_By = {
  author_id?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customer_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_notes */
export type Customer_Notes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "customer_notes" */
export enum Customer_Notes_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rating = 'rating'
}

/** input type for updating data in table "customer_notes" */
export type Customer_Notes_Set_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Customer_Notes_Stddev_Fields = {
  __typename?: 'customer_notes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_notes" */
export type Customer_Notes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Notes_Stddev_Pop_Fields = {
  __typename?: 'customer_notes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_notes" */
export type Customer_Notes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Notes_Stddev_Samp_Fields = {
  __typename?: 'customer_notes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_notes" */
export type Customer_Notes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Customer_Notes_Sum_Fields = {
  __typename?: 'customer_notes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_notes" */
export type Customer_Notes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "customer_notes" */
export enum Customer_Notes_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rating = 'rating'
}

/** aggregate var_pop on columns */
export type Customer_Notes_Var_Pop_Fields = {
  __typename?: 'customer_notes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_notes" */
export type Customer_Notes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Notes_Var_Samp_Fields = {
  __typename?: 'customer_notes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_notes" */
export type Customer_Notes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Notes_Variance_Fields = {
  __typename?: 'customer_notes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_notes" */
export type Customer_Notes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "customer" */
export type Customer_Obj_Rel_Insert_Input = {
  data: Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};

/** on_conflict condition type for table "customer" */
export type Customer_On_Conflict = {
  constraint: Customer_Constraint;
  update_columns?: Array<Customer_Update_Column>;
  where?: InputMaybe<Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "customer". */
export type Customer_Order_By = {
  address?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_notes_aggregate?: InputMaybe<Customer_Notes_Aggregate_Order_By>;
  customer_projects_aggregate?: InputMaybe<Customer_Project_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  hosted_ats_aggregate?: InputMaybe<Hosted_At_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservations_aggregate?: InputMaybe<Reservation_Aggregate_Order_By>;
  seen_last?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_obj?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer */
export type Customer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Prepend_Input = {
  user_obj?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "customer_project" */
export type Customer_Project = {
  __typename?: 'customer_project';
  /** An object relationship */
  customer: Customer;
  customer_id: Scalars['uuid'];
  id: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "customer_project" */
export type Customer_Project_Aggregate = {
  __typename?: 'customer_project_aggregate';
  aggregate?: Maybe<Customer_Project_Aggregate_Fields>;
  nodes: Array<Customer_Project>;
};

/** aggregate fields of "customer_project" */
export type Customer_Project_Aggregate_Fields = {
  __typename?: 'customer_project_aggregate_fields';
  avg?: Maybe<Customer_Project_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Project_Max_Fields>;
  min?: Maybe<Customer_Project_Min_Fields>;
  stddev?: Maybe<Customer_Project_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Project_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Project_Sum_Fields>;
  var_pop?: Maybe<Customer_Project_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Project_Var_Samp_Fields>;
  variance?: Maybe<Customer_Project_Variance_Fields>;
};


/** aggregate fields of "customer_project" */
export type Customer_Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_project" */
export type Customer_Project_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Project_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Project_Max_Order_By>;
  min?: InputMaybe<Customer_Project_Min_Order_By>;
  stddev?: InputMaybe<Customer_Project_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Project_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Project_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Project_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Project_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Project_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Project_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_project" */
export type Customer_Project_Arr_Rel_Insert_Input = {
  data: Array<Customer_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Project_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Project_Avg_Fields = {
  __typename?: 'customer_project_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_project" */
export type Customer_Project_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_project". All fields are combined with a logical 'AND'. */
export type Customer_Project_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Project_Bool_Exp>>;
  _not?: InputMaybe<Customer_Project_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Project_Bool_Exp>>;
  customer?: InputMaybe<Customer_Bool_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_project" */
export enum Customer_Project_Constraint {
  /** unique or primary key constraint */
  CustomerProjectPkey = 'customer_project_pkey'
}

/** input type for incrementing numeric columns in table "customer_project" */
export type Customer_Project_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customer_project" */
export type Customer_Project_Insert_Input = {
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Customer_Project_Max_Fields = {
  __typename?: 'customer_project_max_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "customer_project" */
export type Customer_Project_Max_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Project_Min_Fields = {
  __typename?: 'customer_project_min_fields';
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "customer_project" */
export type Customer_Project_Min_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_project" */
export type Customer_Project_Mutation_Response = {
  __typename?: 'customer_project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Project>;
};

/** on_conflict condition type for table "customer_project" */
export type Customer_Project_On_Conflict = {
  constraint: Customer_Project_Constraint;
  update_columns?: Array<Customer_Project_Update_Column>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_project". */
export type Customer_Project_Order_By = {
  customer?: InputMaybe<Customer_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_project */
export type Customer_Project_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "customer_project" */
export enum Customer_Project_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "customer_project" */
export type Customer_Project_Set_Input = {
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Customer_Project_Stddev_Fields = {
  __typename?: 'customer_project_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_project" */
export type Customer_Project_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Project_Stddev_Pop_Fields = {
  __typename?: 'customer_project_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_project" */
export type Customer_Project_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Project_Stddev_Samp_Fields = {
  __typename?: 'customer_project_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_project" */
export type Customer_Project_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Customer_Project_Sum_Fields = {
  __typename?: 'customer_project_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customer_project" */
export type Customer_Project_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "customer_project" */
export enum Customer_Project_Update_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** aggregate var_pop on columns */
export type Customer_Project_Var_Pop_Fields = {
  __typename?: 'customer_project_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_project" */
export type Customer_Project_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Project_Var_Samp_Fields = {
  __typename?: 'customer_project_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_project" */
export type Customer_Project_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Project_Variance_Fields = {
  __typename?: 'customer_project_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_project" */
export type Customer_Project_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** select columns of table "customer" */
export enum Customer_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Rating = 'rating',
  /** column name */
  SeenLast = 'seen_last',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserObj = 'user_obj'
}

/** input type for updating data in table "customer" */
export type Customer_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['numeric']>;
  seen_last?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_obj?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Customer_Stddev_Fields = {
  __typename?: 'customer_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Stddev_Pop_Fields = {
  __typename?: 'customer_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Stddev_Samp_Fields = {
  __typename?: 'customer_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Customer_Sum_Fields = {
  __typename?: 'customer_sum_fields';
  rating?: Maybe<Scalars['numeric']>;
};

/** update columns of table "customer" */
export enum Customer_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Rating = 'rating',
  /** column name */
  SeenLast = 'seen_last',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserObj = 'user_obj'
}

/** aggregate var_pop on columns */
export type Customer_Var_Pop_Fields = {
  __typename?: 'customer_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Var_Samp_Fields = {
  __typename?: 'customer_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Variance_Fields = {
  __typename?: 'customer_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "hosted_at" */
export type Hosted_At = {
  __typename?: 'hosted_at';
  /** An object relationship */
  customer: Customer;
  guest_id: Scalars['uuid'];
  id: Scalars['Int'];
  /** An object relationship */
  occupied_room?: Maybe<Occupied_Room>;
  occupied_room_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "hosted_at" */
export type Hosted_At_Aggregate = {
  __typename?: 'hosted_at_aggregate';
  aggregate?: Maybe<Hosted_At_Aggregate_Fields>;
  nodes: Array<Hosted_At>;
};

/** aggregate fields of "hosted_at" */
export type Hosted_At_Aggregate_Fields = {
  __typename?: 'hosted_at_aggregate_fields';
  avg?: Maybe<Hosted_At_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hosted_At_Max_Fields>;
  min?: Maybe<Hosted_At_Min_Fields>;
  stddev?: Maybe<Hosted_At_Stddev_Fields>;
  stddev_pop?: Maybe<Hosted_At_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hosted_At_Stddev_Samp_Fields>;
  sum?: Maybe<Hosted_At_Sum_Fields>;
  var_pop?: Maybe<Hosted_At_Var_Pop_Fields>;
  var_samp?: Maybe<Hosted_At_Var_Samp_Fields>;
  variance?: Maybe<Hosted_At_Variance_Fields>;
};


/** aggregate fields of "hosted_at" */
export type Hosted_At_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hosted_At_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hosted_at" */
export type Hosted_At_Aggregate_Order_By = {
  avg?: InputMaybe<Hosted_At_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hosted_At_Max_Order_By>;
  min?: InputMaybe<Hosted_At_Min_Order_By>;
  stddev?: InputMaybe<Hosted_At_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hosted_At_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hosted_At_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hosted_At_Sum_Order_By>;
  var_pop?: InputMaybe<Hosted_At_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hosted_At_Var_Samp_Order_By>;
  variance?: InputMaybe<Hosted_At_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hosted_at" */
export type Hosted_At_Arr_Rel_Insert_Input = {
  data: Array<Hosted_At_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hosted_At_On_Conflict>;
};

/** aggregate avg on columns */
export type Hosted_At_Avg_Fields = {
  __typename?: 'hosted_at_avg_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hosted_at" */
export type Hosted_At_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hosted_at". All fields are combined with a logical 'AND'. */
export type Hosted_At_Bool_Exp = {
  _and?: InputMaybe<Array<Hosted_At_Bool_Exp>>;
  _not?: InputMaybe<Hosted_At_Bool_Exp>;
  _or?: InputMaybe<Array<Hosted_At_Bool_Exp>>;
  customer?: InputMaybe<Customer_Bool_Exp>;
  guest_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  occupied_room?: InputMaybe<Occupied_Room_Bool_Exp>;
  occupied_room_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "hosted_at" */
export enum Hosted_At_Constraint {
  /** unique or primary key constraint */
  HostedAtPkey = 'hosted_at_pkey'
}

/** input type for incrementing numeric columns in table "hosted_at" */
export type Hosted_At_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  occupied_room_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hosted_at" */
export type Hosted_At_Insert_Input = {
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  guest_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  occupied_room?: InputMaybe<Occupied_Room_Obj_Rel_Insert_Input>;
  occupied_room_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Hosted_At_Max_Fields = {
  __typename?: 'hosted_at_max_fields';
  guest_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  occupied_room_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "hosted_at" */
export type Hosted_At_Max_Order_By = {
  guest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hosted_At_Min_Fields = {
  __typename?: 'hosted_at_min_fields';
  guest_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  occupied_room_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "hosted_at" */
export type Hosted_At_Min_Order_By = {
  guest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hosted_at" */
export type Hosted_At_Mutation_Response = {
  __typename?: 'hosted_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hosted_At>;
};

/** on_conflict condition type for table "hosted_at" */
export type Hosted_At_On_Conflict = {
  constraint: Hosted_At_Constraint;
  update_columns?: Array<Hosted_At_Update_Column>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};

/** Ordering options when selecting data from "hosted_at". */
export type Hosted_At_Order_By = {
  customer?: InputMaybe<Customer_Order_By>;
  guest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occupied_room?: InputMaybe<Occupied_Room_Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hosted_at */
export type Hosted_At_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "hosted_at" */
export enum Hosted_At_Select_Column {
  /** column name */
  GuestId = 'guest_id',
  /** column name */
  Id = 'id',
  /** column name */
  OccupiedRoomId = 'occupied_room_id'
}

/** input type for updating data in table "hosted_at" */
export type Hosted_At_Set_Input = {
  guest_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  occupied_room_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Hosted_At_Stddev_Fields = {
  __typename?: 'hosted_at_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hosted_at" */
export type Hosted_At_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hosted_At_Stddev_Pop_Fields = {
  __typename?: 'hosted_at_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hosted_at" */
export type Hosted_At_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hosted_At_Stddev_Samp_Fields = {
  __typename?: 'hosted_at_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hosted_at" */
export type Hosted_At_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Hosted_At_Sum_Fields = {
  __typename?: 'hosted_at_sum_fields';
  id?: Maybe<Scalars['Int']>;
  occupied_room_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hosted_at" */
export type Hosted_At_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** update columns of table "hosted_at" */
export enum Hosted_At_Update_Column {
  /** column name */
  GuestId = 'guest_id',
  /** column name */
  Id = 'id',
  /** column name */
  OccupiedRoomId = 'occupied_room_id'
}

/** aggregate var_pop on columns */
export type Hosted_At_Var_Pop_Fields = {
  __typename?: 'hosted_at_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hosted_at" */
export type Hosted_At_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hosted_At_Var_Samp_Fields = {
  __typename?: 'hosted_at_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hosted_at" */
export type Hosted_At_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hosted_At_Variance_Fields = {
  __typename?: 'hosted_at_variance_fields';
  id?: Maybe<Scalars['Float']>;
  occupied_room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hosted_at" */
export type Hosted_At_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  occupied_room_id?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  accessTokenCheck?: Maybe<AccessTokenCheckOutput>;
  addCustomer?: Maybe<AddCustomerOutput>;
  cloudbeds?: Maybe<CloudbedsOutput>;
  cloudbeds_adjustment_deleteAdjustment?: Maybe<DeleteAdjustmentOutput>;
  cloudbeds_adjustment_getAdjustment?: Maybe<GetAdjustmentOutput>;
  cloudbeds_adjustment_getAdjustments?: Maybe<GetAdjustmentsOutput>;
  cloudbeds_adjustment_postAdjustment?: Maybe<PostAdjustmentOutput>;
  cloudbeds_customFields_getCustomFields?: Maybe<GetCustomFieldsOutput>;
  cloudbeds_customFields_postCustomField?: Maybe<PostCustomFieldOutput>;
  cloudbeds_dashboard_getDashboard?: Maybe<GetDashboardOutput>;
  cloudbeds_emails_getEmailSchedule?: Maybe<GetEmailScheduleOutput>;
  cloudbeds_emails_getEmailTemplates?: Maybe<GetEmailScheduleOutput>;
  cloudbeds_emails_postEmailSchedule?: Maybe<PostEmailScheduleOutput>;
  cloudbeds_emails_postEmailTemplate?: Maybe<PostEmailTemplateOutput>;
  cloudbeds_guest_deleteGuestNote?: Maybe<DeleteGuestNoteOutput>;
  cloudbeds_guest_getGuestList?: Maybe<GetGuestListOutput>;
  cloudbeds_guest_getGuestNotes?: Maybe<GetGuestNotesOutput>;
  cloudbeds_guest_getGuestsByFilter?: Maybe<GetGuestsByFilterOutput>;
  cloudbeds_guest_getGuestsByStatus?: Maybe<GetGuestsByStatusOutput>;
  cloudbeds_guest_getGuestsModified?: Maybe<GetGuestsModifiedOutput>;
  cloudbeds_guest_postGuest?: Maybe<PostGuestOutput>;
  cloudbeds_guest_postGuestDocument?: Maybe<PostGuestDocumentOutput>;
  cloudbeds_guest_postGuestNote?: Maybe<PostGuestNoteOutput>;
  cloudbeds_guest_postGuestPhoto?: Maybe<PostGuestPhotoOutput>;
  cloudbeds_guest_postGuestsToRoom?: Maybe<SampleOutput>;
  cloudbeds_guest_putGuest?: Maybe<PutGuestOutput>;
  cloudbeds_guest_putGuestNote?: Maybe<PutGuestNoteOutput>;
  cloudbeds_hotel_getHotelDetails?: Maybe<GetHotelDetailsOutput>;
  cloudbeds_hotel_getHotels?: Maybe<GetHotelsOutput>;
  cloudbeds_hotel_getHotelsNear?: Maybe<GetHotelsNearOutput>;
  cloudbeds_housekeeping_getHousekeepers?: Maybe<GetHousekeepersOutput>;
  cloudbeds_housekeeping_getHousekeepingStatus?: Maybe<GetHousekeepingStatusOutput>;
  cloudbeds_housekeeping_postHousekeeper?: Maybe<PostHousekeeperOutput>;
  cloudbeds_housekeeping_postHousekeepingAssignment?: Maybe<PostHousekeepingAssignmentOutput>;
  cloudbeds_housekeeping_postHousekeepingStatus?: Maybe<PostHousekeepingStatusOutput>;
  cloudbeds_housekeeping_putHousekeeper?: Maybe<PutHousekeeperOutput>;
  cloudbeds_payment_getPaymentMethods?: Maybe<GetPaymentMethodsOutput>;
  cloudbeds_payment_getPayments?: Maybe<GetPaymentsOutput>;
  cloudbeds_payment_getTransactions?: Maybe<GetTransactionsOutput>;
  cloudbeds_payment_postCustomPaymentMethod?: Maybe<PostCustomPaymentMethodOutput>;
  cloudbeds_payment_postPayment?: Maybe<PostPaymentOutput>;
  cloudbeds_payment_postVoidPayment?: Maybe<PostVoidPaymentOutput>;
  cloudbeds_reservation_deleteReservationNote?: Maybe<DeleteReservationNoteOutput>;
  cloudbeds_reservation_getReservation?: Maybe<GetReservationOutput>;
  cloudbeds_reservation_getReservationAssignments?: Maybe<GetReservationAssignmentsOutput>;
  cloudbeds_reservation_getReservations?: Maybe<GetReservationsOutput>;
  cloudbeds_reservation_getSources?: Maybe<GetSourcesOutput>;
  cloudbeds_reservation_postReservation?: Maybe<PostReservationOutput>;
  cloudbeds_reservation_postReservationDocument?: Maybe<PostReservationDocumentOutput>;
  cloudbeds_reservation_putReservation?: Maybe<PutReservationOutput>;
  cloudbeds_reservation_putReservationNote?: Maybe<PutReservationNoteOutput>;
  cloudbeds_room_getAvailableRoomTypes?: Maybe<GetAvailableRoomTypesOutput>;
  cloudbeds_room_getReservationRoomDetails?: Maybe<GetReservationRoomDetailsOutput>;
  cloudbeds_room_getRoomTypes?: Maybe<GetRoomTypesOutput>;
  cloudbeds_room_getRooms?: Maybe<GetRoomsOutput>;
  cloudbeds_room_getRoomsFeesAndTaxes?: Maybe<GetRoomsFeesAndTaxesOutput>;
  cloudbeds_room_postRoomAssign?: Maybe<PostRoomAssignOutput>;
  cloudbeds_room_postRoomCheckIn?: Maybe<PostRoomCheckInOutput>;
  cloudbeds_room_postRoomCheckOut?: Maybe<PostRoomCheckOutOutput>;
  cloudbeds_rooms_getRoomsUnassigned?: Maybe<GetRoomsUnassignedOutput>;
  cloudbeds_rooms_postRoomAssign?: Maybe<PostRoomAssignOutput>;
  /** delete data from the table: "app_settings" */
  delete_app_settings?: Maybe<App_Settings_Mutation_Response>;
  /** delete single row from the table: "app_settings" */
  delete_app_settings_by_pk?: Maybe<App_Settings>;
  /** delete data from the table: "customer" */
  delete_customer?: Maybe<Customer_Mutation_Response>;
  /** delete single row from the table: "customer" */
  delete_customer_by_pk?: Maybe<Customer>;
  /** delete data from the table: "customer_notes" */
  delete_customer_notes?: Maybe<Customer_Notes_Mutation_Response>;
  /** delete single row from the table: "customer_notes" */
  delete_customer_notes_by_pk?: Maybe<Customer_Notes>;
  /** delete data from the table: "customer_project" */
  delete_customer_project?: Maybe<Customer_Project_Mutation_Response>;
  /** delete single row from the table: "customer_project" */
  delete_customer_project_by_pk?: Maybe<Customer_Project>;
  /** delete data from the table: "hosted_at" */
  delete_hosted_at?: Maybe<Hosted_At_Mutation_Response>;
  /** delete single row from the table: "hosted_at" */
  delete_hosted_at_by_pk?: Maybe<Hosted_At>;
  /** delete data from the table: "occupied_room" */
  delete_occupied_room?: Maybe<Occupied_Room_Mutation_Response>;
  /** delete single row from the table: "occupied_room" */
  delete_occupied_room_by_pk?: Maybe<Occupied_Room>;
  /** delete data from the table: "permissions" */
  delete_permissions?: Maybe<Permissions_Mutation_Response>;
  /** delete single row from the table: "permissions" */
  delete_permissions_by_pk?: Maybe<Permissions>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "reservation" */
  delete_reservation?: Maybe<Reservation_Mutation_Response>;
  /** delete single row from the table: "reservation" */
  delete_reservation_by_pk?: Maybe<Reservation>;
  /** delete data from the table: "reserved_room" */
  delete_reserved_room?: Maybe<Reserved_Room_Mutation_Response>;
  /** delete single row from the table: "reserved_room" */
  delete_reserved_room_by_pk?: Maybe<Reserved_Room>;
  /** delete data from the table: "room" */
  delete_room?: Maybe<Room_Mutation_Response>;
  /** delete single row from the table: "room" */
  delete_room_by_pk?: Maybe<Room>;
  /** delete data from the table: "room_type" */
  delete_room_type?: Maybe<Room_Type_Mutation_Response>;
  /** delete single row from the table: "room_type" */
  delete_room_type_by_pk?: Maybe<Room_Type>;
  /** insert data into the table: "app_settings" */
  insert_app_settings?: Maybe<App_Settings_Mutation_Response>;
  /** insert a single row into the table: "app_settings" */
  insert_app_settings_one?: Maybe<App_Settings>;
  /** insert data into the table: "customer" */
  insert_customer?: Maybe<Customer_Mutation_Response>;
  /** insert data into the table: "customer_notes" */
  insert_customer_notes?: Maybe<Customer_Notes_Mutation_Response>;
  /** insert a single row into the table: "customer_notes" */
  insert_customer_notes_one?: Maybe<Customer_Notes>;
  /** insert a single row into the table: "customer" */
  insert_customer_one?: Maybe<Customer>;
  /** insert data into the table: "customer_project" */
  insert_customer_project?: Maybe<Customer_Project_Mutation_Response>;
  /** insert a single row into the table: "customer_project" */
  insert_customer_project_one?: Maybe<Customer_Project>;
  /** insert data into the table: "hosted_at" */
  insert_hosted_at?: Maybe<Hosted_At_Mutation_Response>;
  /** insert a single row into the table: "hosted_at" */
  insert_hosted_at_one?: Maybe<Hosted_At>;
  /** insert data into the table: "occupied_room" */
  insert_occupied_room?: Maybe<Occupied_Room_Mutation_Response>;
  /** insert a single row into the table: "occupied_room" */
  insert_occupied_room_one?: Maybe<Occupied_Room>;
  /** insert data into the table: "permissions" */
  insert_permissions?: Maybe<Permissions_Mutation_Response>;
  /** insert a single row into the table: "permissions" */
  insert_permissions_one?: Maybe<Permissions>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "reservation" */
  insert_reservation?: Maybe<Reservation_Mutation_Response>;
  /** insert a single row into the table: "reservation" */
  insert_reservation_one?: Maybe<Reservation>;
  /** insert data into the table: "reserved_room" */
  insert_reserved_room?: Maybe<Reserved_Room_Mutation_Response>;
  /** insert a single row into the table: "reserved_room" */
  insert_reserved_room_one?: Maybe<Reserved_Room>;
  /** insert data into the table: "room" */
  insert_room?: Maybe<Room_Mutation_Response>;
  /** insert a single row into the table: "room" */
  insert_room_one?: Maybe<Room>;
  /** insert data into the table: "room_type" */
  insert_room_type?: Maybe<Room_Type_Mutation_Response>;
  /** insert a single row into the table: "room_type" */
  insert_room_type_one?: Maybe<Room_Type>;
  postAccessToken?: Maybe<AccessTokenOutput>;
  /** update data of the table: "app_settings" */
  update_app_settings?: Maybe<App_Settings_Mutation_Response>;
  /** update single row of the table: "app_settings" */
  update_app_settings_by_pk?: Maybe<App_Settings>;
  /** update data of the table: "customer" */
  update_customer?: Maybe<Customer_Mutation_Response>;
  /** update single row of the table: "customer" */
  update_customer_by_pk?: Maybe<Customer>;
  /** update data of the table: "customer_notes" */
  update_customer_notes?: Maybe<Customer_Notes_Mutation_Response>;
  /** update single row of the table: "customer_notes" */
  update_customer_notes_by_pk?: Maybe<Customer_Notes>;
  /** update data of the table: "customer_project" */
  update_customer_project?: Maybe<Customer_Project_Mutation_Response>;
  /** update single row of the table: "customer_project" */
  update_customer_project_by_pk?: Maybe<Customer_Project>;
  /** update data of the table: "hosted_at" */
  update_hosted_at?: Maybe<Hosted_At_Mutation_Response>;
  /** update single row of the table: "hosted_at" */
  update_hosted_at_by_pk?: Maybe<Hosted_At>;
  /** update data of the table: "occupied_room" */
  update_occupied_room?: Maybe<Occupied_Room_Mutation_Response>;
  /** update single row of the table: "occupied_room" */
  update_occupied_room_by_pk?: Maybe<Occupied_Room>;
  /** update data of the table: "permissions" */
  update_permissions?: Maybe<Permissions_Mutation_Response>;
  /** update single row of the table: "permissions" */
  update_permissions_by_pk?: Maybe<Permissions>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update data of the table: "reservation" */
  update_reservation?: Maybe<Reservation_Mutation_Response>;
  /** update single row of the table: "reservation" */
  update_reservation_by_pk?: Maybe<Reservation>;
  /** update data of the table: "reserved_room" */
  update_reserved_room?: Maybe<Reserved_Room_Mutation_Response>;
  /** update single row of the table: "reserved_room" */
  update_reserved_room_by_pk?: Maybe<Reserved_Room>;
  /** update data of the table: "room" */
  update_room?: Maybe<Room_Mutation_Response>;
  /** update single row of the table: "room" */
  update_room_by_pk?: Maybe<Room>;
  /** update data of the table: "room_type" */
  update_room_type?: Maybe<Room_Type_Mutation_Response>;
  /** update single row of the table: "room_type" */
  update_room_type_by_pk?: Maybe<Room_Type>;
};


/** mutation root */
export type Mutation_RootAccessTokenCheckArgs = {
  arg1: AccessTokenCheckInput;
};


/** mutation root */
export type Mutation_RootAddCustomerArgs = {
  arg1: AddCustomerInput;
};


/** mutation root */
export type Mutation_RootCloudbedsArgs = {
  arg1: CloudbedsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Adjustment_DeleteAdjustmentArgs = {
  arg1: DeleteAdjustmentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Adjustment_GetAdjustmentArgs = {
  arg1: GetAdjustmentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Adjustment_GetAdjustmentsArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Adjustment_PostAdjustmentArgs = {
  arg1: PostAdjustmentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_CustomFields_GetCustomFieldsArgs = {
  arg1: GetCustomFieldsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_CustomFields_PostCustomFieldArgs = {
  arg1: PostCustomFieldInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Dashboard_GetDashboardArgs = {
  arg1: GetDashboardInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Emails_GetEmailScheduleArgs = {
  arg1: GetEmailScheduleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Emails_GetEmailTemplatesArgs = {
  arg1: GetEmailTemplatesInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Emails_PostEmailScheduleArgs = {
  arg1: PostEmailScheduleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Emails_PostEmailTemplateArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_DeleteGuestNoteArgs = {
  arg1: DeleteGuestNoteInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_GetGuestListArgs = {
  arg1: GetGuestListInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_GetGuestNotesArgs = {
  arg1: GetGuestNotesInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_GetGuestsByFilterArgs = {
  arg1: GetGuestsByFilterInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_GetGuestsByStatusArgs = {
  arg1: GetGuestsByStatusInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_GetGuestsModifiedArgs = {
  arg1: GetGuestsModifiedInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PostGuestArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PostGuestDocumentArgs = {
  arg1: PostGuestDocumentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PostGuestNoteArgs = {
  arg1: PostGuestNoteInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PostGuestPhotoArgs = {
  arg1: PostGuestPhotoInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PostGuestsToRoomArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PutGuestArgs = {
  arg1: PutGuestInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Guest_PutGuestNoteArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Hotel_GetHotelDetailsArgs = {
  arg1: GetHotelDetailsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Hotel_GetHotelsArgs = {
  arg1: GetHotelsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Hotel_GetHotelsNearArgs = {
  arg1: GetHotelsNearInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Housekeeping_GetHousekeepersArgs = {
  arg1: GetHousekeepersInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Housekeeping_GetHousekeepingStatusArgs = {
  arg1: GetHousekeepingStatusInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Housekeeping_PostHousekeeperArgs = {
  arg1: PostHousekeeperInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Housekeeping_PostHousekeepingAssignmentArgs = {
  arg1: PostHousekeepingAssignmentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Housekeeping_PostHousekeepingStatusArgs = {
  arg1: PostHousekeepingStatusInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Housekeeping_PutHousekeeperArgs = {
  arg1: SampleInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Payment_GetPaymentMethodsArgs = {
  arg1: GetPaymentMethodsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Payment_GetPaymentsArgs = {
  arg1: GetPaymentsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Payment_GetTransactionsArgs = {
  arg1: GetTransactionsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Payment_PostCustomPaymentMethodArgs = {
  arg1: PostCustomPaymentMethodInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Payment_PostPaymentArgs = {
  arg1: PostPaymentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Payment_PostVoidPaymentArgs = {
  arg1: PostVoidPaymentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_DeleteReservationNoteArgs = {
  arg1: DeleteReservationNoteInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_GetReservationArgs = {
  arg1: GetReservationInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_GetReservationAssignmentsArgs = {
  arg1: GetReservationAssignmentsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_GetReservationsArgs = {
  arg1: GetReservationsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_GetSourcesArgs = {
  arg1: GetSourcesInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_PostReservationArgs = {
  arg1: PostReservationInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_PostReservationDocumentArgs = {
  arg1: PostReservationDocumentInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_PutReservationArgs = {
  arg1: PutReservationInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Reservation_PutReservationNoteArgs = {
  arg1: PutReservationNoteInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_GetAvailableRoomTypesArgs = {
  arg1: GetAvailableRoomTypesInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_GetReservationRoomDetailsArgs = {
  arg1: GetReservationRoomDetailsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_GetRoomTypesArgs = {
  arg1: GetRoomTypesInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_GetRoomsArgs = {
  arg1: GetRoomsInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_GetRoomsFeesAndTaxesArgs = {
  arg1: GetRoomsFeesAndTaxesInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_PostRoomAssignArgs = {
  arg1: PostRoomAssignInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_PostRoomCheckInArgs = {
  arg1: PostRoomCheckInInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Room_PostRoomCheckOutArgs = {
  arg1: PostRoomCheckOutInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Rooms_GetRoomsUnassignedArgs = {
  arg1: GetRoomsUnassignedInput;
};


/** mutation root */
export type Mutation_RootCloudbeds_Rooms_PostRoomAssignArgs = {
  arg1: PostRoomAssignInput;
};


/** mutation root */
export type Mutation_RootDelete_App_SettingsArgs = {
  where: App_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CustomerArgs = {
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_NotesArgs = {
  where: Customer_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Customer_ProjectArgs = {
  where: Customer_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_Project_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Hosted_AtArgs = {
  where: Hosted_At_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hosted_At_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Occupied_RoomArgs = {
  where: Occupied_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Occupied_Room_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PermissionsArgs = {
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReservationArgs = {
  where: Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Reserved_RoomArgs = {
  where: Reserved_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reserved_Room_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RoomArgs = {
  where: Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Room_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Room_TypeArgs = {
  where: Room_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Room_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_App_SettingsArgs = {
  objects: Array<App_Settings_Insert_Input>;
  on_conflict?: InputMaybe<App_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Settings_OneArgs = {
  object: App_Settings_Insert_Input;
  on_conflict?: InputMaybe<App_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomerArgs = {
  objects: Array<Customer_Insert_Input>;
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_NotesArgs = {
  objects: Array<Customer_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Notes_OneArgs = {
  object: Customer_Notes_Insert_Input;
  on_conflict?: InputMaybe<Customer_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_OneArgs = {
  object: Customer_Insert_Input;
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_ProjectArgs = {
  objects: Array<Customer_Project_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_Project_OneArgs = {
  object: Customer_Project_Insert_Input;
  on_conflict?: InputMaybe<Customer_Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hosted_AtArgs = {
  objects: Array<Hosted_At_Insert_Input>;
  on_conflict?: InputMaybe<Hosted_At_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hosted_At_OneArgs = {
  object: Hosted_At_Insert_Input;
  on_conflict?: InputMaybe<Hosted_At_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Occupied_RoomArgs = {
  objects: Array<Occupied_Room_Insert_Input>;
  on_conflict?: InputMaybe<Occupied_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Occupied_Room_OneArgs = {
  object: Occupied_Room_Insert_Input;
  on_conflict?: InputMaybe<Occupied_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PermissionsArgs = {
  objects: Array<Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Permissions_OneArgs = {
  object: Permissions_Insert_Input;
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReservationArgs = {
  objects: Array<Reservation_Insert_Input>;
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_OneArgs = {
  object: Reservation_Insert_Input;
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reserved_RoomArgs = {
  objects: Array<Reserved_Room_Insert_Input>;
  on_conflict?: InputMaybe<Reserved_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reserved_Room_OneArgs = {
  object: Reserved_Room_Insert_Input;
  on_conflict?: InputMaybe<Reserved_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomArgs = {
  objects: Array<Room_Insert_Input>;
  on_conflict?: InputMaybe<Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_OneArgs = {
  object: Room_Insert_Input;
  on_conflict?: InputMaybe<Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_TypeArgs = {
  objects: Array<Room_Type_Insert_Input>;
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Room_Type_OneArgs = {
  object: Room_Type_Insert_Input;
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootPostAccessTokenArgs = {
  arg1: PostAccessTokenInput;
};


/** mutation root */
export type Mutation_RootUpdate_App_SettingsArgs = {
  _append?: InputMaybe<App_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<App_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Settings_Delete_Key_Input>;
  _inc?: InputMaybe<App_Settings_Inc_Input>;
  _prepend?: InputMaybe<App_Settings_Prepend_Input>;
  _set?: InputMaybe<App_Settings_Set_Input>;
  where: App_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Settings_By_PkArgs = {
  _append?: InputMaybe<App_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<App_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Settings_Delete_Key_Input>;
  _inc?: InputMaybe<App_Settings_Inc_Input>;
  _prepend?: InputMaybe<App_Settings_Prepend_Input>;
  _set?: InputMaybe<App_Settings_Set_Input>;
  pk_columns: App_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CustomerArgs = {
  _append?: InputMaybe<Customer_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Inc_Input>;
  _prepend?: InputMaybe<Customer_Prepend_Input>;
  _set?: InputMaybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_By_PkArgs = {
  _append?: InputMaybe<Customer_Append_Input>;
  _delete_at_path?: InputMaybe<Customer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Customer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Customer_Delete_Key_Input>;
  _inc?: InputMaybe<Customer_Inc_Input>;
  _prepend?: InputMaybe<Customer_Prepend_Input>;
  _set?: InputMaybe<Customer_Set_Input>;
  pk_columns: Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_NotesArgs = {
  _inc?: InputMaybe<Customer_Notes_Inc_Input>;
  _set?: InputMaybe<Customer_Notes_Set_Input>;
  where: Customer_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Notes_By_PkArgs = {
  _inc?: InputMaybe<Customer_Notes_Inc_Input>;
  _set?: InputMaybe<Customer_Notes_Set_Input>;
  pk_columns: Customer_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_ProjectArgs = {
  _inc?: InputMaybe<Customer_Project_Inc_Input>;
  _set?: InputMaybe<Customer_Project_Set_Input>;
  where: Customer_Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_Project_By_PkArgs = {
  _inc?: InputMaybe<Customer_Project_Inc_Input>;
  _set?: InputMaybe<Customer_Project_Set_Input>;
  pk_columns: Customer_Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hosted_AtArgs = {
  _inc?: InputMaybe<Hosted_At_Inc_Input>;
  _set?: InputMaybe<Hosted_At_Set_Input>;
  where: Hosted_At_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hosted_At_By_PkArgs = {
  _inc?: InputMaybe<Hosted_At_Inc_Input>;
  _set?: InputMaybe<Hosted_At_Set_Input>;
  pk_columns: Hosted_At_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Occupied_RoomArgs = {
  _inc?: InputMaybe<Occupied_Room_Inc_Input>;
  _set?: InputMaybe<Occupied_Room_Set_Input>;
  where: Occupied_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Occupied_Room_By_PkArgs = {
  _inc?: InputMaybe<Occupied_Room_Inc_Input>;
  _set?: InputMaybe<Occupied_Room_Set_Input>;
  pk_columns: Occupied_Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PermissionsArgs = {
  _inc?: InputMaybe<Permissions_Inc_Input>;
  _set?: InputMaybe<Permissions_Set_Input>;
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Permissions_By_PkArgs = {
  _inc?: InputMaybe<Permissions_Inc_Input>;
  _set?: InputMaybe<Permissions_Set_Input>;
  pk_columns: Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _append?: InputMaybe<Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Prepend_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _append?: InputMaybe<Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Prepend_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReservationArgs = {
  _inc?: InputMaybe<Reservation_Inc_Input>;
  _set?: InputMaybe<Reservation_Set_Input>;
  where: Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_By_PkArgs = {
  _inc?: InputMaybe<Reservation_Inc_Input>;
  _set?: InputMaybe<Reservation_Set_Input>;
  pk_columns: Reservation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reserved_RoomArgs = {
  _inc?: InputMaybe<Reserved_Room_Inc_Input>;
  _set?: InputMaybe<Reserved_Room_Set_Input>;
  where: Reserved_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reserved_Room_By_PkArgs = {
  _inc?: InputMaybe<Reserved_Room_Inc_Input>;
  _set?: InputMaybe<Reserved_Room_Set_Input>;
  pk_columns: Reserved_Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoomArgs = {
  _inc?: InputMaybe<Room_Inc_Input>;
  _set?: InputMaybe<Room_Set_Input>;
  where: Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Room_By_PkArgs = {
  _inc?: InputMaybe<Room_Inc_Input>;
  _set?: InputMaybe<Room_Set_Input>;
  pk_columns: Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Room_TypeArgs = {
  _inc?: InputMaybe<Room_Type_Inc_Input>;
  _set?: InputMaybe<Room_Type_Set_Input>;
  where: Room_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Room_Type_By_PkArgs = {
  _inc?: InputMaybe<Room_Type_Inc_Input>;
  _set?: InputMaybe<Room_Type_Set_Input>;
  pk_columns: Room_Type_Pk_Columns_Input;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "occupied_room" */
export type Occupied_Room = {
  __typename?: 'occupied_room';
  check_in?: Maybe<Scalars['timestamptz']>;
  check_out?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  hosted_ats: Array<Hosted_At>;
  /** An aggregate relationship */
  hosted_ats_aggregate: Hosted_At_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  reservation?: Maybe<Reservation>;
  reservation_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "occupied_room" */
export type Occupied_RoomHosted_AtsArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


/** columns and relationships of "occupied_room" */
export type Occupied_RoomHosted_Ats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};

/** aggregated selection of "occupied_room" */
export type Occupied_Room_Aggregate = {
  __typename?: 'occupied_room_aggregate';
  aggregate?: Maybe<Occupied_Room_Aggregate_Fields>;
  nodes: Array<Occupied_Room>;
};

/** aggregate fields of "occupied_room" */
export type Occupied_Room_Aggregate_Fields = {
  __typename?: 'occupied_room_aggregate_fields';
  avg?: Maybe<Occupied_Room_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Occupied_Room_Max_Fields>;
  min?: Maybe<Occupied_Room_Min_Fields>;
  stddev?: Maybe<Occupied_Room_Stddev_Fields>;
  stddev_pop?: Maybe<Occupied_Room_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Occupied_Room_Stddev_Samp_Fields>;
  sum?: Maybe<Occupied_Room_Sum_Fields>;
  var_pop?: Maybe<Occupied_Room_Var_Pop_Fields>;
  var_samp?: Maybe<Occupied_Room_Var_Samp_Fields>;
  variance?: Maybe<Occupied_Room_Variance_Fields>;
};


/** aggregate fields of "occupied_room" */
export type Occupied_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "occupied_room" */
export type Occupied_Room_Aggregate_Order_By = {
  avg?: InputMaybe<Occupied_Room_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Occupied_Room_Max_Order_By>;
  min?: InputMaybe<Occupied_Room_Min_Order_By>;
  stddev?: InputMaybe<Occupied_Room_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Occupied_Room_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Occupied_Room_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Occupied_Room_Sum_Order_By>;
  var_pop?: InputMaybe<Occupied_Room_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Occupied_Room_Var_Samp_Order_By>;
  variance?: InputMaybe<Occupied_Room_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "occupied_room" */
export type Occupied_Room_Arr_Rel_Insert_Input = {
  data: Array<Occupied_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Occupied_Room_On_Conflict>;
};

/** aggregate avg on columns */
export type Occupied_Room_Avg_Fields = {
  __typename?: 'occupied_room_avg_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "occupied_room" */
export type Occupied_Room_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "occupied_room". All fields are combined with a logical 'AND'. */
export type Occupied_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Occupied_Room_Bool_Exp>>;
  _not?: InputMaybe<Occupied_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Occupied_Room_Bool_Exp>>;
  check_in?: InputMaybe<Timestamptz_Comparison_Exp>;
  check_out?: InputMaybe<Timestamptz_Comparison_Exp>;
  hosted_ats?: InputMaybe<Hosted_At_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  reservation?: InputMaybe<Reservation_Bool_Exp>;
  reservation_id?: InputMaybe<Int_Comparison_Exp>;
  room?: InputMaybe<Room_Bool_Exp>;
  room_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "occupied_room" */
export enum Occupied_Room_Constraint {
  /** unique or primary key constraint */
  OccupiedRoomPkey = 'occupied_room_pkey'
}

/** input type for incrementing numeric columns in table "occupied_room" */
export type Occupied_Room_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  reservation_id?: InputMaybe<Scalars['Int']>;
  room_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "occupied_room" */
export type Occupied_Room_Insert_Input = {
  check_in?: InputMaybe<Scalars['timestamptz']>;
  check_out?: InputMaybe<Scalars['timestamptz']>;
  hosted_ats?: InputMaybe<Hosted_At_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  reservation?: InputMaybe<Reservation_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['Int']>;
  room?: InputMaybe<Room_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Occupied_Room_Max_Fields = {
  __typename?: 'occupied_room_max_fields';
  check_in?: Maybe<Scalars['timestamptz']>;
  check_out?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['Int']>;
  room_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "occupied_room" */
export type Occupied_Room_Max_Order_By = {
  check_in?: InputMaybe<Order_By>;
  check_out?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Occupied_Room_Min_Fields = {
  __typename?: 'occupied_room_min_fields';
  check_in?: Maybe<Scalars['timestamptz']>;
  check_out?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['Int']>;
  room_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "occupied_room" */
export type Occupied_Room_Min_Order_By = {
  check_in?: InputMaybe<Order_By>;
  check_out?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "occupied_room" */
export type Occupied_Room_Mutation_Response = {
  __typename?: 'occupied_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Occupied_Room>;
};

/** input type for inserting object relation for remote table "occupied_room" */
export type Occupied_Room_Obj_Rel_Insert_Input = {
  data: Occupied_Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Occupied_Room_On_Conflict>;
};

/** on_conflict condition type for table "occupied_room" */
export type Occupied_Room_On_Conflict = {
  constraint: Occupied_Room_Constraint;
  update_columns?: Array<Occupied_Room_Update_Column>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "occupied_room". */
export type Occupied_Room_Order_By = {
  check_in?: InputMaybe<Order_By>;
  check_out?: InputMaybe<Order_By>;
  hosted_ats_aggregate?: InputMaybe<Hosted_At_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservation_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room?: InputMaybe<Room_Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: occupied_room */
export type Occupied_Room_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "occupied_room" */
export enum Occupied_Room_Select_Column {
  /** column name */
  CheckIn = 'check_in',
  /** column name */
  CheckOut = 'check_out',
  /** column name */
  Id = 'id',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  RoomId = 'room_id'
}

/** input type for updating data in table "occupied_room" */
export type Occupied_Room_Set_Input = {
  check_in?: InputMaybe<Scalars['timestamptz']>;
  check_out?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  reservation_id?: InputMaybe<Scalars['Int']>;
  room_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Occupied_Room_Stddev_Fields = {
  __typename?: 'occupied_room_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "occupied_room" */
export type Occupied_Room_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Occupied_Room_Stddev_Pop_Fields = {
  __typename?: 'occupied_room_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "occupied_room" */
export type Occupied_Room_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Occupied_Room_Stddev_Samp_Fields = {
  __typename?: 'occupied_room_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "occupied_room" */
export type Occupied_Room_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Occupied_Room_Sum_Fields = {
  __typename?: 'occupied_room_sum_fields';
  id?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['Int']>;
  room_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "occupied_room" */
export type Occupied_Room_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** update columns of table "occupied_room" */
export enum Occupied_Room_Update_Column {
  /** column name */
  CheckIn = 'check_in',
  /** column name */
  CheckOut = 'check_out',
  /** column name */
  Id = 'id',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  RoomId = 'room_id'
}

/** aggregate var_pop on columns */
export type Occupied_Room_Var_Pop_Fields = {
  __typename?: 'occupied_room_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "occupied_room" */
export type Occupied_Room_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Occupied_Room_Var_Samp_Fields = {
  __typename?: 'occupied_room_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "occupied_room" */
export type Occupied_Room_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Occupied_Room_Variance_Fields = {
  __typename?: 'occupied_room_variance_fields';
  id?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "occupied_room" */
export type Occupied_Room_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "permissions" */
export type Permissions = {
  __typename?: 'permissions';
  id: Scalars['Int'];
  project_id?: Maybe<Scalars['uuid']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "permissions" */
export type Permissions_Aggregate = {
  __typename?: 'permissions_aggregate';
  aggregate?: Maybe<Permissions_Aggregate_Fields>;
  nodes: Array<Permissions>;
};

/** aggregate fields of "permissions" */
export type Permissions_Aggregate_Fields = {
  __typename?: 'permissions_aggregate_fields';
  avg?: Maybe<Permissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Permissions_Max_Fields>;
  min?: Maybe<Permissions_Min_Fields>;
  stddev?: Maybe<Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Permissions_Sum_Fields>;
  var_pop?: Maybe<Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Permissions_Var_Samp_Fields>;
  variance?: Maybe<Permissions_Variance_Fields>;
};


/** aggregate fields of "permissions" */
export type Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "permissions" */
export type Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Permissions_Max_Order_By>;
  min?: InputMaybe<Permissions_Min_Order_By>;
  stddev?: InputMaybe<Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "permissions" */
export type Permissions_Arr_Rel_Insert_Input = {
  data: Array<Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Permissions_Avg_Fields = {
  __typename?: 'permissions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "permissions" */
export type Permissions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "permissions". All fields are combined with a logical 'AND'. */
export type Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Bool_Exp>>;
  _not?: InputMaybe<Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions" */
export enum Permissions_Constraint {
  /** unique or primary key constraint */
  PermissionsPkey = 'permissions_pkey'
}

/** input type for incrementing numeric columns in table "permissions" */
export type Permissions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "permissions" */
export type Permissions_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Permissions_Max_Fields = {
  __typename?: 'permissions_max_fields';
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "permissions" */
export type Permissions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Permissions_Min_Fields = {
  __typename?: 'permissions_min_fields';
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "permissions" */
export type Permissions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "permissions" */
export type Permissions_Mutation_Response = {
  __typename?: 'permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Permissions>;
};

/** on_conflict condition type for table "permissions" */
export type Permissions_On_Conflict = {
  constraint: Permissions_Constraint;
  update_columns?: Array<Permissions_Update_Column>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions". */
export type Permissions_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: permissions */
export type Permissions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "permissions" */
export enum Permissions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "permissions" */
export type Permissions_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Permissions_Stddev_Fields = {
  __typename?: 'permissions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "permissions" */
export type Permissions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Permissions_Stddev_Pop_Fields = {
  __typename?: 'permissions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "permissions" */
export type Permissions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Permissions_Stddev_Samp_Fields = {
  __typename?: 'permissions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "permissions" */
export type Permissions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Permissions_Sum_Fields = {
  __typename?: 'permissions_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "permissions" */
export type Permissions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "permissions" */
export enum Permissions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Permissions_Var_Pop_Fields = {
  __typename?: 'permissions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "permissions" */
export type Permissions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Permissions_Var_Samp_Fields = {
  __typename?: 'permissions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "permissions" */
export type Permissions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Permissions_Variance_Fields = {
  __typename?: 'permissions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "permissions" */
export type Permissions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  /** An array relationship */
  Permissions: Array<Permissions>;
  /** An aggregate relationship */
  Permissions_aggregate: Permissions_Aggregate;
  cloudbeds_token?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  customer_notes: Array<Customer_Notes>;
  /** An aggregate relationship */
  customer_notes_aggregate: Customer_Notes_Aggregate;
  /** An array relationship */
  customer_projects: Array<Customer_Project>;
  /** An aggregate relationship */
  customer_projects_aggregate: Customer_Project_Aggregate;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservations_aggregate: Reservation_Aggregate;
  /** An array relationship */
  room_types: Array<Room_Type>;
  /** An aggregate relationship */
  room_types_aggregate: Room_Type_Aggregate;
  /** An array relationship */
  rooms: Array<Room>;
  /** An aggregate relationship */
  rooms_aggregate: Room_Aggregate;
};


/** columns and relationships of "projects" */
export type ProjectsPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCloudbeds_TokenArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "projects" */
export type ProjectsCustomer_NotesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCustomer_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCustomer_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCustomer_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRoom_TypesArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRoom_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRoomsArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Append_Input = {
  cloudbeds_token?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  Permissions?: InputMaybe<Permissions_Bool_Exp>;
  _and?: InputMaybe<Array<Projects_Bool_Exp>>;
  _not?: InputMaybe<Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Bool_Exp>>;
  cloudbeds_token?: InputMaybe<Jsonb_Comparison_Exp>;
  customer_notes?: InputMaybe<Customer_Notes_Bool_Exp>;
  customer_projects?: InputMaybe<Customer_Project_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  reservations?: InputMaybe<Reservation_Bool_Exp>;
  room_types?: InputMaybe<Room_Type_Bool_Exp>;
  rooms?: InputMaybe<Room_Bool_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint */
  ProjectsPkey = 'projects_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Delete_At_Path_Input = {
  cloudbeds_token?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Delete_Elem_Input = {
  cloudbeds_token?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Delete_Key_Input = {
  cloudbeds_token?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  Permissions?: InputMaybe<Permissions_Arr_Rel_Insert_Input>;
  cloudbeds_token?: InputMaybe<Scalars['jsonb']>;
  customer_notes?: InputMaybe<Customer_Notes_Arr_Rel_Insert_Input>;
  customer_projects?: InputMaybe<Customer_Project_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  reservations?: InputMaybe<Reservation_Arr_Rel_Insert_Input>;
  room_types?: InputMaybe<Room_Type_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Room_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  Permissions_aggregate?: InputMaybe<Permissions_Aggregate_Order_By>;
  cloudbeds_token?: InputMaybe<Order_By>;
  customer_notes_aggregate?: InputMaybe<Customer_Notes_Aggregate_Order_By>;
  customer_projects_aggregate?: InputMaybe<Customer_Project_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reservations_aggregate?: InputMaybe<Reservation_Aggregate_Order_By>;
  room_types_aggregate?: InputMaybe<Room_Type_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Prepend_Input = {
  cloudbeds_token?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  CloudbedsToken = 'cloudbeds_token',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  cloudbeds_token?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  CloudbedsToken = 'cloudbeds_token',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "app_settings" */
  app_settings: Array<App_Settings>;
  /** fetch aggregated fields from the table: "app_settings" */
  app_settings_aggregate: App_Settings_Aggregate;
  /** fetch data from the table: "app_settings" using primary key columns */
  app_settings_by_pk?: Maybe<App_Settings>;
  cloudbeds_get_reservations?: Maybe<CloudbedsGetReservationsOutput>;
  cloudbeds_guest_getGuest?: Maybe<GetGuestOutput>;
  cloudbeds_reservation_getReservationInvoiceInformation?: Maybe<GetReservationInvoiceInformationOutput>;
  cloudbeds_reservation_getReservationsWithRateDetails?: Maybe<GetReservationsWithRateDetailsOutput>;
  /** fetch data from the table: "customer" */
  customer: Array<Customer>;
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>;
  /** An array relationship */
  customer_notes: Array<Customer_Notes>;
  /** An aggregate relationship */
  customer_notes_aggregate: Customer_Notes_Aggregate;
  /** fetch data from the table: "customer_notes" using primary key columns */
  customer_notes_by_pk?: Maybe<Customer_Notes>;
  /** fetch data from the table: "customer_project" */
  customer_project: Array<Customer_Project>;
  /** fetch aggregated fields from the table: "customer_project" */
  customer_project_aggregate: Customer_Project_Aggregate;
  /** fetch data from the table: "customer_project" using primary key columns */
  customer_project_by_pk?: Maybe<Customer_Project>;
  getAccessToken?: Maybe<AccessTokenOutput>;
  getHotelDetails?: Maybe<GetHotelDetailsOutput>;
  getHotels?: Maybe<GetHotelsOutput>;
  /** fetch data from the table: "hosted_at" */
  hosted_at: Array<Hosted_At>;
  /** fetch aggregated fields from the table: "hosted_at" */
  hosted_at_aggregate: Hosted_At_Aggregate;
  /** fetch data from the table: "hosted_at" using primary key columns */
  hosted_at_by_pk?: Maybe<Hosted_At>;
  /** fetch data from the table: "occupied_room" */
  occupied_room: Array<Occupied_Room>;
  /** fetch aggregated fields from the table: "occupied_room" */
  occupied_room_aggregate: Occupied_Room_Aggregate;
  /** fetch data from the table: "occupied_room" using primary key columns */
  occupied_room_by_pk?: Maybe<Occupied_Room>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** fetch data from the table: "reserved_room" */
  reserved_room: Array<Reserved_Room>;
  /** fetch aggregated fields from the table: "reserved_room" */
  reserved_room_aggregate: Reserved_Room_Aggregate;
  /** fetch data from the table: "reserved_room" using primary key columns */
  reserved_room_by_pk?: Maybe<Reserved_Room>;
  /** fetch data from the table: "room" */
  room: Array<Room>;
  /** fetch aggregated fields from the table: "room" */
  room_aggregate: Room_Aggregate;
  /** fetch data from the table: "room" using primary key columns */
  room_by_pk?: Maybe<Room>;
  /** fetch data from the table: "room_type" */
  room_type: Array<Room_Type>;
  /** fetch aggregated fields from the table: "room_type" */
  room_type_aggregate: Room_Type_Aggregate;
  /** fetch data from the table: "room_type" using primary key columns */
  room_type_by_pk?: Maybe<Room_Type>;
};


export type Query_RootApp_SettingsArgs = {
  distinct_on?: InputMaybe<Array<App_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Settings_Order_By>>;
  where?: InputMaybe<App_Settings_Bool_Exp>;
};


export type Query_RootApp_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Settings_Order_By>>;
  where?: InputMaybe<App_Settings_Bool_Exp>;
};


export type Query_RootApp_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCloudbeds_Get_ReservationsArgs = {
  arg1: CloudbedsGetReservationsInput;
};


export type Query_RootCloudbeds_Guest_GetGuestArgs = {
  arg1: GetGuestInput;
};


export type Query_RootCloudbeds_Reservation_GetReservationInvoiceInformationArgs = {
  arg1: GetReservationInvoiceInformationInput;
};


export type Query_RootCloudbeds_Reservation_GetReservationsWithRateDetailsArgs = {
  arg1: GetReservationsWithRateDetailsInput;
};


export type Query_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomer_NotesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


export type Query_RootCustomer_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


export type Query_RootCustomer_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCustomer_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


export type Query_RootCustomer_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


export type Query_RootCustomer_Project_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetHotelDetailsArgs = {
  arg1: GetHotelDetailsInput;
};


export type Query_RootGetHotelsArgs = {
  arg1: GetHotelsInput;
};


export type Query_RootHosted_AtArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


export type Query_RootHosted_At_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


export type Query_RootHosted_At_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOccupied_RoomArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


export type Query_RootOccupied_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


export type Query_RootOccupied_Room_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Query_RootPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Query_RootPermissions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReservationArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Query_RootReservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Query_RootReservation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootReserved_RoomArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


export type Query_RootReserved_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


export type Query_RootReserved_Room_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRoomArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Query_RootRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Query_RootRoom_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRoom_TypeArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Query_RootRoom_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Query_RootRoom_Type_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "reservation" */
export type Reservation = {
  __typename?: 'reservation';
  /** An object relationship */
  customer: Customer;
  date_in: Scalars['date'];
  date_out: Scalars['date'];
  guest_id: Scalars['uuid'];
  id: Scalars['Int'];
  made_by?: Maybe<Scalars['String']>;
  /** An array relationship */
  occupied_rooms: Array<Occupied_Room>;
  /** An aggregate relationship */
  occupied_rooms_aggregate: Occupied_Room_Aggregate;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  reserved_rooms: Array<Reserved_Room>;
  /** An aggregate relationship */
  reserved_rooms_aggregate: Reserved_Room_Aggregate;
};


/** columns and relationships of "reservation" */
export type ReservationOccupied_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


/** columns and relationships of "reservation" */
export type ReservationOccupied_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


/** columns and relationships of "reservation" */
export type ReservationReserved_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


/** columns and relationships of "reservation" */
export type ReservationReserved_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};

/** aggregated selection of "reservation" */
export type Reservation_Aggregate = {
  __typename?: 'reservation_aggregate';
  aggregate?: Maybe<Reservation_Aggregate_Fields>;
  nodes: Array<Reservation>;
};

/** aggregate fields of "reservation" */
export type Reservation_Aggregate_Fields = {
  __typename?: 'reservation_aggregate_fields';
  avg?: Maybe<Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reservation_Max_Fields>;
  min?: Maybe<Reservation_Min_Fields>;
  stddev?: Maybe<Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Reservation_Sum_Fields>;
  var_pop?: Maybe<Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Reservation_Var_Samp_Fields>;
  variance?: Maybe<Reservation_Variance_Fields>;
};


/** aggregate fields of "reservation" */
export type Reservation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservation" */
export type Reservation_Aggregate_Order_By = {
  avg?: InputMaybe<Reservation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reservation_Max_Order_By>;
  min?: InputMaybe<Reservation_Min_Order_By>;
  stddev?: InputMaybe<Reservation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reservation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reservation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reservation_Sum_Order_By>;
  var_pop?: InputMaybe<Reservation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reservation_Var_Samp_Order_By>;
  variance?: InputMaybe<Reservation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservation" */
export type Reservation_Arr_Rel_Insert_Input = {
  data: Array<Reservation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservation_Avg_Fields = {
  __typename?: 'reservation_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reservation" */
export type Reservation_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservation". All fields are combined with a logical 'AND'. */
export type Reservation_Bool_Exp = {
  _and?: InputMaybe<Array<Reservation_Bool_Exp>>;
  _not?: InputMaybe<Reservation_Bool_Exp>;
  _or?: InputMaybe<Array<Reservation_Bool_Exp>>;
  customer?: InputMaybe<Customer_Bool_Exp>;
  date_in?: InputMaybe<Date_Comparison_Exp>;
  date_out?: InputMaybe<Date_Comparison_Exp>;
  guest_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  made_by?: InputMaybe<String_Comparison_Exp>;
  occupied_rooms?: InputMaybe<Occupied_Room_Bool_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  reserved_rooms?: InputMaybe<Reserved_Room_Bool_Exp>;
};

/** unique or primary key constraints on table "reservation" */
export enum Reservation_Constraint {
  /** unique or primary key constraint */
  ReservationPkey = 'reservation_pkey'
}

/** input type for incrementing numeric columns in table "reservation" */
export type Reservation_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reservation" */
export type Reservation_Insert_Input = {
  customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  date_in?: InputMaybe<Scalars['date']>;
  date_out?: InputMaybe<Scalars['date']>;
  guest_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  made_by?: InputMaybe<Scalars['String']>;
  occupied_rooms?: InputMaybe<Occupied_Room_Arr_Rel_Insert_Input>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  reserved_rooms?: InputMaybe<Reserved_Room_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Reservation_Max_Fields = {
  __typename?: 'reservation_max_fields';
  date_in?: Maybe<Scalars['date']>;
  date_out?: Maybe<Scalars['date']>;
  guest_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  made_by?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reservation" */
export type Reservation_Max_Order_By = {
  date_in?: InputMaybe<Order_By>;
  date_out?: InputMaybe<Order_By>;
  guest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  made_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reservation_Min_Fields = {
  __typename?: 'reservation_min_fields';
  date_in?: Maybe<Scalars['date']>;
  date_out?: Maybe<Scalars['date']>;
  guest_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  made_by?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reservation" */
export type Reservation_Min_Order_By = {
  date_in?: InputMaybe<Order_By>;
  date_out?: InputMaybe<Order_By>;
  guest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  made_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reservation" */
export type Reservation_Mutation_Response = {
  __typename?: 'reservation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation>;
};

/** input type for inserting object relation for remote table "reservation" */
export type Reservation_Obj_Rel_Insert_Input = {
  data: Reservation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};

/** on_conflict condition type for table "reservation" */
export type Reservation_On_Conflict = {
  constraint: Reservation_Constraint;
  update_columns?: Array<Reservation_Update_Column>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};

/** Ordering options when selecting data from "reservation". */
export type Reservation_Order_By = {
  customer?: InputMaybe<Customer_Order_By>;
  date_in?: InputMaybe<Order_By>;
  date_out?: InputMaybe<Order_By>;
  guest_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  made_by?: InputMaybe<Order_By>;
  occupied_rooms_aggregate?: InputMaybe<Occupied_Room_Aggregate_Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  reserved_rooms_aggregate?: InputMaybe<Reserved_Room_Aggregate_Order_By>;
};

/** primary key columns input for table: reservation */
export type Reservation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "reservation" */
export enum Reservation_Select_Column {
  /** column name */
  DateIn = 'date_in',
  /** column name */
  DateOut = 'date_out',
  /** column name */
  GuestId = 'guest_id',
  /** column name */
  Id = 'id',
  /** column name */
  MadeBy = 'made_by',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "reservation" */
export type Reservation_Set_Input = {
  date_in?: InputMaybe<Scalars['date']>;
  date_out?: InputMaybe<Scalars['date']>;
  guest_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  made_by?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Reservation_Stddev_Fields = {
  __typename?: 'reservation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reservation" */
export type Reservation_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservation_Stddev_Pop_Fields = {
  __typename?: 'reservation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reservation" */
export type Reservation_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservation_Stddev_Samp_Fields = {
  __typename?: 'reservation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reservation" */
export type Reservation_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Reservation_Sum_Fields = {
  __typename?: 'reservation_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reservation" */
export type Reservation_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "reservation" */
export enum Reservation_Update_Column {
  /** column name */
  DateIn = 'date_in',
  /** column name */
  DateOut = 'date_out',
  /** column name */
  GuestId = 'guest_id',
  /** column name */
  Id = 'id',
  /** column name */
  MadeBy = 'made_by',
  /** column name */
  ProjectId = 'project_id'
}

/** aggregate var_pop on columns */
export type Reservation_Var_Pop_Fields = {
  __typename?: 'reservation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reservation" */
export type Reservation_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservation_Var_Samp_Fields = {
  __typename?: 'reservation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reservation" */
export type Reservation_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservation_Variance_Fields = {
  __typename?: 'reservation_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reservation" */
export type Reservation_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reserved_room" */
export type Reserved_Room = {
  __typename?: 'reserved_room';
  id: Scalars['Int'];
  number_of_rooms?: Maybe<Scalars['Int']>;
  /** An object relationship */
  reservation?: Maybe<Reservation>;
  reservation_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  room_type?: Maybe<Room_Type>;
  room_type_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reserved_room" */
export type Reserved_Room_Aggregate = {
  __typename?: 'reserved_room_aggregate';
  aggregate?: Maybe<Reserved_Room_Aggregate_Fields>;
  nodes: Array<Reserved_Room>;
};

/** aggregate fields of "reserved_room" */
export type Reserved_Room_Aggregate_Fields = {
  __typename?: 'reserved_room_aggregate_fields';
  avg?: Maybe<Reserved_Room_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reserved_Room_Max_Fields>;
  min?: Maybe<Reserved_Room_Min_Fields>;
  stddev?: Maybe<Reserved_Room_Stddev_Fields>;
  stddev_pop?: Maybe<Reserved_Room_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reserved_Room_Stddev_Samp_Fields>;
  sum?: Maybe<Reserved_Room_Sum_Fields>;
  var_pop?: Maybe<Reserved_Room_Var_Pop_Fields>;
  var_samp?: Maybe<Reserved_Room_Var_Samp_Fields>;
  variance?: Maybe<Reserved_Room_Variance_Fields>;
};


/** aggregate fields of "reserved_room" */
export type Reserved_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reserved_room" */
export type Reserved_Room_Aggregate_Order_By = {
  avg?: InputMaybe<Reserved_Room_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reserved_Room_Max_Order_By>;
  min?: InputMaybe<Reserved_Room_Min_Order_By>;
  stddev?: InputMaybe<Reserved_Room_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reserved_Room_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reserved_Room_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reserved_Room_Sum_Order_By>;
  var_pop?: InputMaybe<Reserved_Room_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reserved_Room_Var_Samp_Order_By>;
  variance?: InputMaybe<Reserved_Room_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reserved_room" */
export type Reserved_Room_Arr_Rel_Insert_Input = {
  data: Array<Reserved_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reserved_Room_On_Conflict>;
};

/** aggregate avg on columns */
export type Reserved_Room_Avg_Fields = {
  __typename?: 'reserved_room_avg_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reserved_room" */
export type Reserved_Room_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reserved_room". All fields are combined with a logical 'AND'. */
export type Reserved_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Reserved_Room_Bool_Exp>>;
  _not?: InputMaybe<Reserved_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Reserved_Room_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  number_of_rooms?: InputMaybe<Int_Comparison_Exp>;
  reservation?: InputMaybe<Reservation_Bool_Exp>;
  reservation_id?: InputMaybe<Int_Comparison_Exp>;
  room_type?: InputMaybe<Room_Type_Bool_Exp>;
  room_type_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reserved_room" */
export enum Reserved_Room_Constraint {
  /** unique or primary key constraint */
  ReservedRoomPkey = 'reserved_room_pkey'
}

/** input type for incrementing numeric columns in table "reserved_room" */
export type Reserved_Room_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number_of_rooms?: InputMaybe<Scalars['Int']>;
  reservation_id?: InputMaybe<Scalars['Int']>;
  room_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reserved_room" */
export type Reserved_Room_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number_of_rooms?: InputMaybe<Scalars['Int']>;
  reservation?: InputMaybe<Reservation_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['Int']>;
  room_type?: InputMaybe<Room_Type_Obj_Rel_Insert_Input>;
  room_type_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reserved_Room_Max_Fields = {
  __typename?: 'reserved_room_max_fields';
  id?: Maybe<Scalars['Int']>;
  number_of_rooms?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['Int']>;
  room_type_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reserved_room" */
export type Reserved_Room_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reserved_Room_Min_Fields = {
  __typename?: 'reserved_room_min_fields';
  id?: Maybe<Scalars['Int']>;
  number_of_rooms?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['Int']>;
  room_type_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reserved_room" */
export type Reserved_Room_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reserved_room" */
export type Reserved_Room_Mutation_Response = {
  __typename?: 'reserved_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reserved_Room>;
};

/** on_conflict condition type for table "reserved_room" */
export type Reserved_Room_On_Conflict = {
  constraint: Reserved_Room_Constraint;
  update_columns?: Array<Reserved_Room_Update_Column>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "reserved_room". */
export type Reserved_Room_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservation_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type?: InputMaybe<Room_Type_Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reserved_room */
export type Reserved_Room_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "reserved_room" */
export enum Reserved_Room_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfRooms = 'number_of_rooms',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  RoomTypeId = 'room_type_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "reserved_room" */
export type Reserved_Room_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number_of_rooms?: InputMaybe<Scalars['Int']>;
  reservation_id?: InputMaybe<Scalars['Int']>;
  room_type_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Reserved_Room_Stddev_Fields = {
  __typename?: 'reserved_room_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reserved_room" */
export type Reserved_Room_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reserved_Room_Stddev_Pop_Fields = {
  __typename?: 'reserved_room_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reserved_room" */
export type Reserved_Room_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reserved_Room_Stddev_Samp_Fields = {
  __typename?: 'reserved_room_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reserved_room" */
export type Reserved_Room_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Reserved_Room_Sum_Fields = {
  __typename?: 'reserved_room_sum_fields';
  id?: Maybe<Scalars['Int']>;
  number_of_rooms?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['Int']>;
  room_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reserved_room" */
export type Reserved_Room_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "reserved_room" */
export enum Reserved_Room_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfRooms = 'number_of_rooms',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  RoomTypeId = 'room_type_id',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Reserved_Room_Var_Pop_Fields = {
  __typename?: 'reserved_room_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reserved_room" */
export type Reserved_Room_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reserved_Room_Var_Samp_Fields = {
  __typename?: 'reserved_room_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reserved_room" */
export type Reserved_Room_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reserved_Room_Variance_Fields = {
  __typename?: 'reserved_room_variance_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_rooms?: Maybe<Scalars['Float']>;
  reservation_id?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reserved_room" */
export type Reserved_Room_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_rooms?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "room" */
export type Room = {
  __typename?: 'room';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  occupied_rooms: Array<Occupied_Room>;
  /** An aggregate relationship */
  occupied_rooms_aggregate: Occupied_Room_Aggregate;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  rating: Scalars['numeric'];
  room_number: Scalars['String'];
  /** An object relationship */
  room_type?: Maybe<Room_Type>;
  room_type_id?: Maybe<Scalars['Int']>;
  smoke?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};


/** columns and relationships of "room" */
export type RoomOccupied_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


/** columns and relationships of "room" */
export type RoomOccupied_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};

/** aggregated selection of "room" */
export type Room_Aggregate = {
  __typename?: 'room_aggregate';
  aggregate?: Maybe<Room_Aggregate_Fields>;
  nodes: Array<Room>;
};

/** aggregate fields of "room" */
export type Room_Aggregate_Fields = {
  __typename?: 'room_aggregate_fields';
  avg?: Maybe<Room_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Max_Fields>;
  min?: Maybe<Room_Min_Fields>;
  stddev?: Maybe<Room_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Sum_Fields>;
  var_pop?: Maybe<Room_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Var_Samp_Fields>;
  variance?: Maybe<Room_Variance_Fields>;
};


/** aggregate fields of "room" */
export type Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room" */
export type Room_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Max_Order_By>;
  min?: InputMaybe<Room_Min_Order_By>;
  stddev?: InputMaybe<Room_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room" */
export type Room_Arr_Rel_Insert_Input = {
  data: Array<Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Avg_Fields = {
  __typename?: 'room_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room" */
export type Room_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room". All fields are combined with a logical 'AND'. */
export type Room_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Bool_Exp>>;
  _not?: InputMaybe<Room_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  occupied_rooms?: InputMaybe<Occupied_Room_Bool_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  room_number?: InputMaybe<String_Comparison_Exp>;
  room_type?: InputMaybe<Room_Type_Bool_Exp>;
  room_type_id?: InputMaybe<Int_Comparison_Exp>;
  smoke?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "room" */
export enum Room_Constraint {
  /** unique or primary key constraint */
  RoomPkey = 'room_pkey'
}

/** input type for incrementing numeric columns in table "room" */
export type Room_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['numeric']>;
  room_type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "room" */
export type Room_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  occupied_rooms?: InputMaybe<Occupied_Room_Arr_Rel_Insert_Input>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['numeric']>;
  room_number?: InputMaybe<Scalars['String']>;
  room_type?: InputMaybe<Room_Type_Obj_Rel_Insert_Input>;
  room_type_id?: InputMaybe<Scalars['Int']>;
  smoke?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Room_Max_Fields = {
  __typename?: 'room_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  room_number?: Maybe<Scalars['String']>;
  room_type_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "room" */
export type Room_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_number?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Min_Fields = {
  __typename?: 'room_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  room_number?: Maybe<Scalars['String']>;
  room_type_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "room" */
export type Room_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_number?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room" */
export type Room_Mutation_Response = {
  __typename?: 'room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room>;
};

/** input type for inserting object relation for remote table "room" */
export type Room_Obj_Rel_Insert_Input = {
  data: Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_On_Conflict>;
};

/** on_conflict condition type for table "room" */
export type Room_On_Conflict = {
  constraint: Room_Constraint;
  update_columns?: Array<Room_Update_Column>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** Ordering options when selecting data from "room". */
export type Room_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  occupied_rooms_aggregate?: InputMaybe<Occupied_Room_Aggregate_Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_number?: InputMaybe<Order_By>;
  room_type?: InputMaybe<Room_Type_Order_By>;
  room_type_id?: InputMaybe<Order_By>;
  smoke?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: room */
export type Room_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "room" */
export enum Room_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RoomNumber = 'room_number',
  /** column name */
  RoomTypeId = 'room_type_id',
  /** column name */
  Smoke = 'smoke',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "room" */
export type Room_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['numeric']>;
  room_number?: InputMaybe<Scalars['String']>;
  room_type_id?: InputMaybe<Scalars['Int']>;
  smoke?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Room_Stddev_Fields = {
  __typename?: 'room_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room" */
export type Room_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Stddev_Pop_Fields = {
  __typename?: 'room_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room" */
export type Room_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Stddev_Samp_Fields = {
  __typename?: 'room_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room" */
export type Room_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Room_Sum_Fields = {
  __typename?: 'room_sum_fields';
  id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['numeric']>;
  room_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "room" */
export type Room_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "room_type" */
export type Room_Type = {
  __typename?: 'room_type';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  max_capacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  reserved_rooms: Array<Reserved_Room>;
  /** An aggregate relationship */
  reserved_rooms_aggregate: Reserved_Room_Aggregate;
  /** An array relationship */
  rooms: Array<Room>;
  /** An aggregate relationship */
  rooms_aggregate: Room_Aggregate;
};


/** columns and relationships of "room_type" */
export type Room_TypeReserved_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


/** columns and relationships of "room_type" */
export type Room_TypeReserved_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


/** columns and relationships of "room_type" */
export type Room_TypeRoomsArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


/** columns and relationships of "room_type" */
export type Room_TypeRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};

/** aggregated selection of "room_type" */
export type Room_Type_Aggregate = {
  __typename?: 'room_type_aggregate';
  aggregate?: Maybe<Room_Type_Aggregate_Fields>;
  nodes: Array<Room_Type>;
};

/** aggregate fields of "room_type" */
export type Room_Type_Aggregate_Fields = {
  __typename?: 'room_type_aggregate_fields';
  avg?: Maybe<Room_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Room_Type_Max_Fields>;
  min?: Maybe<Room_Type_Min_Fields>;
  stddev?: Maybe<Room_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Room_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Room_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Room_Type_Sum_Fields>;
  var_pop?: Maybe<Room_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Room_Type_Var_Samp_Fields>;
  variance?: Maybe<Room_Type_Variance_Fields>;
};


/** aggregate fields of "room_type" */
export type Room_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Room_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "room_type" */
export type Room_Type_Aggregate_Order_By = {
  avg?: InputMaybe<Room_Type_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Room_Type_Max_Order_By>;
  min?: InputMaybe<Room_Type_Min_Order_By>;
  stddev?: InputMaybe<Room_Type_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Room_Type_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Room_Type_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Room_Type_Sum_Order_By>;
  var_pop?: InputMaybe<Room_Type_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Room_Type_Var_Samp_Order_By>;
  variance?: InputMaybe<Room_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "room_type" */
export type Room_Type_Arr_Rel_Insert_Input = {
  data: Array<Room_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Room_Type_Avg_Fields = {
  __typename?: 'room_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "room_type" */
export type Room_Type_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "room_type". All fields are combined with a logical 'AND'. */
export type Room_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Room_Type_Bool_Exp>>;
  _not?: InputMaybe<Room_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Room_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max_capacity?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  reserved_rooms?: InputMaybe<Reserved_Room_Bool_Exp>;
  rooms?: InputMaybe<Room_Bool_Exp>;
};

/** unique or primary key constraints on table "room_type" */
export enum Room_Type_Constraint {
  /** unique or primary key constraint */
  RoomTypePkey = 'room_type_pkey'
}

/** input type for incrementing numeric columns in table "room_type" */
export type Room_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  max_capacity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "room_type" */
export type Room_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_capacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  reserved_rooms?: InputMaybe<Reserved_Room_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Room_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Room_Type_Max_Fields = {
  __typename?: 'room_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_capacity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "room_type" */
export type Room_Type_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Room_Type_Min_Fields = {
  __typename?: 'room_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_capacity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "room_type" */
export type Room_Type_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "room_type" */
export type Room_Type_Mutation_Response = {
  __typename?: 'room_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Room_Type>;
};

/** input type for inserting object relation for remote table "room_type" */
export type Room_Type_Obj_Rel_Insert_Input = {
  data: Room_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Room_Type_On_Conflict>;
};

/** on_conflict condition type for table "room_type" */
export type Room_Type_On_Conflict = {
  constraint: Room_Type_Constraint;
  update_columns?: Array<Room_Type_Update_Column>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "room_type". */
export type Room_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  reserved_rooms_aggregate?: InputMaybe<Reserved_Room_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Room_Aggregate_Order_By>;
};

/** primary key columns input for table: room_type */
export type Room_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "room_type" */
export enum Room_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxCapacity = 'max_capacity',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "room_type" */
export type Room_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_capacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Room_Type_Stddev_Fields = {
  __typename?: 'room_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "room_type" */
export type Room_Type_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Room_Type_Stddev_Pop_Fields = {
  __typename?: 'room_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "room_type" */
export type Room_Type_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Room_Type_Stddev_Samp_Fields = {
  __typename?: 'room_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "room_type" */
export type Room_Type_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Room_Type_Sum_Fields = {
  __typename?: 'room_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
  max_capacity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "room_type" */
export type Room_Type_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** update columns of table "room_type" */
export enum Room_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxCapacity = 'max_capacity',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id'
}

/** aggregate var_pop on columns */
export type Room_Type_Var_Pop_Fields = {
  __typename?: 'room_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room_type" */
export type Room_Type_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Type_Var_Samp_Fields = {
  __typename?: 'room_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room_type" */
export type Room_Type_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Type_Variance_Fields = {
  __typename?: 'room_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max_capacity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room_type" */
export type Room_Type_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  max_capacity?: InputMaybe<Order_By>;
};

/** update columns of table "room" */
export enum Room_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RoomNumber = 'room_number',
  /** column name */
  RoomTypeId = 'room_type_id',
  /** column name */
  Smoke = 'smoke',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Room_Var_Pop_Fields = {
  __typename?: 'room_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "room" */
export type Room_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Room_Var_Samp_Fields = {
  __typename?: 'room_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "room" */
export type Room_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Room_Variance_Fields = {
  __typename?: 'room_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  room_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "room" */
export type Room_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  room_type_id?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "app_settings" */
  app_settings: Array<App_Settings>;
  /** fetch aggregated fields from the table: "app_settings" */
  app_settings_aggregate: App_Settings_Aggregate;
  /** fetch data from the table: "app_settings" using primary key columns */
  app_settings_by_pk?: Maybe<App_Settings>;
  /** fetch data from the table: "customer" */
  customer: Array<Customer>;
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>;
  /** An array relationship */
  customer_notes: Array<Customer_Notes>;
  /** An aggregate relationship */
  customer_notes_aggregate: Customer_Notes_Aggregate;
  /** fetch data from the table: "customer_notes" using primary key columns */
  customer_notes_by_pk?: Maybe<Customer_Notes>;
  /** fetch data from the table: "customer_project" */
  customer_project: Array<Customer_Project>;
  /** fetch aggregated fields from the table: "customer_project" */
  customer_project_aggregate: Customer_Project_Aggregate;
  /** fetch data from the table: "customer_project" using primary key columns */
  customer_project_by_pk?: Maybe<Customer_Project>;
  /** fetch data from the table: "hosted_at" */
  hosted_at: Array<Hosted_At>;
  /** fetch aggregated fields from the table: "hosted_at" */
  hosted_at_aggregate: Hosted_At_Aggregate;
  /** fetch data from the table: "hosted_at" using primary key columns */
  hosted_at_by_pk?: Maybe<Hosted_At>;
  /** fetch data from the table: "occupied_room" */
  occupied_room: Array<Occupied_Room>;
  /** fetch aggregated fields from the table: "occupied_room" */
  occupied_room_aggregate: Occupied_Room_Aggregate;
  /** fetch data from the table: "occupied_room" using primary key columns */
  occupied_room_by_pk?: Maybe<Occupied_Room>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** fetch data from the table: "reserved_room" */
  reserved_room: Array<Reserved_Room>;
  /** fetch aggregated fields from the table: "reserved_room" */
  reserved_room_aggregate: Reserved_Room_Aggregate;
  /** fetch data from the table: "reserved_room" using primary key columns */
  reserved_room_by_pk?: Maybe<Reserved_Room>;
  /** fetch data from the table: "room" */
  room: Array<Room>;
  /** fetch aggregated fields from the table: "room" */
  room_aggregate: Room_Aggregate;
  /** fetch data from the table: "room" using primary key columns */
  room_by_pk?: Maybe<Room>;
  /** fetch data from the table: "room_type" */
  room_type: Array<Room_Type>;
  /** fetch aggregated fields from the table: "room_type" */
  room_type_aggregate: Room_Type_Aggregate;
  /** fetch data from the table: "room_type" using primary key columns */
  room_type_by_pk?: Maybe<Room_Type>;
};


export type Subscription_RootApp_SettingsArgs = {
  distinct_on?: InputMaybe<Array<App_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Settings_Order_By>>;
  where?: InputMaybe<App_Settings_Bool_Exp>;
};


export type Subscription_RootApp_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Settings_Order_By>>;
  where?: InputMaybe<App_Settings_Bool_Exp>;
};


export type Subscription_RootApp_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomer_NotesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


export type Subscription_RootCustomer_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Notes_Order_By>>;
  where?: InputMaybe<Customer_Notes_Bool_Exp>;
};


export type Subscription_RootCustomer_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


export type Subscription_RootCustomer_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Project_Order_By>>;
  where?: InputMaybe<Customer_Project_Bool_Exp>;
};


export type Subscription_RootCustomer_Project_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootHosted_AtArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


export type Subscription_RootHosted_At_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hosted_At_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hosted_At_Order_By>>;
  where?: InputMaybe<Hosted_At_Bool_Exp>;
};


export type Subscription_RootHosted_At_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOccupied_RoomArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


export type Subscription_RootOccupied_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Occupied_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Occupied_Room_Order_By>>;
  where?: InputMaybe<Occupied_Room_Bool_Exp>;
};


export type Subscription_RootOccupied_Room_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPermissions_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReservationArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReservation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReserved_RoomArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


export type Subscription_RootReserved_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reserved_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reserved_Room_Order_By>>;
  where?: InputMaybe<Reserved_Room_Bool_Exp>;
};


export type Subscription_RootReserved_Room_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRoomArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Order_By>>;
  where?: InputMaybe<Room_Bool_Exp>;
};


export type Subscription_RootRoom_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRoom_TypeArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Subscription_RootRoom_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Room_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Room_Type_Order_By>>;
  where?: InputMaybe<Room_Type_Bool_Exp>;
};


export type Subscription_RootRoom_Type_By_PkArgs = {
  id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AddProjectMutationVariables = Exact<{
  projects_insert_input: Projects_Insert_Input;
}>;


export type AddProjectMutation = { __typename?: 'mutation_root', insert_projects_one?: { __typename?: 'projects', id: any, name?: string | null } | null };

export type AddRoomMutationVariables = Exact<{
  room_id?: InputMaybe<Scalars['Int']>;
  guest_id?: InputMaybe<Scalars['uuid']>;
  date_in?: InputMaybe<Scalars['date']>;
  date_out?: InputMaybe<Scalars['date']>;
  check_in?: InputMaybe<Scalars['timestamptz']>;
}>;


export type AddRoomMutation = { __typename?: 'mutation_root', insert_reservation_one?: { __typename?: 'reservation', id: number } | null };

export type AccessTokenCheckMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type AccessTokenCheckMutation = { __typename?: 'mutation_root', accessTokenCheck?: { __typename?: 'AccessTokenCheckOutput', accessTokenSuccess: string } | null };

export type CheckoutRoomMutationVariables = Exact<{
  occupied_room_id: Scalars['Int'];
  check_out?: InputMaybe<Scalars['timestamptz']>;
  room_id: Scalars['Int'];
  status: Scalars['String'];
}>;


export type CheckoutRoomMutation = { __typename?: 'mutation_root', update_occupied_room_by_pk?: { __typename?: 'occupied_room', check_out?: any | null, check_in?: any | null } | null, update_room_by_pk?: { __typename?: 'room', room_number: string, status?: string | null } | null };

export type GetCloudbedsAccessTokenMutationVariables = Exact<{
  property: Scalars['String'];
  code: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
}>;


export type GetCloudbedsAccessTokenMutation = { __typename?: 'mutation_root', cloudbeds?: { __typename?: 'CloudbedsOutput', accessToken: any } | null };

export type GetAvailableRoomTypesMutationVariables = Exact<{
  get_available_room_types_input: GetAvailableRoomTypesInput;
}>;


export type GetAvailableRoomTypesMutation = { __typename?: 'mutation_root', cloudbeds_room_getAvailableRoomTypes?: { __typename?: 'GetAvailableRoomTypesOutput', data?: any | null, error?: any | null } | null };

export type GetGuestQueryVariables = Exact<{
  get_guest_input: GetGuestInput;
}>;


export type GetGuestQuery = { __typename?: 'query_root', cloudbeds_guest_getGuest?: { __typename?: 'GetGuestOutput', data?: any | null, error?: any | null } | null };

export type GetGuestsByFilterMutationVariables = Exact<{
  get_guests_by_filter_input: GetGuestsByFilterInput;
}>;


export type GetGuestsByFilterMutation = { __typename?: 'mutation_root', cloudbeds_guest_getGuestsByFilter?: { __typename?: 'GetGuestsByFilterOutput', data?: any | null, error?: any | null } | null };

export type GetHotelsQueryVariables = Exact<{
  get_hotels_input: GetHotelsInput;
}>;


export type GetHotelsQuery = { __typename?: 'query_root', getHotels?: { __typename?: 'GetHotelsOutput', data?: any | null, error?: any | null } | null };

export type GetPaymentMethodsMutationVariables = Exact<{
  get_payment_methods_input: GetPaymentMethodsInput;
}>;


export type GetPaymentMethodsMutation = { __typename?: 'mutation_root', cloudbeds_payment_getPaymentMethods?: { __typename?: 'GetPaymentMethodsOutput', data?: any | null, error?: any | null } | null };

export type GetReservationInvoiceInformationQueryVariables = Exact<{
  get_reservation_invoice_information_input: GetReservationInvoiceInformationInput;
}>;


export type GetReservationInvoiceInformationQuery = { __typename?: 'query_root', cloudbeds_reservation_getReservationInvoiceInformation?: { __typename?: 'GetReservationInvoiceInformationOutput', data?: any | null, error?: any | null } | null };

export type GetReservationWithRateDetailsQueryVariables = Exact<{
  get_reservations_with_rate_details_input: GetReservationsWithRateDetailsInput;
}>;


export type GetReservationWithRateDetailsQuery = { __typename?: 'query_root', cloudbeds_reservation_getReservationsWithRateDetails?: { __typename?: 'GetReservationsWithRateDetailsOutput', data?: any | null, error?: any | null } | null };

export type GetRoomsMutationVariables = Exact<{
  get_rooms_input: GetRoomsInput;
}>;


export type GetRoomsMutation = { __typename?: 'mutation_root', cloudbeds_room_getRooms?: { __typename?: 'GetRoomsOutput', data?: any | null, error?: any | null } | null };

export type GetTransactionsMutationVariables = Exact<{
  get_transactions_input: GetTransactionsInput;
}>;


export type GetTransactionsMutation = { __typename?: 'mutation_root', cloudbeds_payment_getTransactions?: { __typename?: 'GetTransactionsOutput', data?: any | null, error?: any | null } | null };

export type PostPaymentMutationVariables = Exact<{
  post_payment_input: PostPaymentInput;
}>;


export type PostPaymentMutation = { __typename?: 'mutation_root', cloudbeds_payment_postPayment?: { __typename?: 'PostPaymentOutput', data?: any | null, error?: any | null } | null };

export type PostReservationMutationVariables = Exact<{
  post_reservation_input: PostReservationInput;
}>;


export type PostReservationMutation = { __typename?: 'mutation_root', cloudbeds_reservation_postReservation?: { __typename?: 'PostReservationOutput', data?: any | null, error?: any | null } | null };

export type PostRoomAssignMutationVariables = Exact<{
  post_room_assign_input: PostRoomAssignInput;
}>;


export type PostRoomAssignMutation = { __typename?: 'mutation_root', cloudbeds_room_postRoomAssign?: { __typename?: 'PostRoomAssignOutput', data?: any | null, error?: any | null } | null };

export type PostRoomCheckInMutationVariables = Exact<{
  post_room_check_in_input: PostRoomCheckInInput;
}>;


export type PostRoomCheckInMutation = { __typename?: 'mutation_root', cloudbeds_room_postRoomCheckIn?: { __typename?: 'PostRoomCheckInOutput', data?: any | null, error?: any | null } | null };

export type GetCustomerNotesByCustomerIdQueryVariables = Exact<{
  customer_id: Scalars['uuid'];
}>;


export type GetCustomerNotesByCustomerIdQuery = { __typename?: 'query_root', customer_notes: Array<{ __typename?: 'customer_notes', author_id?: any | null, id: number, note?: string | null, project_id?: any | null, rating?: number | null, customer_id?: any | null, project?: { __typename?: 'projects', name?: string | null } | null }> };

export type GetCustomersQueryVariables = Exact<{
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetCustomersQuery = { __typename?: 'query_root', customer: Array<{ __typename?: 'customer', id: any, first_name: string, last_name: string, address?: string | null, phone_number?: string | null, description?: string | null, rating?: any | null, birthdate?: any | null, user_obj?: any | null }>, customer_aggregate: { __typename?: 'customer_aggregate', aggregate?: { __typename?: 'customer_aggregate_fields', count: number } | null } };

export type GetProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, cloudbeds_token?: any | null }> };

export type GetRoomTypesQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type GetRoomTypesQuery = { __typename?: 'query_root', room_type: Array<{ __typename?: 'room_type', description?: string | null, id: number, max_capacity?: number | null, name: string }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'query_root', customer: Array<{ __typename?: 'customer', address?: string | null, first_name: string, id: any, last_name: string, phone_number?: string | null, description?: string | null, rating?: any | null, birthdate?: any | null }> };

export type InsertCustomerNotesMutationVariables = Exact<{
  customer_notes_insert_input: Customer_Notes_Insert_Input;
}>;


export type InsertCustomerNotesMutation = { __typename?: 'mutation_root', insert_customer_notes_one?: { __typename?: 'customer_notes', author_id?: any | null, customer_id?: any | null, id: number, note?: string | null, rating?: number | null } | null };

export type InsertRoomMutationVariables = Exact<{
  room_insert_input: Room_Insert_Input;
}>;


export type InsertRoomMutation = { __typename?: 'mutation_root', insert_room_one?: { __typename?: 'room', name?: string | null, rating: any, room_number: string, room_type_id?: number | null, smoke?: boolean | null, status?: string | null } | null };

export type InsertRoomTypeMutationVariables = Exact<{
  room_type_insert_input: Room_Type_Insert_Input;
}>;


export type InsertRoomTypeMutation = { __typename?: 'mutation_root', insert_room_type_one?: { __typename?: 'room_type', description?: string | null, id: number, max_capacity?: number | null, name: string } | null };

export type MyMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type MyMutationMutation = { __typename?: 'mutation_root', insert_reservation_one?: { __typename?: 'reservation', guest_id: any, date_out: any, date_in: any, occupied_rooms: Array<{ __typename?: 'occupied_room', check_in?: any | null, check_out?: any | null, room_id?: number | null }> } | null };

export type InsertUserMutationVariables = Exact<{
  customer_insert_input: Customer_Insert_Input;
}>;


export type InsertUserMutation = { __typename?: 'mutation_root', insert_customer_one?: { __typename?: 'customer', address?: string | null, first_name: string, id: any, last_name: string, phone_number?: string | null, rating?: any | null, description?: string | null, birthdate?: any | null } | null };

export type SearchUsersQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type SearchUsersQuery = { __typename?: 'query_root', customer: Array<{ __typename?: 'customer', id: any, first_name: string, last_name: string, address?: string | null, phone_number?: string | null, description?: string | null, rating?: any | null, birthdate?: any | null, user_obj?: any | null }> };

export type TodaysRoomsQueryVariables = Exact<{
  project_id: Scalars['uuid'];
}>;


export type TodaysRoomsQuery = { __typename?: 'query_root', room: Array<{ __typename?: 'room', room_number: string, id: number, status?: string | null, occupied_rooms: Array<{ __typename?: 'occupied_room', id: number, check_in?: any | null, check_out?: any | null, reservation?: { __typename?: 'reservation', date_in: any, date_out: any, customer: { __typename?: 'customer', first_name: string, last_name: string, user_obj?: any | null } } | null }> }> };

export type UpdateRoomMutationVariables = Exact<{
  id: Scalars['Int'];
  room_set_input: Room_Set_Input;
}>;


export type UpdateRoomMutation = { __typename?: 'mutation_root', update_room_by_pk?: { __typename?: 'room', name?: string | null, rating: any, room_number: string, status?: string | null } | null };

export type UpdateRoomStatusMutationVariables = Exact<{
  room_id: Scalars['Int'];
  status: Scalars['String'];
}>;


export type UpdateRoomStatusMutation = { __typename?: 'mutation_root', update_room_by_pk?: { __typename?: 'room', room_number: string, status?: string | null } | null };

export type GetRealtimeUpdatedCustomerSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetRealtimeUpdatedCustomerSubscription = { __typename?: 'subscription_root', customer: Array<{ __typename?: 'customer', id: any, first_name: string, updated_at?: any | null, last_name: string, seen_last?: any | null, user_obj?: any | null, phone_number?: string | null, address?: string | null, description?: string | null, rating?: any | null, birthdate?: any | null }> };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  customer_set_input: Customer_Set_Input;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_customer_by_pk?: { __typename?: 'customer', address?: string | null, first_name: string, id: any, last_name: string, phone_number?: string | null, rating?: any | null, description?: string | null, birthdate?: any | null } | null };


export const AddProjectDocument = `
    mutation AddProject($projects_insert_input: projects_insert_input!) {
  insert_projects_one(object: $projects_insert_input) {
    id
    name
  }
}
    `;
export const useAddProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddProjectMutation, TError, AddProjectMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AddProjectMutation, TError, AddProjectMutationVariables, TContext>(
      ['AddProject'],
      (variables?: AddProjectMutationVariables) => fetcher<AddProjectMutation, AddProjectMutationVariables>(client, AddProjectDocument, variables, headers)(),
      options
    );
export const AddRoomDocument = `
    mutation AddRoom($room_id: Int, $guest_id: uuid, $date_in: date, $date_out: date, $check_in: timestamptz) {
  insert_reservation_one(
    object: {occupied_rooms: {data: {room_id: $room_id, check_in: $check_in}}, guest_id: $guest_id, date_in: $date_in, date_out: $date_out}
  ) {
    id
  }
}
    `;
export const useAddRoomMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddRoomMutation, TError, AddRoomMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AddRoomMutation, TError, AddRoomMutationVariables, TContext>(
      ['AddRoom'],
      (variables?: AddRoomMutationVariables) => fetcher<AddRoomMutation, AddRoomMutationVariables>(client, AddRoomDocument, variables, headers)(),
      options
    );
export const AccessTokenCheckDocument = `
    mutation AccessTokenCheck($accessToken: String!) {
  accessTokenCheck(arg1: {accessToken: $accessToken}) {
    accessTokenSuccess
  }
}
    `;
export const useAccessTokenCheckMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AccessTokenCheckMutation, TError, AccessTokenCheckMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AccessTokenCheckMutation, TError, AccessTokenCheckMutationVariables, TContext>(
      ['AccessTokenCheck'],
      (variables?: AccessTokenCheckMutationVariables) => fetcher<AccessTokenCheckMutation, AccessTokenCheckMutationVariables>(client, AccessTokenCheckDocument, variables, headers)(),
      options
    );
export const CheckoutRoomDocument = `
    mutation CheckoutRoom($occupied_room_id: Int!, $check_out: timestamptz, $room_id: Int!, $status: String!) {
  update_occupied_room_by_pk(
    pk_columns: {id: $occupied_room_id}
    _set: {check_out: $check_out}
  ) {
    check_out
    check_in
  }
  update_room_by_pk(pk_columns: {id: $room_id}, _set: {status: $status}) {
    room_number
    status
  }
}
    `;
export const useCheckoutRoomMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CheckoutRoomMutation, TError, CheckoutRoomMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CheckoutRoomMutation, TError, CheckoutRoomMutationVariables, TContext>(
      ['CheckoutRoom'],
      (variables?: CheckoutRoomMutationVariables) => fetcher<CheckoutRoomMutation, CheckoutRoomMutationVariables>(client, CheckoutRoomDocument, variables, headers)(),
      options
    );
export const GetCloudbedsAccessTokenDocument = `
    mutation GetCloudbedsAccessToken($property: String!, $code: String!, $state: String) {
  cloudbeds(arg1: {code: $code, state: $state, property: $property}) {
    accessToken
  }
}
    `;
export const useGetCloudbedsAccessTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetCloudbedsAccessTokenMutation, TError, GetCloudbedsAccessTokenMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetCloudbedsAccessTokenMutation, TError, GetCloudbedsAccessTokenMutationVariables, TContext>(
      ['GetCloudbedsAccessToken'],
      (variables?: GetCloudbedsAccessTokenMutationVariables) => fetcher<GetCloudbedsAccessTokenMutation, GetCloudbedsAccessTokenMutationVariables>(client, GetCloudbedsAccessTokenDocument, variables, headers)(),
      options
    );
export const GetAvailableRoomTypesDocument = `
    mutation GetAvailableRoomTypes($get_available_room_types_input: GetAvailableRoomTypesInput!) {
  cloudbeds_room_getAvailableRoomTypes(arg1: $get_available_room_types_input) {
    data
    error
  }
}
    `;
export const useGetAvailableRoomTypesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetAvailableRoomTypesMutation, TError, GetAvailableRoomTypesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetAvailableRoomTypesMutation, TError, GetAvailableRoomTypesMutationVariables, TContext>(
      ['GetAvailableRoomTypes'],
      (variables?: GetAvailableRoomTypesMutationVariables) => fetcher<GetAvailableRoomTypesMutation, GetAvailableRoomTypesMutationVariables>(client, GetAvailableRoomTypesDocument, variables, headers)(),
      options
    );
export const GetGuestDocument = `
    query GetGuest($get_guest_input: GetGuestInput!) {
  cloudbeds_guest_getGuest(arg1: $get_guest_input) {
    data
    error
  }
}
    `;
export const useGetGuestQuery = <
      TData = GetGuestQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetGuestQueryVariables,
      options?: UseQueryOptions<GetGuestQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGuestQuery, TError, TData>(
      ['GetGuest', variables],
      fetcher<GetGuestQuery, GetGuestQueryVariables>(client, GetGuestDocument, variables, headers),
      options
    );

useGetGuestQuery.getKey = (variables: GetGuestQueryVariables) => ['GetGuest', variables];
;

export const GetGuestsByFilterDocument = `
    mutation GetGuestsByFilter($get_guests_by_filter_input: GetGuestsByFilterInput!) {
  cloudbeds_guest_getGuestsByFilter(arg1: $get_guests_by_filter_input) {
    data
    error
  }
}
    `;
export const useGetGuestsByFilterMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetGuestsByFilterMutation, TError, GetGuestsByFilterMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetGuestsByFilterMutation, TError, GetGuestsByFilterMutationVariables, TContext>(
      ['GetGuestsByFilter'],
      (variables?: GetGuestsByFilterMutationVariables) => fetcher<GetGuestsByFilterMutation, GetGuestsByFilterMutationVariables>(client, GetGuestsByFilterDocument, variables, headers)(),
      options
    );
export const GetHotelsDocument = `
    query GetHotels($get_hotels_input: GetHotelsInput!) {
  getHotels(arg1: $get_hotels_input) {
    data
    error
  }
}
    `;
export const useGetHotelsQuery = <
      TData = GetHotelsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetHotelsQueryVariables,
      options?: UseQueryOptions<GetHotelsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetHotelsQuery, TError, TData>(
      ['GetHotels', variables],
      fetcher<GetHotelsQuery, GetHotelsQueryVariables>(client, GetHotelsDocument, variables, headers),
      options
    );

useGetHotelsQuery.getKey = (variables: GetHotelsQueryVariables) => ['GetHotels', variables];
;

export const GetPaymentMethodsDocument = `
    mutation GetPaymentMethods($get_payment_methods_input: GetPaymentMethodsInput!) {
  cloudbeds_payment_getPaymentMethods(arg1: $get_payment_methods_input) {
    data
    error
  }
}
    `;
export const useGetPaymentMethodsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetPaymentMethodsMutation, TError, GetPaymentMethodsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetPaymentMethodsMutation, TError, GetPaymentMethodsMutationVariables, TContext>(
      ['GetPaymentMethods'],
      (variables?: GetPaymentMethodsMutationVariables) => fetcher<GetPaymentMethodsMutation, GetPaymentMethodsMutationVariables>(client, GetPaymentMethodsDocument, variables, headers)(),
      options
    );
export const GetReservationInvoiceInformationDocument = `
    query GetReservationInvoiceInformation($get_reservation_invoice_information_input: GetReservationInvoiceInformationInput!) {
  cloudbeds_reservation_getReservationInvoiceInformation(
    arg1: $get_reservation_invoice_information_input
  ) {
    data
    error
  }
}
    `;
export const useGetReservationInvoiceInformationQuery = <
      TData = GetReservationInvoiceInformationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetReservationInvoiceInformationQueryVariables,
      options?: UseQueryOptions<GetReservationInvoiceInformationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetReservationInvoiceInformationQuery, TError, TData>(
      ['GetReservationInvoiceInformation', variables],
      fetcher<GetReservationInvoiceInformationQuery, GetReservationInvoiceInformationQueryVariables>(client, GetReservationInvoiceInformationDocument, variables, headers),
      options
    );

useGetReservationInvoiceInformationQuery.getKey = (variables: GetReservationInvoiceInformationQueryVariables) => ['GetReservationInvoiceInformation', variables];
;

export const GetReservationWithRateDetailsDocument = `
    query GetReservationWithRateDetails($get_reservations_with_rate_details_input: GetReservationsWithRateDetailsInput!) {
  cloudbeds_reservation_getReservationsWithRateDetails(
    arg1: $get_reservations_with_rate_details_input
  ) {
    data
    error
  }
}
    `;
export const useGetReservationWithRateDetailsQuery = <
      TData = GetReservationWithRateDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetReservationWithRateDetailsQueryVariables,
      options?: UseQueryOptions<GetReservationWithRateDetailsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetReservationWithRateDetailsQuery, TError, TData>(
      ['GetReservationWithRateDetails', variables],
      fetcher<GetReservationWithRateDetailsQuery, GetReservationWithRateDetailsQueryVariables>(client, GetReservationWithRateDetailsDocument, variables, headers),
      options
    );

useGetReservationWithRateDetailsQuery.getKey = (variables: GetReservationWithRateDetailsQueryVariables) => ['GetReservationWithRateDetails', variables];
;

export const GetRoomsDocument = `
    mutation GetRooms($get_rooms_input: GetRoomsInput!) {
  cloudbeds_room_getRooms(arg1: $get_rooms_input) {
    data
    error
  }
}
    `;
export const useGetRoomsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetRoomsMutation, TError, GetRoomsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetRoomsMutation, TError, GetRoomsMutationVariables, TContext>(
      ['GetRooms'],
      (variables?: GetRoomsMutationVariables) => fetcher<GetRoomsMutation, GetRoomsMutationVariables>(client, GetRoomsDocument, variables, headers)(),
      options
    );
export const GetTransactionsDocument = `
    mutation GetTransactions($get_transactions_input: GetTransactionsInput!) {
  cloudbeds_payment_getTransactions(arg1: $get_transactions_input) {
    data
    error
  }
}
    `;
export const useGetTransactionsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetTransactionsMutation, TError, GetTransactionsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetTransactionsMutation, TError, GetTransactionsMutationVariables, TContext>(
      ['GetTransactions'],
      (variables?: GetTransactionsMutationVariables) => fetcher<GetTransactionsMutation, GetTransactionsMutationVariables>(client, GetTransactionsDocument, variables, headers)(),
      options
    );
export const PostPaymentDocument = `
    mutation PostPayment($post_payment_input: PostPaymentInput!) {
  cloudbeds_payment_postPayment(arg1: $post_payment_input) {
    data
    error
  }
}
    `;
export const usePostPaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PostPaymentMutation, TError, PostPaymentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PostPaymentMutation, TError, PostPaymentMutationVariables, TContext>(
      ['PostPayment'],
      (variables?: PostPaymentMutationVariables) => fetcher<PostPaymentMutation, PostPaymentMutationVariables>(client, PostPaymentDocument, variables, headers)(),
      options
    );
export const PostReservationDocument = `
    mutation PostReservation($post_reservation_input: PostReservationInput!) {
  cloudbeds_reservation_postReservation(arg1: $post_reservation_input) {
    data
    error
  }
}
    `;
export const usePostReservationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PostReservationMutation, TError, PostReservationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PostReservationMutation, TError, PostReservationMutationVariables, TContext>(
      ['PostReservation'],
      (variables?: PostReservationMutationVariables) => fetcher<PostReservationMutation, PostReservationMutationVariables>(client, PostReservationDocument, variables, headers)(),
      options
    );
export const PostRoomAssignDocument = `
    mutation PostRoomAssign($post_room_assign_input: PostRoomAssignInput!) {
  cloudbeds_room_postRoomAssign(arg1: $post_room_assign_input) {
    data
    error
  }
}
    `;
export const usePostRoomAssignMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PostRoomAssignMutation, TError, PostRoomAssignMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PostRoomAssignMutation, TError, PostRoomAssignMutationVariables, TContext>(
      ['PostRoomAssign'],
      (variables?: PostRoomAssignMutationVariables) => fetcher<PostRoomAssignMutation, PostRoomAssignMutationVariables>(client, PostRoomAssignDocument, variables, headers)(),
      options
    );
export const PostRoomCheckInDocument = `
    mutation PostRoomCheckIn($post_room_check_in_input: PostRoomCheckInInput!) {
  cloudbeds_room_postRoomCheckIn(arg1: $post_room_check_in_input) {
    data
    error
  }
}
    `;
export const usePostRoomCheckInMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PostRoomCheckInMutation, TError, PostRoomCheckInMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PostRoomCheckInMutation, TError, PostRoomCheckInMutationVariables, TContext>(
      ['PostRoomCheckIn'],
      (variables?: PostRoomCheckInMutationVariables) => fetcher<PostRoomCheckInMutation, PostRoomCheckInMutationVariables>(client, PostRoomCheckInDocument, variables, headers)(),
      options
    );
export const GetCustomerNotesByCustomerIdDocument = `
    query GetCustomerNotesByCustomerId($customer_id: uuid!) {
  customer_notes(where: {customer_id: {_eq: $customer_id}}) {
    author_id
    id
    note
    project_id
    rating
    customer_id
    project {
      name
    }
  }
}
    `;
export const useGetCustomerNotesByCustomerIdQuery = <
      TData = GetCustomerNotesByCustomerIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCustomerNotesByCustomerIdQueryVariables,
      options?: UseQueryOptions<GetCustomerNotesByCustomerIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCustomerNotesByCustomerIdQuery, TError, TData>(
      ['GetCustomerNotesByCustomerId', variables],
      fetcher<GetCustomerNotesByCustomerIdQuery, GetCustomerNotesByCustomerIdQueryVariables>(client, GetCustomerNotesByCustomerIdDocument, variables, headers),
      options
    );

useGetCustomerNotesByCustomerIdQuery.getKey = (variables: GetCustomerNotesByCustomerIdQueryVariables) => ['GetCustomerNotesByCustomerId', variables];
;

export const GetCustomersDocument = `
    query GetCustomers($first_name: String, $last_name: String, $limit: Int, $offset: Int) {
  customer(
    order_by: {seen_last: desc_nulls_last}
    limit: 10
    offset: $offset
    where: {first_name: {_iregex: $first_name}, last_name: {_iregex: $last_name}}
  ) {
    id
    first_name
    last_name
    address
    phone_number
    description
    rating
    birthdate
    user_obj
  }
  customer_aggregate(
    where: {first_name: {_iregex: $first_name}, last_name: {_iregex: $last_name}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetCustomersQuery = <
      TData = GetCustomersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCustomersQueryVariables,
      options?: UseQueryOptions<GetCustomersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCustomersQuery, TError, TData>(
      variables === undefined ? ['GetCustomers'] : ['GetCustomers', variables],
      fetcher<GetCustomersQuery, GetCustomersQueryVariables>(client, GetCustomersDocument, variables, headers),
      options
    );

useGetCustomersQuery.getKey = (variables?: GetCustomersQueryVariables) => variables === undefined ? ['GetCustomers'] : ['GetCustomers', variables];
;

export const GetProjectsDocument = `
    query GetProjects {
  projects {
    id
    name
    cloudbeds_token
  }
}
    `;
export const useGetProjectsQuery = <
      TData = GetProjectsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProjectsQueryVariables,
      options?: UseQueryOptions<GetProjectsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetProjectsQuery, TError, TData>(
      variables === undefined ? ['GetProjects'] : ['GetProjects', variables],
      fetcher<GetProjectsQuery, GetProjectsQueryVariables>(client, GetProjectsDocument, variables, headers),
      options
    );

useGetProjectsQuery.getKey = (variables?: GetProjectsQueryVariables) => variables === undefined ? ['GetProjects'] : ['GetProjects', variables];
;

export const GetRoomTypesDocument = `
    query GetRoomTypes($project_id: uuid!) {
  room_type(where: {project_id: {_eq: $project_id}}) {
    description
    id
    max_capacity
    name
  }
}
    `;
export const useGetRoomTypesQuery = <
      TData = GetRoomTypesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomTypesQueryVariables,
      options?: UseQueryOptions<GetRoomTypesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetRoomTypesQuery, TError, TData>(
      ['GetRoomTypes', variables],
      fetcher<GetRoomTypesQuery, GetRoomTypesQueryVariables>(client, GetRoomTypesDocument, variables, headers),
      options
    );

useGetRoomTypesQuery.getKey = (variables: GetRoomTypesQueryVariables) => ['GetRoomTypes', variables];
;

export const GetUsersDocument = `
    query GetUsers {
  customer(order_by: {seen_last: asc}) {
    address
    first_name
    id
    last_name
    phone_number
    description
    rating
    birthdate
  }
}
    `;
export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUsersQueryVariables,
      options?: UseQueryOptions<GetUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers'] : ['GetUsers', variables],
      fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, variables, headers),
      options
    );

useGetUsersQuery.getKey = (variables?: GetUsersQueryVariables) => variables === undefined ? ['GetUsers'] : ['GetUsers', variables];
;

export const InsertCustomerNotesDocument = `
    mutation InsertCustomerNotes($customer_notes_insert_input: customer_notes_insert_input!) {
  insert_customer_notes_one(object: $customer_notes_insert_input) {
    author_id
    customer_id
    id
    note
    rating
  }
}
    `;
export const useInsertCustomerNotesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertCustomerNotesMutation, TError, InsertCustomerNotesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InsertCustomerNotesMutation, TError, InsertCustomerNotesMutationVariables, TContext>(
      ['InsertCustomerNotes'],
      (variables?: InsertCustomerNotesMutationVariables) => fetcher<InsertCustomerNotesMutation, InsertCustomerNotesMutationVariables>(client, InsertCustomerNotesDocument, variables, headers)(),
      options
    );
export const InsertRoomDocument = `
    mutation InsertRoom($room_insert_input: room_insert_input!) {
  insert_room_one(object: $room_insert_input) {
    name
    rating
    room_number
    room_type_id
    smoke
    status
  }
}
    `;
export const useInsertRoomMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertRoomMutation, TError, InsertRoomMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InsertRoomMutation, TError, InsertRoomMutationVariables, TContext>(
      ['InsertRoom'],
      (variables?: InsertRoomMutationVariables) => fetcher<InsertRoomMutation, InsertRoomMutationVariables>(client, InsertRoomDocument, variables, headers)(),
      options
    );
export const InsertRoomTypeDocument = `
    mutation InsertRoomType($room_type_insert_input: room_type_insert_input!) {
  insert_room_type_one(object: $room_type_insert_input) {
    description
    id
    max_capacity
    name
  }
}
    `;
export const useInsertRoomTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertRoomTypeMutation, TError, InsertRoomTypeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InsertRoomTypeMutation, TError, InsertRoomTypeMutationVariables, TContext>(
      ['InsertRoomType'],
      (variables?: InsertRoomTypeMutationVariables) => fetcher<InsertRoomTypeMutation, InsertRoomTypeMutationVariables>(client, InsertRoomTypeDocument, variables, headers)(),
      options
    );
export const MyMutationDocument = `
    mutation MyMutation {
  insert_reservation_one(
    object: {date_in: "", date_out: "", guest_id: "", occupied_rooms: {data: {check_in: "", check_out: "", id: 10, room: {data: {room_number: "", id: 10}}}}}
  ) {
    guest_id
    date_out
    date_in
    occupied_rooms {
      check_in
      check_out
      room_id
    }
  }
}
    `;
export const useMyMutationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MyMutationMutation, TError, MyMutationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<MyMutationMutation, TError, MyMutationMutationVariables, TContext>(
      ['MyMutation'],
      (variables?: MyMutationMutationVariables) => fetcher<MyMutationMutation, MyMutationMutationVariables>(client, MyMutationDocument, variables, headers)(),
      options
    );
export const InsertUserDocument = `
    mutation InsertUser($customer_insert_input: customer_insert_input!) {
  insert_customer_one(object: $customer_insert_input) {
    address
    first_name
    id
    last_name
    phone_number
    rating
    description
    birthdate
  }
}
    `;
export const useInsertUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertUserMutation, TError, InsertUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InsertUserMutation, TError, InsertUserMutationVariables, TContext>(
      ['InsertUser'],
      (variables?: InsertUserMutationVariables) => fetcher<InsertUserMutation, InsertUserMutationVariables>(client, InsertUserDocument, variables, headers)(),
      options
    );
export const SearchUsersDocument = `
    query SearchUsers($searchString: String!) {
  customer(
    order_by: {seen_last: desc_nulls_last}
    where: {_or: [{first_name: {_iregex: $searchString}}, {last_name: {_iregex: $searchString}}]}
  ) {
    id
    first_name
    last_name
    address
    phone_number
    description
    rating
    birthdate
    user_obj
  }
}
    `;
export const useSearchUsersQuery = <
      TData = SearchUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SearchUsersQueryVariables,
      options?: UseQueryOptions<SearchUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SearchUsersQuery, TError, TData>(
      ['SearchUsers', variables],
      fetcher<SearchUsersQuery, SearchUsersQueryVariables>(client, SearchUsersDocument, variables, headers),
      options
    );

useSearchUsersQuery.getKey = (variables: SearchUsersQueryVariables) => ['SearchUsers', variables];
;

export const TodaysRoomsDocument = `
    query TodaysRooms($project_id: uuid!) {
  room(where: {project_id: {_eq: $project_id}}) {
    room_number
    id
    status
    occupied_rooms(
      where: {check_out: {_is_null: true}, check_in: {_is_null: false}}
    ) {
      id
      reservation {
        date_in
        date_out
        customer {
          first_name
          last_name
          user_obj
        }
      }
      check_in
      check_out
    }
  }
}
    `;
export const useTodaysRoomsQuery = <
      TData = TodaysRoomsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: TodaysRoomsQueryVariables,
      options?: UseQueryOptions<TodaysRoomsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<TodaysRoomsQuery, TError, TData>(
      ['TodaysRooms', variables],
      fetcher<TodaysRoomsQuery, TodaysRoomsQueryVariables>(client, TodaysRoomsDocument, variables, headers),
      options
    );

useTodaysRoomsQuery.getKey = (variables: TodaysRoomsQueryVariables) => ['TodaysRooms', variables];
;

export const UpdateRoomDocument = `
    mutation UpdateRoom($id: Int!, $room_set_input: room_set_input!) {
  update_room_by_pk(pk_columns: {id: $id}, _set: $room_set_input) {
    name
    rating
    room_number
    status
  }
}
    `;
export const useUpdateRoomMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateRoomMutation, TError, UpdateRoomMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateRoomMutation, TError, UpdateRoomMutationVariables, TContext>(
      ['UpdateRoom'],
      (variables?: UpdateRoomMutationVariables) => fetcher<UpdateRoomMutation, UpdateRoomMutationVariables>(client, UpdateRoomDocument, variables, headers)(),
      options
    );
export const UpdateRoomStatusDocument = `
    mutation UpdateRoomStatus($room_id: Int!, $status: String!) {
  update_room_by_pk(pk_columns: {id: $room_id}, _set: {status: $status}) {
    room_number
    status
  }
}
    `;
export const useUpdateRoomStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateRoomStatusMutation, TError, UpdateRoomStatusMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateRoomStatusMutation, TError, UpdateRoomStatusMutationVariables, TContext>(
      ['UpdateRoomStatus'],
      (variables?: UpdateRoomStatusMutationVariables) => fetcher<UpdateRoomStatusMutation, UpdateRoomStatusMutationVariables>(client, UpdateRoomStatusDocument, variables, headers)(),
      options
    );
export const GetRealtimeUpdatedCustomerDocument = `
    subscription GetRealtimeUpdatedCustomer {
  customer(limit: 1, order_by: {seen_last: desc_nulls_last}) {
    id
    first_name
    updated_at
    last_name
    seen_last
    user_obj
    phone_number
    address
    description
    rating
    birthdate
  }
}
    `;
export const UpdateUserDocument = `
    mutation UpdateUser($id: uuid!, $customer_set_input: customer_set_input!) {
  update_customer_by_pk(pk_columns: {id: $id}, _set: $customer_set_input) {
    address
    first_name
    id
    last_name
    phone_number
    rating
    description
    birthdate
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    );