const win: any = window;
export const LoginCloudbedsButton = ({ clientId, redirectUri }: { redirectUri: string, clientId: string }) => {
    return (
        <>
            <div className="text-center pt-7"> <a
                className="btn btn-primary fw-bolder fs-6 px-7 py-3"
                onClick={() => {

                    const scope_adjustment = [
                        'delete:adjustment', 'read:adjustment', 'write:adjustment'
                    ];
                    const scope_customer_fields = [
                        'read:customFields', 'write:customFields'
                    ];

                    const scope_dashboard = [
                        'read:dashboard'
                    ];

                    const scope_emails = [
                        'read:communication', 'write:communication',
                    ];
                    const scope_guest = [
                        'write:guest', 'read:guest',
                    ];
                    const scope_hotel = [
                        // 'write:hotel', 
                        'read:hotel',
                    ];

                    const scope_house_account = [
                        // 'write:houseAccount', 
                        'read:houseAccount',
                    ];
                    const scope_housekeeping = [
                        'write:housekeeping',
                        'read:housekeeping',
                    ];
                    const scope_integration = ['--',];
                    const scope_item = [
                        'write:item',
                        'read:item',
                    ];
                    const scope_payment = [
                        'write:payment',
                        'read:payment',
                    ];

                    const scope_rate = [
                        'read:rate'
                    ];
                    const scope_reservation = [
                        'read:reservation',
                        'write:reservation'
                    ];
                    const scope_room = [
                        'read:room',
                        'write:room'
                    ];
                    const scope_taxesAndFees = [
                        'read:taxesAndFees'
                    ];
                    const scope_user = [
                        'read:user'
                    ];
                    const scopes = [
                        ...scope_dashboard,
                        ...scope_hotel,
                        ...scope_reservation,
                        ...scope_room,
                        ...scope_adjustment,
                        ...scope_customer_fields,
                        ...scope_emails,
                        ...scope_guest,
                        ...scope_item,
                        ...scope_payment,
                        ...scope_user,
                        ...scope_house_account,

                        ...scope_housekeeping,
                        ...scope_rate,
                        ...scope_taxesAndFees,
                    ];
                    win.location = `https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(" ")}`;
                    // win.location = `https://hotels.cloudbeds.com/api/v1.1/oauth?state=${project.id}&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(" ")}`;
                }}
            >
                Login
            </a>
            </div>
        </>
    )
}