/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useContext } from "react";
import { Modal } from "react-bootstrap-v5";
import { usePostRoomCheckInMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { ApplicationContext } from "../../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import { FindGuest } from "./FindGuest";
import { GetAvailableRooms } from "./GetAvailableRooms";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";
import { Payments } from "./Payments";
import { SelectDates } from "./SelectDates";
import { SelectDeposit } from "./SelectDeposit";
import { Summary } from "./Summary";

type Props = {
    show: boolean;
    handleClose: () => void;
};

const GetAvailableRoomsModal: React.FC<Props> = ({ show, handleClose }) => {

    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const { graphQlClient } = useAnonymousGraphQlClient();
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const [state, setState] = useState<{
        guest: any, dates: { endDate: any, startDate: any },
        room: any; deposit: any; payments: any; reservation: any
    }>(
        {
            guest: null,
            dates: { startDate: null, endDate: null },
            room: null,
            deposit: null, payments: null, reservation: null,
        });
    const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
    const [hasError, setHasError] = useState(false);

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(
            stepperRef.current as HTMLDivElement
        );
    };

    const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
        const updatedData = { ...data, ...fieldsToUpdate };
        setData(updatedData);
    };

    const checkAppBasic = (): boolean => {
        if (!data.appBasic.appName || !data.appBasic.appType) {
            return false;
        }
        return true;
    };

    const checkAppDataBase = (): boolean => {
        if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
            return false;
        }

        return true;
    };

    const prevStep = () => {
        if (!stepper.current) {
            return;
        }

        stepper.current.goPrev();
    };


    const postRoomCheckIn = usePostRoomCheckInMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            // need to also add deposit to this

            // this is where the notification goes
            // alert("boom")
            //boom
        }
    });


    const nextStep = (newState: any) => {
        // setHasError(false);
        // if (!stepper.current) {
        //     return;
        // }

        // if (stepper.current.getCurrentStepIndex() === 1) {
        //     if (!checkAppBasic()) {
        //         setHasError(true);
        //         return;
        //     }
        // }

        // if (stepper.current.getCurrentStepIndex() === 3) {
        //     if (!checkAppDataBase()) {
        //         setHasError(true);
        //         return;
        //     }
        // }
        if (newState) {

            setState({ ...state, ...newState });
        }
        stepper?.current?.goNext();
    };

    const submit = () => {
        // window.location.reload();
        //close modal
        handleClose();
    };

    return (
        <Modal
            id="kt_modal_create_app"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog-centered mw-1000px h-auto"
            show={show}
            onHide={handleClose}
            onEntered={loadStepper}
        >
            <div className="container px-10 py-10">
                <div className="modal-header py-2 d-flex justify-content-end border-0">
                    {/* begin::Close */}
                    <div
                        className="btn btn-icon btn-sm btn-light-primary"
                        onClick={handleClose}
                    >
                        <KTSVG
                            className="svg-icon-2"
                            path="/media/icons/duotone/Navigation/Close.svg"
                        />
                    </div>
                    {/* end::Close */}
                </div>

                <div className="modal-body">
                    {/*begin::Stepper */}

                    {/* <p>What does this solve?</p>
                    <p>This solves the issue of assigning room</p>
                    <p>Putting them in house</p>
                    <p>Getting payment/making it a whole number</p>
                    <p>Adding a week</p>
                    <p>Taking in a deposit and labelling it</p> */}
                    <div
                        ref={stepperRef}
                        className="stepper stepper-1 d-flex flex-column flex-xl-row flex-row-fluid"
                        id="kt_modal_create_app_stepper"
                    >
                        {/*begin::Aside */}
                        <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                            {/*begin::Nav */}
                            <div className="stepper-nav d-flex flex-column pt-5">
                                {/*begin::Step 1 */}
                                <div
                                    className="stepper-item current"
                                    data-kt-stepper-element="nav"
                                >
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">1</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">Find user/Add user</h3>
                                            <div className="stepper-desc">Collect money + deposit</div>
                                            <div className="stepper-desc"></div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 1 */}

                                {/*begin::Step 2 */}
                                <div className="stepper-item" data-kt-stepper-element="nav">
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">2</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">Select dates</h3>
                                            <div className="stepper-desc">
                                                Define your app framework
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 2 */}

                                {/*begin::Step 2 */}
                                <div className="stepper-item" data-kt-stepper-element="nav">
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">2</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">Select room</h3>
                                            <div className="stepper-desc">
                                                Define your app framework
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 2 */}

                                {/*begin::Step 3 */}
                                <div className="stepper-item" data-kt-stepper-element="nav">
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">3</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">Add deposit</h3>
                                            <div className="stepper-desc">
                                                PostReservation
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 3 */}

                                {/*begin::Step 4 */}
                                <div className="stepper-item" data-kt-stepper-element="nav">
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">4</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">See summary</h3>
                                            <div className="stepper-desc">
                                                PostPayment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 4 */}

                                {/*begin::Step 4 */}
                                <div className="stepper-item" data-kt-stepper-element="nav">
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">4</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">Choose payment types</h3>
                                            <div className="stepper-desc">
                                                PostPayment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 4 */}

                                {/*begin::Step 5 */}
                                <div className="stepper-item" data-kt-stepper-element="nav">
                                    <div className="stepper-wrapper">
                                        <div className="stepper-icon">
                                            <i className="stepper-check fas fa-check"></i>
                                            <span className="stepper-number">5</span>
                                        </div>
                                        <div className="stepper-label">
                                            <h3 className="stepper-title">Paid! Print Receipt</h3>
                                            <div className="stepper-desc">Review and Submit</div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Step 5 */}
                            </div>
                            {/*end::Nav */}
                        </div>
                        {/*begin::Aside */}

                        {/*begin::Content */}
                        <div className="d-flex flex-row-fluid justify-content-center">
                            {/*begin::Form */}
                            <form
                                className="pb-5 w-100 w-md-400px w-xl-500px"
                                noValidate
                                id="kt_modal_create_app_form"
                            >
                                {/*begin::Step 1 */}
                                <div className="pb-5 current" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <FindGuest nextStep={nextStep}></FindGuest>

                                    </div>
                                </div>
                                {/*end::Step 1 */}

                                {/*begin::Step 2 */}
                                <div className="pb-5" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <SelectDates nextStep={nextStep}></SelectDates>
                                        {/* <SelectDates nextStep={nextStep}></SelectDates> */}
                                    </div>
                                </div>
                                {/*end::Step 2 */}

                                {/*begin::Step 2 */}
                                <div className="pb-5" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <GetAvailableRooms nextStep={nextStep}></GetAvailableRooms>
                                        {/* <SelectDates nextStep={nextStep}></SelectDates> */}
                                    </div>
                                </div>
                                {/*end::Step 2 */}

                                {/*begin::Step 3 */}
                                <div className="pb-5" data-kt-stepper-element="content">
                                    <div className="w-100">

                                        <SelectDeposit nextStep={nextStep}></SelectDeposit>


                                    </div>
                                </div>
                                {/*end::Step 3 */}

                                {/*begin::Step 4 */}
                                <div className="pb-5" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <Summary summaryState={state} nextStep={nextStep}></Summary>
                                    </div>
                                </div>
                                {/*end::Step 4 */}

                                {/*begin::Step 4 */}
                                <div className="pb-5" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        <Payments summaryState={state} nextStep={nextStep}></Payments>
                                    </div>
                                </div>
                                {/*end::Step 4 */}

                                {/*begin::Step 5 */}
                                <div className="pb-5" data-kt-stepper-element="content">
                                    <div className="w-100">
                                        {/* begin::Heading */}
                                        <div className="pb-10 pb-lg-15">
                                            <h3 className="fw-bolder text-dark display-6">
                                                Complete
                                            </h3>
                                            <div className="text-muted fw-bold fs-3">
                                                {/* Review your setup to kickstart your app! */}
                                                If everything is correct, submit the reservation!
                                            </div>
                                        </div>
                                        {/* end::Heading */}
                                        <div className="mb-lg-15 alert alert-danger">
                                            <div className="alert-text font-weight-bold">
                                                Please check the room before you give the customer the key!
                                            </div>
                                        </div>

                                        {/* begin::Section */}
                                        <h4 className="fw-bolder mb-3">Customer</h4>
                                        <div className="text-gray-600 fw-bold lh-lg mb-8">
                                            <div>{state.guest?.guestName}</div>
                                            {/* <div>{data.appBasic.appType}</div> */}
                                        </div>
                                        {/* end::Section */}

                                        {/* begin::Section */}
                                        <h4 className="fw-bolder mb-3">Dates</h4>
                                        <div className="text-gray-600 fw-bold lh-lg mb-8">
                                            <table cellSpacing={10}>
                                                <tr><td>

                                                    <strong>Check-in:    </strong>
                                                </td>
                                                    <td>
                                                        {" "}
                                                        {state.dates?.startDate}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>

                                                        <strong>Check-out: </strong>
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        {state.dates?.endDate}

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        {/* end::Section */}

                                        {/* begin::Section */}
                                        <h4 className="fw-bolder mb-3">Room</h4>
                                        <div className="text-gray-600 fw-bold lh-lg mb-8">
                                            <div>{state.room?.roomTypeName}</div>
                                            <div>{state.room?.roomName}</div>
                                        </div>
                                        {/* end::Section */}

                                        {/* begin::Section */}
                                        {/* <h4 className="fw-bolder mb-3">App Storage</h4>
                                        <div className="text-gray-600 fw-bold lh-lg mb-8">
                                            <div>{data.appStorage}</div>
                                        </div> */}
                                        {/* end::Section */}
                                    </div>
                                </div>
                                {/*end::Step 5 */}

                                {/*begin::Actions */}
                                <div className="d-flex justify-content-between pt-10">
                                    {/* <pre>

                                        {JSON.stringify(state, undefined, 2)}
                                    </pre> */}
                                    {/* <div className="mr-2">
                                        <button
                                            type="button"
                                            className="btn btn-lg btn-light-primary fw-bolder py-4 pe-8 me-3"
                                            data-kt-stepper-action="previous"
                                            onClick={prevStep}
                                        >
                                            <KTSVG
                                                path="/media/icons/duotone/Navigation/Left-2.svg"
                                                className="svg-icon-3 me-1"
                                            />{" "}
                                            Previous
                                        </button>
                                    </div> */}
                                    <div>
                                        <button
                                            type="button"
                                            className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                                            data-kt-stepper-action="submit"
                                            onClick={(event) => {
                                                postRoomCheckIn.mutate({
                                                    post_room_check_in_input: {
                                                        accessToken: accessToken.access_token,
                                                        reservationID: state.reservation?.reservationID

                                                    }
                                                })
                                                submit();

                                            }}
                                        >
                                            Submit{" "}
                                            <KTSVG
                                                path="/media/icons/duotone/Navigation/Right-2.svg"
                                                className="svg-icon-3 ms-2"
                                            />
                                        </button>

                                        {/* <button
                                            type="button"
                                            className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                                            data-kt-stepper-action="next"
                                            onClick={nextStep}
                                        >
                                            Next Step{" "}
                                            <KTSVG
                                                path="/media/icons/duotone/Navigation/Right-2.svg"
                                                className="svg-icon-3 ms-1"
                                            />
                                        </button> */}
                                    </div>
                                </div>
                                {/*end::Actions */}
                            </form>
                            {/*end::Form */}
                        </div>
                        {/*end::Content */}
                    </div>
                    {/* end::Stepper */}
                </div>
            </div >
        </Modal >
    );
};

export { GetAvailableRoomsModal };
