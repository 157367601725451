
import { graphql } from "graphql";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetGuestQuery, useGetGuestsByFilterMutation, usePostReservationMutation, usePostRoomAssignMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { ReservationSummary } from "../../../../../_start/partials/widgets/lists/ReservationSummary";
import { ApplicationContext } from "../../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import useGraphQlClient from "../../../../useGraphQlClient";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";

function jsonStringifyRecursive(obj: any) {
    const cache = new Set();
    return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.has(value)) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.add(value);
        }
        return value;
    }, 2);
}

export const Summary = ({ nextStep, summaryState }: { summaryState: any; nextStep: any }) => {
    const { graphQlClient } = useAnonymousGraphQlClient();
    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const [reservation, setReservation] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [results, setResults] = useState<Array<any>>([])
    const [error, setError] = useState<String>();

    const { data } = useGetGuestQuery(graphQlClient(), { get_guest_input: { accessToken: accessToken.access_token, guestID: summaryState?.guest?.guestID } })

    const guest = data?.cloudbeds_guest_getGuest?.data;
    // call getGuestsByFilter
    const postRoomAssignMutation = usePostRoomAssignMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            // need to also add deposit to this


            nextStep({ reservation })
        }
    });



    const postReservationMutation = usePostReservationMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            setResults(data.cloudbeds_reservation_postReservation?.data)
            if (data.cloudbeds_reservation_postReservation?.error) {
                setError(data.cloudbeds_reservation_postReservation?.error);
            } else {
                setReservation(data.cloudbeds_reservation_postReservation?.data);
                setTimeout(() => {
                    postRoomAssignMutation.mutate({
                        post_room_assign_input: {
                            accessToken: accessToken.access_token,
                            reservationID: data.cloudbeds_reservation_postReservation?.data?.reservationID,
                            newRoomID: summaryState.room.roomID,
                            roomTypeID: summaryState.room.roomTypeID
                        }
                    })
                }, 0)
            }
        }
    })


    const postReservation = () => {
        const { email, lastName, firstName, zip, phone, country } = data?.cloudbeds_guest_getGuest?.data;
        postReservationMutation.mutate({
            post_reservation_input: {
                guestEmail: email,
                guestFirstName: firstName,
                guestLastName: lastName,
                guestZip: zip,
                guestPhone: phone,
                guestCountry: country,
                adults: [{ roomTypeID: summaryState?.room?.roomTypeID, quantity: 1 }],
                rooms: [{ quantity: 1, roomTypeID: summaryState?.room?.roomTypeID }],
                children: [{ roomTypeID: summaryState?.room?.roomTypeID, quantity: 1 }],
                paymentMethod: "credit",
                startDate: summaryState?.dates?.startDate,
                endDate: summaryState?.dates?.endDate,
                accessToken: accessToken.access_token
            }
        })
    }


    return <>
        {/*begin::Heading */}
        <div className="pb-5 pb-lg-10">
            <h3 className="fw-bolder text-dark display-6">
                Summary
            </h3>
        </div>
        {/*begin::Heading */}

        {postReservationMutation.isLoading ? <>
            Loading...
        </> : <>
            <div className="fv-row mb-12">

                {error}

                <ReservationSummary data={{
                    ...summaryState,
                    guest,
                }} className="mb-5 mb-xxl-8"></ReservationSummary>
                {/* <pre>
                    {jsonStringifyRecursive(guest)}
                </pre>
                <pre>
                    {jsonStringifyRecursive(summaryState?.dates)}
                </pre>
                <pre>
                    {jsonStringifyRecursive(summaryState?.guest)}
                </pre>
                <pre>
                    Deposit: {jsonStringifyRecursive(summaryState?.deposit)}
                </pre>
                <pre>
                    Room: {jsonStringifyRecursive(summaryState?.room)}
                </pre> */}
            </div>
        </>}
        {/*begin::Form Group */}
        <span className="form-check form-check-custom form-check-solid">

            <button
                type="button"
                className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                data-kt-stepper-action="next"
                onClick={() => { postReservation() }}
            >
                Post reservation{" "}
                <KTSVG
                    path="/media/icons/duotone/Navigation/Right-2.svg"
                    className="svg-icon-3 ms-1"
                />
            </button>
        </span>

        {/*end::Form Group */}
    </>
}