/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetCloudbedsAccessTokenMutation } from "../../../../generated/graphql";
import {
  IThemeConfig,
  useTheme,
  getConfig,
} from "../../../../_start/layout/core";
import { ApplicationContext } from "../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../useAnonymousGraphQlClient";
import { StartDashboardPage } from "./StartDashboardPage";

const defaultPageConfig = getConfig();
const dashboardPageConfig: Partial<IThemeConfig> = {
  aside: {
    ...defaultPageConfig.aside,
    display: false,
    primaryDisplay: false,
    secondaryDisplay: false,
    toggle: false,
    content: "menu",
  },
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: false,
  },
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
  },
};

export function CloubedsDashboardWrapper({ property }: { property: string }) {
  const history = useHistory();
  const { accessToken, setAccessToken } = useContext(ApplicationContext);
  const { setTheme } = useTheme();

  const { graphQlClient } = useAnonymousGraphQlClient();
  const getCloudbedsAccessToken = useGetCloudbedsAccessTokenMutation(graphQlClient(), {
    onSuccess: (data: any, variables: any) => {

      if (data.cloudbeds.accessToken.error) {
        // alert("Must relogin");
        history.push("/");
      }

      if (data.cloudbeds.accessToken) {
        setAccessToken(data.cloudbeds.accessToken);
      }
    },
    onError: () => {
      history.push("/");
    }
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search)
  // how do we get projectId
  useEffect(() => {
    if (!accessToken?.access_token) {
      getCloudbedsAccessToken.mutate({
        code: searchParams.get("code") || "",
        state: searchParams.get('state'),
        property
      })
    }
  }, [])
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(dashboardPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return <>
    <StartDashboardPage />
  </>;
}
