import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RootState } from "../setup";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { ApplicationContextWrapper } from "./ApplicationContext";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { PublicRoutes } from "./routing/PublicRoutes";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <ApplicationContextWrapper>
      <BrowserRouter basename={basename}>
        <ThemeProvider>
          <Switch>
            <Route path="/logout" component={Logout} />
            {/* {!isAuthorized ? (
              <MasterLayout>
                <PublicRoutes />
              </MasterLayout>
            ) : (
              <>
                <MasterLayout>
                  <PrivateRoutes />
                </MasterLayout>
              </>
            )} */}
            <MasterLayout>
              <PublicRoutes />
            </MasterLayout>
          </Switch>
        </ThemeProvider>
      </BrowserRouter>
    </ApplicationContextWrapper>
  );
};

export { App };
