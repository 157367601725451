/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
  results?: any[]
};

const CancellationsWidget: React.FC<Props> = ({ results, className, innerPadding = "" }) => {

  const [transactions, setTransactions] = useState<any>({});

  useEffect(() => {

    // batch up with parentTransactionID
    // create a hashmap for this
    // const tempTransactions = results && results?.reduce((accumulator, result) => {

    //   if (result.parentTransactionID) {
    //     // set up the key...but don't put it in the 0 index
    //     if (accumulator[result.parentTransactionID]) {
    //       accumulator[result.parentTransactionID] = [result, ...accumulator[result.parentTransactionID]];
    //     } else {
    //       accumulator[result.parentTransactionID] = [result];
    //     }
    //   } else {
    //     if (accumulator[result.transactionID]) {
    //       accumulator[result.transactionID] = [...accumulator[result.transactionID], result];
    //     } else {
    //       accumulator[result.transactionID] = [result];
    //     }
    //   }
    //   return accumulator;
    // }, {}) || {};

    // setTransactions(results)


  }, [results])

  // return <>
  //   <pre>{JSON.stringify(transactions, undefined, 2)}</pre>
  // </>

  return <>

    {results?.length === 0 ? <>
      <h3>
        <span className="text-muted fw-bold d-block mt-1">
          No results found
        </span>
      </h3>
    </> : <>
      <div className={`card ${className}`}>

        {/* begin::Body */}
        <div className=" pt-3 pb-0 ">
          <div className="tab-content mt-4" id="myTabTables2">
            {/* begin::Tap pane */}
            <div
              id="kt_tab_pane_2_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_2_1"
              className="tab-pane fade active show"
            >
              {/* begin::Table */}
              <div className="table-responsive">
                <table className="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th className="p-0 w-50px"></th>
                      <th className="p-0 min-w-150px"></th>
                      <th className="p-0 min-w-120px"></th>
                      <th className="p-0 min-w-70px"></th>
                      <th className="p-0 min-w-70px"></th>
                      <th className="p-0 min-w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>


                    {results?.map((result: any) => {

                      // transactions is the array
                      // const totalAmount = transactions.reduce((accumulator: number, transaction: any) => {
                      //   return accumulator + transaction.amount;
                      // }, 0);


                      return <>
                        <tr>
                          <td className="px-0 py-3">
                            <div className="symbol symbol-55px mt-1 me-5">
                              <span className="symbol-label bg-light-primary align-items-end">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/svg/avatars/001-boy.svg"
                                  )}
                                  className="mh-40px"
                                />
                              </span>
                            </div>
                          </td>
                          <td className="px-0">
                            <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                              {result.guestName}
                            </a>
                            <span className="text-muted fw-bold d-block mt-1">
                              {result.status}
                            </span>
                            <span className="text-muted fw-bold d-block mt-1">
                              source:
                              <pre>
                                {JSON.stringify(result.sourceName, undefined, 2)}
                              </pre>
                              <pre>
                                {JSON.stringify(result.sourceCategory, undefined, 2)}
                              </pre>
                              <pre>
                                {JSON.stringify(result.source, undefined, 2)}
                              </pre>
                              <pre>
                                {JSON.stringify(result.sourceReservationID, undefined, 2)}
                              </pre>
                            </span>

                            {/* <pre> {JSON.stringify(result, undefined, 2)}
                            </pre> */}
                          </td>
                          <td></td>
                          <td className="text-end">
                            <span className="text-gray-800 fw-bolder d-block fs-6">
                            </span>
                            <span className="text-muted fw-bold d-block mt-1 fs-7">

                              {/* {JSON.stringify(result)}
                              Paid */}
                            </span>
                          </td>
                          <td className="text-end">
                            <span className="fw-bolder text-primary">{result.sourceName}</span>
                          </td>
                          <td className="text-end pe-0">
                            <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                              <KTSVG
                                className="svg-icon-4"
                                path="/media/icons/duotone/Navigation/Arrow-right.svg"
                              />
                            </a>
                          </td>
                        </tr>
                      </>

                    })}



                  </tbody>
                </table>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>}



    {!results || !results.length ? <>
    </> : <>




    </>
    }
  </>
};

export { CancellationsWidget };
