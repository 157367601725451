import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import { AuthPage } from "../modules/auth";
import { LightDashboardWrapper } from "../pages/dashboards/light-dashboard/LightDashboardWrapper";
import { StartDashboardWrapper } from "../pages/dashboards/start-dashboard/StartDashboardWrapper";
import { BlankDashboardWrapper } from "../pages/dashboards/blank-dashboard/StartDashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { CloubedsDashboardWrapper } from "../pages/dashboards/cloudbeds-dashboard/StartDashboardWrapper";

export function PublicRoutes() {
  const ProfilePageWrapper = lazy(
    () => import("../modules/profile/ProfilePageWrapper")
  );
  const GeneralPageWrapper = lazy(
    () => import("../modules/general/GeneralPageWrapper")
  );
  const DocsPageWrapper = lazy(() => import("../modules/docs/DocsPageWrapper"));

  return (


    <Switch>
      {/* <Route path="/cloudbeds/glide/oauth/callback" component={() => { return <CloubedsDashboardWrapper property="glide"></CloubedsDashboardWrapper> }} /> */}
      <Route path="/cloudbeds/columbus/oauth/callback" component={() => { return <CloubedsDashboardWrapper property="217179"></CloubedsDashboardWrapper> }} />
      <Route path="/cloudbeds/marco/oauth/callback" component={() => { return <CloubedsDashboardWrapper property="256697"></CloubedsDashboardWrapper> }} />
      <Route path="/dashboard" component={BlankDashboardWrapper} />
      <Route path="/start-dashboard" component={StartDashboardWrapper} />
      <Route path="/light" component={LightDashboardWrapper} />
      <Route path="/general" component={GeneralPageWrapper} />
      <Route path="/profile" component={ProfilePageWrapper} />
      <Route path="/menu-test" component={MenuTestPage} />
      <Route path="/docs" component={DocsPageWrapper} />
      <Redirect from="/auth" to="/dashboard" />
      <Redirect exact from="/" to="/dashboard" />
      <Redirect to="dashboard" />
      {/* <Route path="/auth" component={AuthPage} />
      <Redirect to="/auth" /> */}
    </Switch>
  );
}
