
import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetGuestsByFilterMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { ApplicationContext } from "../../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import useGraphQlClient from "../../../../useGraphQlClient";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";


export const SelectDeposit = ({ nextStep }: { nextStep: any }) => {
    const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
    const { graphQlClient } = useAnonymousGraphQlClient();
    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const [deposit, setDeposit] = useState<number>();
    const [results, setResults] = useState<Array<any>>([])



    const updateData = (fieldsToUpdate: Partial<{ firstName: String, lastName: String }>) => {
        const updatedData = { ...data, ...fieldsToUpdate };
        setData(updatedData);
    };

    // call getGuestsByFilter
    const getGuestsByFilterMutation = useGetGuestsByFilterMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            setResults(data.cloudbeds_guest_getGuestsByFilter?.data)
            console.log('data', data)
        }
    })

    const getGuestByFilter = (name: string) => {
        getGuestsByFilterMutation.mutate({ get_guests_by_filter_input: { accessToken: accessToken.access_token, status: "", guestName: name } })
    }

    return <>
        {/*begin::Heading */}
        <div className="pb-5 pb-lg-10">
            <h3 className="fw-bolder text-dark display-6">
                Select Deposit
            </h3>
        </div>
        {/*begin::Heading */}
        {/*begin::Form Group */}
        <div className="fv-row mb-12">
            <span className="form-check form-check-custom form-check-solid">

                <button
                    type="button"
                    className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="next"

                    onClick={() => { nextStep({ deposit: 0 }) }}
                >
                    0$
                </button>
            </span>
        </div>
        {/*end::Form Group */}
        {/*begin::Form Group */}
        <div className="fv-row mb-12">
            <span className="form-check form-check-custom form-check-solid">

                <button
                    type="button"
                    className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="next"
                    onClick={() => { nextStep({ deposit: 100 }) }}
                >
                    100$
                </button>
            </span>
        </div>
        {/*end::Form Group */}
        {/*begin::Form Group */}
        <div className="fv-row mb-12">
            <span className="form-check form-check-custom form-check-solid">

                <button
                    type="button"
                    className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="next"
                    onClick={() => { nextStep({ deposit: 200 }) }}
                >
                    200$
                </button>
            </span>
        </div>
        {/*end::Form Group */}
        <div className="fv-row mb-12">
        </div>
        {/*begin::Form Group */}

        {/*end::Form Group */}
    </>
}