
import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetAvailableRoomTypesMutation, useGetGuestsByFilterMutation, useGetRoomsMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { ApplicationContext } from "../../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import useGraphQlClient from "../../../../useGraphQlClient";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";


export const GetRooms = ({ nextStep, startDate, endDate, roomTypeID, roomTypeNameShort }: { nextStep: any; startDate: string | undefined; endDate: string | undefined; roomTypeID: number, roomTypeNameShort: string }) => {
    const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
    const { graphQlClient } = useAnonymousGraphQlClient();
    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const [results, setResults] = useState<Array<any>>([])


    useEffect(() => {
        if (startDate && endDate) {
            getGuestByFilter(startDate, endDate);
        }
    }, [startDate, endDate])

    const updateData = (fieldsToUpdate: Partial<{ firstName: String, lastName: String }>) => {
        const updatedData = { ...data, ...fieldsToUpdate };
        setData(updatedData);
    };

    // call getGuestsByFilter
    const getGuestsByFilterMutation = useGetRoomsMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            setResults(data.cloudbeds_room_getRooms?.data?.[0]?.rooms)
            console.log('data', data.cloudbeds_room_getRooms?.data?.[0]?.rooms)
        }
    })

    const getGuestByFilter = (startDate: string, endDate: string) => {
        getGuestsByFilterMutation.mutate({ get_rooms_input: { accessToken: accessToken.access_token, startDate, endDate, roomTypeID, roomTypeNameShort } })
    }

    return <>
        {/*begin::Form Group */}
        <div className="fv-row">
            {!results || !results.length ? <>
                No results
            </> : results.map((result) => {
                return <>
                    {/*begin:Option */}
                    <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-primary">
                                    <KTSVG
                                        path="/media/icons/duotone/Home/Globe.svg"
                                        className="svg-icon-1 svg-icon-primary"
                                    />
                                </span>
                            </span>

                            <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                    {result.roomTypeName}
                                </span>
                                <span className="fs-7 text-muted">
                                    {result.roomsAvailable}
                                </span>
                            </span>
                        </span>

                        <span className="form-check form-check-custom form-check-solid">
                            <button
                                type="button"
                                className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                                data-kt-stepper-action="next"
                                onClick={() => { nextStep({ room: result, dates: { startDate, endDate } }) }}
                            >
                                Select{" "}
                                <KTSVG
                                    path="/media/icons/duotone/Navigation/Right-2.svg"
                                    className="svg-icon-3 ms-1"
                                />
                            </button>

                        </span>
                    </label>
                    {/*end::Option */}
                </>
            })}



        </div>
        {/*end::Form Group */}
    </>
}