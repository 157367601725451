
import { useQueryClient } from "@tanstack/react-query";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetGuestsByFilterMutation, useGetPaymentMethodsMutation, useGetReservationInvoiceInformationQuery, usePostPaymentMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { ApplicationContext } from "../../../../ApplicationContext";
import { Accordion } from "../../../../modules/docs/pages/base/Accordion";
import { Forms } from "../../../../modules/docs/pages/base/Forms";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import useGraphQlClient from "../../../../useGraphQlClient";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";


export const Payments = ({ summaryState, nextStep }: { summaryState: any; nextStep: any }) => {
    const { graphQlClient } = useAnonymousGraphQlClient();
    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const [firstName, setFirstName] = useState<string>();
    const [invoiceInformation, setInvoiceInformation] = useState<any>();
    const [results, setResults] = useState<Array<any>>([])
    const [owed, setOwed] = useState(0);
    const [paymentAmounts, setPaymentAmounts] = useState<any>({});
    const queryClient = useQueryClient();


    useEffect(() => {
        getPaymentMethods();
    }, [])


    const getPaymentMethodsMutation = useGetPaymentMethodsMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            setResults(data.cloudbeds_payment_getPaymentMethods?.data?.methods)
            console.log('data', data)
        }
    })

    const postPayment = usePostPaymentMutation(graphQlClient(), {
        onSuccess: () => {
            refetch();
            // debugger;
            // queryClient.invalidateQueries(['getReservationInvoiceInformation', {
            //     accessToken: accessToken.access_token,
            //     reservationID: summaryState.reservation?.reservationID
            // }])
        }
    })

    const getPaymentMethods = () => {
        getPaymentMethodsMutation.mutate({ get_payment_methods_input: { accessToken: accessToken.access_token } })
    }

    const { data, refetch } = useGetReservationInvoiceInformationQuery(graphQlClient(), {
        get_reservation_invoice_information_input: {
            accessToken: accessToken.access_token,
            reservationID: summaryState.reservation?.reservationID
        }
    })

    useEffect(() => {
        // multiple by 100 so we can do math without decimals
        // divide it by 100 at the end
        const grandTotal = summaryState?.reservation?.grandTotal * 100 /*+ summaryState.deposit * 100 */;
        const newOwedTotal = Object.entries(paymentAmounts).reduce((accumulator, [k, v]: any) => {
            return accumulator - v * 100;
        }, grandTotal)
        setOwed(newOwedTotal / 100);
    }, [paymentAmounts, summaryState])

    useEffect(() => {
        setInvoiceInformation(data?.cloudbeds_reservation_getReservationInvoiceInformation)
    }, [data])

    return <>
        {/*begin::Heading */}

        <div className="pb-5 pb-lg-10">
            <h3 className="fw-bolder text-dark display-6">
                Choose payment methods
            </h3>
        </div>
        {/*begin::Heading */}
        {/* <pre>grandTotal: {JSON.stringify(summaryState.reservation?.grandTotal, undefined, 2)}</pre>
        <pre>deposit: {JSON.stringify(summaryState.deposit, undefined, 2)}</pre> */}
        {/* <h4 style={{ color: owed * 100 >= 0 ? "black" : "red" }}>owed: {owed}</h4> */}

        <h3 style={{ marginBottom: 50 }}>Balance ${invoiceInformation?.data?.balance} </h3>

        {/* <pre>owed {JSON.stringify(invoiceInformation?.data?.balance, undefined, 2)}</pre> */}
        {/* <pre>data {JSON.stringify(invoiceInformation?.data?.balanceDetailed, undefined, 2)}</pre>
        <pre>data {JSON.stringify(invoiceInformation?.data?.reservationPayments, undefined, 2)}</pre> */}
        {/* <pre>{JSON.stringify(summaryState.reservation, undefined, 2)}</pre> */}

        {/*begin::Form Group */}
        {/* <pre>{JSON.stringify(summaryState, undefined, 2)}</pre> */}
        {/*end::Form Group */}


        {/* <p>Get price and modify dates</p>
        <p>
            - Allow ability to modify total price and recalculate rate
        </p> */}
        {/*begin::Form Group */}
        <div className="fv-row">
            {!results || !results?.length ? <>
                No results
            </> : results.map((result) => {
                return <>
                    {/*begin:Option */}
                    <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-primary">
                                    <KTSVG
                                        path="/media/icons/duotone/Home/Globe.svg"
                                        className="svg-icon-1 svg-icon-primary"
                                    />
                                </span>
                            </span>

                            <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                    {result.name}
                                </span>
                                <span className="fs-7 text-muted">
                                    {result.method}
                                </span>
                            </span>
                        </span>

                        <span className="form-check form-check-custom form-check-solid">
                            <input
                                autoComplete="off"
                                type="number"
                                className="form-control form-control-lg form-control-solid"
                                name="appname"
                                placeholder=""
                                value={paymentAmounts[result.name]}
                                onChange={(e) => {
                                    setPaymentAmounts({ ...paymentAmounts, [result.name]: e.target.value })
                                }}
                            />


                        </span>
                        <span className="form-check form-check-custom form-check-solid">

                            <button
                                type="button"
                                className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                                data-kt-stepper-action="next"
                                onClick={() => {
                                    postPayment.mutate({
                                        post_payment_input: {
                                            accessToken: accessToken.access_token,
                                            amount: parseFloat(paymentAmounts[result.name]),
                                            reservationID: summaryState.reservation.reservationID,
                                            // we need to modify this to use a dropdown for the card type
                                            cardType: result.method === "credit" ? "visa" : "",
                                            type: result.method
                                        }
                                    })
                                    setPaymentAmounts({ ...paymentAmounts, [result.name]: 0 })
                                }}
                            >
                                Pay{" "}
                                <KTSVG
                                    path="/media/icons/duotone/Navigation/Right-2.svg"
                                    className="svg-icon-3 ms-1"
                                />
                            </button>
                        </span>
                        {owed * 100 < 0 && (
                            <div className="fv-plugins-message-container">
                                <div
                                    data-field="appname"
                                    data-validator="notEmpty"
                                    className="fv-help-block"
                                >
                                    Lower your amount by
                                    <a href="" onClick={(event) => {
                                        event.preventDefault();
                                        setPaymentAmounts({ ...paymentAmounts, [result.name]: (paymentAmounts[result.name] * 100 + owed * 100) / 100 })
                                    }}>{owed}</a>
                                </div>
                            </div>
                        )}
                    </label>
                    {/* <div>

                        {paymentAmounts[result.name]}
                    </div> */}
                    {/*end::Option */}
                </>
            })}


            {/*begin:Option */}
            {/* <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-danger">
                            <KTSVG
                                path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
                                className="svg-icon-1 svg-icon-danger"
                            />
                        </span>
                    </span>

                    <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">
                            Face to Face Discussions
                        </span>
                        <span className="fs-7 text-muted">
                            Creating a clear text structure is just one aspect
                        </span>
                    </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="appType"
                        value="Face to Face Discussions"
                        checked={
                            data.appBasic.appType ===
                            "Face to Face Discussions"
                        }
                        onChange={() => {
                            // updateData({
                            //     appBasic: {
                            //         appName: data.appBasic.appName,
                            //         appType: "Face to Face Discussions",
                            //     },
                            // })
                        }}
                    />
                </span>
            </label> */}
            {/*end::Option */}

            {/*begin:Option */}
            {/* <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-success">
                            <KTSVG
                                path="/media/icons/duotone/Devices/Watch1.svg"
                                className="svg-icon-1 svg-icon-success"
                            />
                        </span>
                    </span>

                    <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">
                            Full Intro Training
                        </span>
                        <span className="fs-7 text-muted">
                            Creating a clear text structure copywriting
                        </span>
                    </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="appType"
                        value="Full Intro Training"
                        checked={
                            data.appBasic.appType === "Full Intro Training"
                        }
                        onChange={() => {
                            // updateData({
                            //     appBasic: {
                            //         appName: data.appBasic.appName,
                            //         appType: "Full Intro Training",
                            //     },
                            // })
                        }}
                    />
                </span>
            </label> */}
            {/*end::Option */}
            {/* <span className="form-check form-check-custom form-check-solid">

                <button
                    type="button"
                    className="btn btn-lg btn-danger fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="next"
                    onClick={() => {
                        nextStep()
                    }}
                >
                    Cancel Reservation{" "}
                    <KTSVG
                        path="/media/icons/duotone/Navigation/Right-2.svg"
                        className="svg-icon-3 ms-1"
                    />
                </button>
            </span> */}
            <span className="form-check form-check-custom form-check-solid">

                <button
                    // disabled={invoiceInformation?.data?.balance > 0}
                    type="button"
                    className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="next"
                    onClick={() => {
                        nextStep()
                    }}
                >
                    Next{" "}
                    <KTSVG
                        path="/media/icons/duotone/Navigation/Right-2.svg"
                        className="svg-icon-3 ms-1"
                    />
                </button>
            </span>
        </div>
        {/*end::Form Group */}
    </>
}