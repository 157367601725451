
import dayjs from "dayjs";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetGuestsByFilterMutation, useGetTransactionsMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { TransactionWidget } from "../../../../../_start/partials/widgets/tables/TransactionsWidget";
import { ApplicationContext } from "../../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import useGraphQlClient from "../../../../useGraphQlClient";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";


export const GetTransactions = () => {
    const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
    const { graphQlClient } = useAnonymousGraphQlClient();
    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const [modifiedFrom, setModifiedFrom] = useState<string>(dayjs().startOf("day")
        .format("YYYY-MM-DD"));
    const [modifiedTo, setModifiedTo] = useState<string>(dayjs().startOf("day").add(1, "day")
        .format("YYYY-MM-DD"));
    const [results, setResults] = useState<Array<any>>([])

    useEffect(() => {
        if (modifiedFrom && modifiedTo) {
            getGuestByFilter(modifiedFrom, modifiedTo);
        }
    }, [modifiedFrom, modifiedTo])

    const updateData = (fieldsToUpdate: Partial<{ firstName: String, lastName: String }>) => {
        const updatedData = { ...data, ...fieldsToUpdate };
        setData(updatedData);
    };

    // call getGuestsByFilter
    const getGuestsByFilterMutation = useGetTransactionsMutation(graphQlClient(), {
        onSuccess: (data, variables) => {
            setResults(data.cloudbeds_payment_getTransactions?.data)
        }
    })

    const getGuestByFilter = (modifiedFrom: string, modifiedTo: string) => {
        const modifiedToDate = modifiedTo.split("-");
        const dayjsModifiedToDate = dayjs()
            .set('year', parseInt(modifiedToDate[0]))
            .set('month', parseInt(modifiedToDate[1]) - 1)
            .set('date', parseInt(modifiedToDate[2]))
            .set('hour', 8).set('minute', 0).set('second', 0)
            .add(1, "day").format('YYYY-MM-DD HH:mm:ss');
        getGuestsByFilterMutation.mutate({ get_transactions_input: { accessToken: accessToken.access_token, createdFrom: modifiedFrom + " 08:00:00", createdTo: dayjsModifiedToDate } })
    }

    return <>
        {/*begin::Heading */}
        <div className="pb-5 pb-lg-10">
            <h3 className="fw-bolder text-dark display-6">
                Get transactions
            </h3>

            <span className="text-muted fw-bold d-block mt-1">
                This retrieves transactions from the start date @ 8am till the day after the end date @ 8am

            </span>
        </div>
        {/*begin::Heading */}

        {/*begin::Form Group */}
        <div className="fv-row mb-12">
            <label className="fs-6 fw-bolder text-dark form-label">
                Start date
            </label>
            <input
                type="date"
                className="form-control form-control-lg form-control-solid"
                name="appname"
                placeholder=""
                value={modifiedFrom || ""}
                onChange={(e) => {
                    setModifiedFrom(e.target.value)
                }}
            />
            {/* {!data.appBasic.appName && hasError && (
                <div className="fv-plugins-message-container">
                    <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                    >
                        App name is required
                    </div>
                </div>
            )} */}
        </div>
        {/*end::Form Group */}

        <div className="fv-row mb-12">
            <label className="fs-6 fw-bolder text-dark form-label">
                End date
            </label>
            <input
                type="date"
                className="form-control form-control-lg form-control-solid"
                name="appname"
                placeholder=""
                value={modifiedTo || ""}
                onChange={(e) => {
                    setModifiedTo(e.target.value)
                }}
            />
            {/* {!data.appBasic.appName && hasError && (
                <div className="fv-plugins-message-container">
                    <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                    >
                        App name is required
                    </div>
                </div>
            )} */}
        </div>
        {/*begin::Form Group */}
        <div className="fv-row">

            <TransactionWidget results={results} className="card-stretch mb-5 mb-xxl-8"></TransactionWidget>


            {/*begin:Option */}
            {/* <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-danger">
                            <KTSVG
                                path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
                                className="svg-icon-1 svg-icon-danger"
                            />
                        </span>
                    </span>

                    <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">
                            Face to Face Discussions
                        </span>
                        <span className="fs-7 text-muted">
                            Creating a clear text structure is just one aspect
                        </span>
                    </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="appType"
                        value="Face to Face Discussions"
                        checked={
                            data.appBasic.appType ===
                            "Face to Face Discussions"
                        }
                        onChange={() => {
                            // updateData({
                            //     appBasic: {
                            //         appName: data.appBasic.appName,
                            //         appType: "Face to Face Discussions",
                            //     },
                            // })
                        }}
                    />
                </span>
            </label> */}
            {/*end::Option */}

            {/*begin:Option */}
            {/* <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-success">
                            <KTSVG
                                path="/media/icons/duotone/Devices/Watch1.svg"
                                className="svg-icon-1 svg-icon-success"
                            />
                        </span>
                    </span>

                    <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">
                            Full Intro Training
                        </span>
                        <span className="fs-7 text-muted">
                            Creating a clear text structure copywriting
                        </span>
                    </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="appType"
                        value="Full Intro Training"
                        checked={
                            data.appBasic.appType === "Full Intro Training"
                        }
                        onChange={() => {
                            // updateData({
                            //     appBasic: {
                            //         appName: data.appBasic.appName,
                            //         appType: "Full Intro Training",
                            //     },
                            // })
                        }}
                    />
                </span>
            </label> */}
            {/*end::Option */}
        </div>
        {/*end::Form Group */}
    </>
}