/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { ApplicationContext } from "../../../../app/ApplicationContext";
import useAnonymousGraphQlClient from "../../../../app/useAnonymousGraphQlClient";
import { useGetReservationWithRateDetailsQuery } from "../../../../generated/graphql";
import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
};

const UnpaidBalances: React.FC<Props> = ({ className, innerPadding = "" }) => {
  const { graphQlClient } = useAnonymousGraphQlClient();
  const [results, setResults] = useState();
  const { accessToken, setAccessToken } = useContext(ApplicationContext);

  const { data } = useGetReservationWithRateDetailsQuery(graphQlClient(), {
    get_reservations_with_rate_details_input: {
      accessToken: accessToken?.access_token,
      sortByRecent: true,
      includeDeleted: false
    }
  })

  return (
    <div className={`card bg-danger ${className}`}>
      {/* begin::Body */}
      <div className={`card-body ${innerPadding}`}>
        {/* begin::Section */}
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}
          <div className="symbol symbol-50px me-5">
            <span className="symbol-label bg-white bg-opacity-10">
              <KTSVG
                className="svg-icon-2x svg-icon-white"
                path="/media/icons/duotone/Communication/Group-chat.svg"
              />
            </span>
          </div>
          {/* end::Symbol */}

          {/* begin::Title */}
          <div>
            <a
              href="#"
              className="fs-4 text-white text-hover-primary fw-bolder"
            >
              Unpaid Balances
            </a>
            <div className="fs-7 text-white opacity-75 fw-bold mt-1">
              Debts
            </div>
          </div>
          {/* end::Title */}
        </div>
        {/* end::Section */}

        {/* begin::Info */}
        <div className="fw-bolder text-white pt-7">
          {/* <span className="d-block">26 Authors</span>
          <span className="d-block pt-2">140 Comments</span> */}
          {/* <pre>

            {JSON.stringify(data?.cloudbeds_reservation_getReservationsWithRateDetails?.data, undefined, 2)}
          </pre> */}
          {data?.cloudbeds_reservation_getReservationsWithRateDetails?.data?.filter((result: any) => {
            const grandTotal = parseInt(result.balanceDetailed.grandTotal);
            const paid = parseInt(result.balanceDetailed.paid);
            if (grandTotal - paid !== 0) {
              return true;
            }

          })
            // .filter((result: any) => {
            //   if (result.status !== 'canceled' && result.status !== 'no_show' && result.status !== "confirmed") {
            //     return true;
            //   }
            // })
            .filter((result: any) => {
              // filter out reservations that start today and after
              return dayjs(result.reservationCheckIn, "YYYY-MM-DD").isBefore(dayjs().subtract(0, "day"));
            })
            .filter((result: any) => {
              // filter out reservations that start today and after
              const grandTotal = parseInt(result.balanceDetailed.grandTotal);
              const paid = parseInt(result.balanceDetailed.paid);
              return (grandTotal - paid) > 0;
            })
            .map((result: any) => {
              const grandTotal = parseInt(result.balanceDetailed.grandTotal);
              const paid = parseInt(result.balanceDetailed.paid);
              return <>
                <p>
                  {result.guestName} {": "}
                  {result.reservationCheckIn} {": "}
                  ${grandTotal - paid} {" "}

                  <div className="fs-7 text-white opacity-75 fw-bold mt-1">
                    {result.status} {" "}
                  </div>
                </p>
              </>
            })}
        </div>
        {/* end::Info */}

        {/* begin::Progress */}
        {/* <div className="progress h-6px mt-7 bg-white bg-opacity-10">
          <div
            className="progress-bar bg-white"
            role="progressbar"
            style={{ width: "70%" }}
            aria-valuenow={50}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div> */}
        {/* end::Progress */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { UnpaidBalances };
