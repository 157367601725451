/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../_start/helpers";
import {
  EngageWidget5,
  ListsWidget1,
  StatsWidget1,
  StatsWidget2,
  StatsWidget3,
  StatsWidget4,
  StatsWidget5,
  StatsWidget6,
  StatsWidget7,
  TablesWidget1,
  TablesWidget2,
} from "../../../../_start/partials/widgets";
import { LoginCloudbeds } from "../../../../_start/partials/widgets/stats/LoginCloudbeds";
import { UnpaidBalances } from "../../../../_start/partials/widgets/stats/UnpaidBalances";
import { UnreturnedDeposits } from "../../../../_start/partials/widgets/stats/UnreturnedDeposits";
import { CloudbedActions } from "../../../../_start/partials/widgets/tables/CloudbedActions";
import { CreateAppModal } from "../_modals/create-app-stepper/CreateAppModal";
import { CreateReservationModal } from "../_modals/create-app-stepper/CreateReservationModal";
import { GetAvailableRoomsModal } from "../_modals/create-app-stepper/GetAvailableRoomsModal";
import { GetTransactionsModal } from "../_modals/create-app-stepper/GetTransactionsModal";

export const StartDashboardPage: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          <UnpaidBalances className="card-stretch mb-5  mb-xxl-8"></UnpaidBalances>
        </div>

        <div className="col-xl-4">
          <UnreturnedDeposits className="card-stretch mb-5  mb-xxl-8"></UnreturnedDeposits>
        </div>

        <div className="col-xl-4">
          {/* <StatsWidget5 className="card-stretch mb-5  mb-xxl-8" /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xxl-4">
          <StatsWidget6 className="card-stretch mb-5  mb-xxl-8">
            <a
              // onClick={() => setShowCreateAppModal(true)}
              className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6"
            >
              Boost{" "}
              <KTSVG
                className="svg-icon-3 me-0"
                path="/media/icons/duotone/Navigation/Up-right.svg"
              />
            </a>
          </StatsWidget6>
        </div>

        <div className="col-xxl-8 gy-0 gy-xxl-8">
          <StatsWidget7 className="card-stretch mb-5  mb-xxl-8" />
        </div>
      </div> */}
      {/* end::Row */}
      <CloudbedActions className="card-stretch mb-5 mb-xxl-8" />

      {/* <p>
        <Link
          className="text-muted text-hover-primary"
          to={'/start-dashboard'}
        >
          Link to previous start dashboard
        </Link>
      </p>
      <p>
        <Link
          className="text-muted text-hover-primary"
          to={'/light'}
        >
          Link to previous light dashboard
        </Link>
      </p> */}
      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          {/* <EngageWidget5 className="card-stretch mb-5 mb-xxl-8">
            <div className="text-center pt-7"> <a
              className="btn btn-primary fw-bolder fs-6 px-7 py-3"
              onClick={() => setShow(true)}
            >

              Create reservation for existing guest
            </a>
            </div>
          </EngageWidget5> */}
        </div>

        <div className="col-xl-8">
          {/* <TablesWidget1 className="card-stretch mb-5 mb-xxl-8" /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          {/* <StatsWidget1 className="card-stretch mb-5 mb-xxl-8" /> */}
        </div>

        <div className="col-xl-8">
          {/* <LoginCloudbeds className="card-stretch mb-5 mb-xxl-8" /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          {/* <ListsWidget1 className="card-stretch mb-5 mb-xxl-8" /> */}
        </div>

        <div className="col-xl-8">
          {/* <TablesWidget2 className="card-stretch mb-5 mb-xxl-8" /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Modals */}
      <CreateReservationModal show={show} handleClose={() => setShow(false)} />

      {/* end::Modals */}
    </>
  );
};
