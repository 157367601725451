
import dayjs from "dayjs";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetGuestsByFilterMutation } from "../../../../../generated/graphql";
import { StepperComponent } from "../../../../../_start/assets/ts/components";
import { KTSVG } from "../../../../../_start/helpers";
import { ApplicationContext } from "../../../../ApplicationContext";
import useAnonymousGraphQlClient from "../../../../useAnonymousGraphQlClient";
import useGraphQlClient from "../../../../useGraphQlClient";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";


export const SelectDates = ({ nextStep }: { nextStep: any }) => {
    const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
    const { graphQlClient } = useAnonymousGraphQlClient();
    const { accessToken, setAccessToken } = useContext(ApplicationContext);
    const [startDate, setStartDate] = useState<string>(dayjs().startOf("day")
        .format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState<string>(dayjs().startOf("day").add(1, "day")
        .format("YYYY-MM-DD"));
    const [results, setResults] = useState<Array<any>>([])

    return <>
        {/*begin::Heading */}
        <div className="pb-5 pb-lg-10">
            <h3 className="fw-bolder text-dark display-6">
                Select dates
            </h3>
        </div>
        {/*begin::Heading */}

        {/*begin::Form Group */}
        <div className="fv-row mb-12">
            <label className="fs-6 fw-bolder text-dark form-label">
                Start date
            </label>
            <input
                type="date"
                className="form-control form-control-lg form-control-solid"
                name="appname"
                placeholder=""
                value={startDate || ""}
                onChange={(e) => {
                    setStartDate(e.target.value)
                    if (dayjs(e.target.value).isAfter(dayjs(endDate))) {
                        setEndDate(dayjs(e.target.value).add(1, "day").format("YYYY-MM-DD"));
                    }
                }}
            />
            {/* {!data.appBasic.appName && hasError && (
                <div className="fv-plugins-message-container">
                    <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                    >
                        App name is required
                    </div>
                </div>
            )} */}
        </div>
        {/*end::Form Group */}

        <div className="fv-row mb-12">
            <label className="fs-6 fw-bolder text-dark form-label">
                End date
            </label>
            <input
                type="date"
                className="form-control form-control-lg form-control-solid"
                name="appname"
                value={endDate}
                placeholder=""
                // value={data.appBasic.appName}
                onChange={(e) => {
                    setEndDate(e.target.value)
                }}
            />
            {/* {!data.appBasic.appName && hasError && (
                <div className="fv-plugins-message-container">
                    <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                    >
                        App name is required
                    </div>
                </div>
            )} */}
        </div>
        {/*begin::Form Group */}
        <div className="fv-row">



            {/*begin:Option */}
            {/* <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-danger">
                            <KTSVG
                                path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
                                className="svg-icon-1 svg-icon-danger"
                            />
                        </span>
                    </span>

                    <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">
                            Face to Face Discussions
                        </span>
                        <span className="fs-7 text-muted">
                            Creating a clear text structure is just one aspect
                        </span>
                    </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="appType"
                        value="Face to Face Discussions"
                        checked={
                            data.appBasic.appType ===
                            "Face to Face Discussions"
                        }
                        onChange={() => {
                            // updateData({
                            //     appBasic: {
                            //         appName: data.appBasic.appName,
                            //         appType: "Face to Face Discussions",
                            //     },
                            // })
                        }}
                    />
                </span>
            </label> */}
            {/*end::Option */}

            {/*begin:Option */}
            {/* <label className="d-flex align-items-center justify-content-between mb-6 cursor-pointer">
                <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light-success">
                            <KTSVG
                                path="/media/icons/duotone/Devices/Watch1.svg"
                                className="svg-icon-1 svg-icon-success"
                            />
                        </span>
                    </span>

                    <span className="d-flex flex-column">
                        <span className="fw-bolder fs-6">
                            Full Intro Training
                        </span>
                        <span className="fs-7 text-muted">
                            Creating a clear text structure copywriting
                        </span>
                    </span>
                </span>

                <span className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="appType"
                        value="Full Intro Training"
                        checked={
                            data.appBasic.appType === "Full Intro Training"
                        }
                        onChange={() => {
                            // updateData({
                            //     appBasic: {
                            //         appName: data.appBasic.appName,
                            //         appType: "Full Intro Training",
                            //     },
                            // })
                        }}
                    />
                </span>
            </label> */}
            {/*end::Option */}

            <div>

                <button
                    type="button"
                    className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="next"
                    onClick={() => { nextStep({ dates: { startDate: startDate, endDate: endDate } }) }}
                >
                    Select{" "}
                    <KTSVG
                        path="/media/icons/duotone/Navigation/Right-2.svg"
                        className="svg-icon-3 ms-1"
                    />
                </button>

            </div>
        </div>
        {/*end::Form Group */}
    </>
}