import React, { useState } from "react";

export const ApplicationContext = React.createContext<{
  keycloak: any;
  user: any;
  setKeycloak: (keycloak: any) => void;
  setUser: (keycloak: any) => void;
  setAccessToken: (keycloak: any) => void;
  accessToken: any;
  initialized: boolean;
  setInitialized: (initialized: any) => void;
}>({
  user: null,
  keycloak: null,
  setKeycloak: () => { },
  setUser: () => { },
  setAccessToken: () => { },
  accessToken: null,
  initialized: false,
  setInitialized: () => { },
});

export const ApplicationContextWrapper: React.FC<any> = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(
    // localStorage.getItem('accesstoken')?.charAt(0) === "{" ? JSON.parse(sessionStorage.getItem("accesstoken") || "false") : null
  );
  const [initialized, setInitialized] = useState(false);

  return (

    <ApplicationContext.Provider
      value={{
        initialized,
        user,
        accessToken,
        keycloak,
        setKeycloak: (keycloak: any) => {
          setKeycloak(keycloak);
        },
        setInitialized: (initialized: any) => {
          setInitialized(initialized);
        },
        setUser: (user: any) => {
          setUser(user);
        },
        setAccessToken: (accessToken: any) => {
          setAccessToken(accessToken);
        },
      }}
    >
      {/* AccessToken : {accessToken} */}
      {children}
    </ApplicationContext.Provider>
  );
};
